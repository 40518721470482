import React from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";

const TableText = ({
  item,
  accessor,
  className,
  defaultValue = "-",
  ...props
}) => {
  return (
    <span
      // className={classnames({ "table-text": true, [className]: !!className })}
      {...props}
    >
      {item[accessor] || defaultValue}
    </span>
  );
};

TableText.propTypes = {
  item: PropTypes.object.isRequired,
  accessor: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
};

export default TableText;
