import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Switch } from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";

const FormSwitch = ({ field, label, form, checked, onChange, ...props }) => {
  const [switchValue, setSwitchValue] = useState(field.value || checked);

  // We query for an undefined value given that all form initial values are undefined
  // And the 'false' switch value does not mess up with this condition
  useEffect(() => {
    if (field.value === undefined) {
      form.setFieldValue(field.name, checked);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSwitchValue = (event) => {
    const value = event.target.checked;

    setSwitchValue(value);
    form.setFieldValue(field.name, value);
  };

  return (
    <FormFieldWrapper name={field.name} label={label}>
      <Switch checked={switchValue} onChange={toggleSwitchValue} {...props} />
    </FormFieldWrapper>
  );
};

FormSwitch.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  props: PropTypes.object,
};

export default FormSwitch;
