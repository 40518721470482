const baseUrl = process.env.REACT_APP_API_URL;

const headers = {
  "Access-Control-Allow-Origin": "*",
  // Accept: "application/json",
};

export {
  baseUrl,
  headers,
}