import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useHasPermission } from "lib/hooks/permissions";

const AuthWrapper = ({ permission, children }) => {
  const { authObject } = useSelector(({ authReducer }) => authReducer);

  const hasAccess = useHasPermission(permission);

  if (authObject && authObject.roles.length && hasAccess) {
    return children;
  } else {
    return null;
  }
};

AuthWrapper.propTypes = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default AuthWrapper;
