function returnWithZeroOffset(number) {
  return `${number < 10 ? "0" : ""}${number}`;
}

function exportDateObject(date) {
  const dateObject = new Date(date);

  const day = returnWithZeroOffset(dateObject.getUTCDate());
  const month = returnWithZeroOffset(dateObject.getUTCMonth() + 1);
  const year = dateObject.getUTCFullYear();

  const hours = returnWithZeroOffset(dateObject.getUTCHours());
  const minutes = returnWithZeroOffset(dateObject.getUTCMinutes());

  return {
    day,
    month,
    year,
    hours,
    minutes,
  };
}

export { returnWithZeroOffset, exportDateObject };
