import {
  SET_SELECTED_OPEN_SPACE,
  UNSET_SELECTED_OPEN_SPACE,
  SET_OPEN_SPACES_LIST,
  UNSET_OPEN_SPACES_LIST,
  SET_FETCHING_OPEN_SPACES_STATUS,
  UNSET_FETCHING_OPEN_SPACES_STATUS,
} from "store/open-space/types";

const openSpaceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_OPEN_SPACE:
      return {
        ...state,
        selected: payload.selected,
      };
    case UNSET_SELECTED_OPEN_SPACE:
      return {
        ...state,
        selected: null,
      };
    case SET_OPEN_SPACES_LIST:
      return {
        ...state,
        list: payload.list,
        fetched: true,
      };
    case UNSET_OPEN_SPACES_LIST:
      return {
        ...state,
        list: [],
        fetched: false
      };
    case SET_FETCHING_OPEN_SPACES_STATUS:
      return {
        ...state,
        isFetching: true,
      };
    case UNSET_FETCHING_OPEN_SPACES_STATUS:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

const initialState = {
  selected: null,
  list: [],
  fetched: false,
  isFetching: false,
  listLength: null
};

export default openSpaceReducer;
