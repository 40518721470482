import React from "react";
import PropTypes from "prop-types";

import { Button, Grid, Typography } from "@material-ui/core";

import { Field, FieldArray, Formik } from "formik";

/**
 * Form wrapper to handle state and submit action
 *
 * @param fields
 * @param initialValues
 * @param onSubmit
 * @param validate
 * @param formProps
 * @param onSubmitButtonText
 * @param OnSubmitActionComponent
 * @param noDirtyValidation
 * @returns {JSX.Element}
 * @constructor
 */
const FormWrapper = ({
  fields,
  initialValues,
  onSubmit,
  validate,
  formProps,
  onSubmitButtonText,
  OnSubmitActionComponent,
  noDirtyValidation,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      {...formProps}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }) => (
        <>
          {fields.map(({ name, component, props, type = "" }) =>
            type === "fieldArray" ? (
              <FieldArray
                name={name}
                key={name}
                render={(formArrayProps) => {
                  const Component = component;
                  return <Component {...formArrayProps} {...props} />;
                }}
              />
            ) : type === "textOnly" ? (
              <React.Fragment key={name}>{component}</React.Fragment>
            ) : props.fields ? (
              <Grid container key={name} alignItems={"center"}>
                {props.label && (
                  <Grid item xs={4}>
                    <Typography>{props.label}</Typography>
                  </Grid>
                )}
                <Grid item xs={props.label ? 8 : 12}>
                  <Grid container spacing={2}>
                    {props.fields.map(
                      ({ name, component, gridProps = {}, props }) => (
                        <Grid item key={name} {...gridProps}>
                          <Field name={name} component={component} {...props} />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Field name={name} component={component} {...props} key={name} />
            )
          )}
          <div style={{ marginTop: 30 }}>
            {OnSubmitActionComponent ? (
              <OnSubmitActionComponent
                onClick={handleSubmit}
                disabled={noDirtyValidation ? !isValid : !isValid || !dirty}
              />
            ) : (
              <Button
                disabled={!isValid || !dirty}
                color="secondary"
                onClick={handleSubmit}
                variant={"contained"}
              >
                {onSubmitButtonText}
              </Button>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

FormWrapper.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      props: PropTypes.object,
    })
  ),
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onSubmitButtonText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  validate: PropTypes.func,
  formProps: PropTypes.object,
  OnSubmitActionComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  noDirtyValidation: PropTypes.bool,
};

export default FormWrapper;
