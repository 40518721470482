export const SET_ITEM_STATUS_LIST = "ITEMS/SET_STATUS_LIST";
export const SET_ITEM_CONDITIONS_LIST = "ITEMS/SET_CONDITIONS_LIST";
export const SET_ITEMS_LIST = "ITEMS/SET_LIST";
export const SET_SELECTED_ITEM = "ITEMS/SET_SELECTED_ITEM";
export const UNSET_SELECTED_ITEM = "ITEMS/UNSET_SELECTED_ITEM";
export const SET_SELECTED_ITEMS_LIST = "ITEMS/SET_SELECTED_ITEMS_LIST";
export const UNSET_SELECTED_ITEMS_LIST = "ITEMS/UNSET_SELECTED_ITEMS_LIST";
export const SET_ITEM_LOADING = "ITEMS/SET_ITEM_LOADING";
export const UNSET_ITEM_LOADING = "ITEMS/UNSET_ITEM_LOADING";
export const SET_ITEM_HISTORY_LIST = "ITEMS/SET_ITEM_HISTORY_LIST";
export const SET_IMAGE_UPLOADING = 'ITEMS/SET_IMAGE_UPLOADING';
export const UNSET_IMAGE_UPLOADING = 'ITEMS/UNSET_IMAGE_UPLOADING';
