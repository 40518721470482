import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

import TableLayout from "components/TableLayout";
import Icon from "components/Icon";
import TableText from "components/TableText";
import EditCategoryButtonInTable from "screens/Categories/components/EditCategoryButtonInTable";
import CategoryTableImage from "screens/Categories/components/CategoryTableImage";
import CategoryTableLink from "screens/Categories/components/CategoryTableLink";

const CategoriesTable = () => {
  const { list, isFetching } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );

  const categoryColumnsTable = [
    {
      name: "imageUrl",
      displayName: (
        <Icon name={"image"} classnames={"product-table-icon"} fontSize={18} />
      ),
      props: { width: "1%", style: { textAlign: "center" } },
      Component: CategoryTableImage,
    },
    {
      name: "name",
      displayName: "Name",
      props: {
        width: 250,
      },
      Component: CategoryTableLink,
      componentProps: {
        accessor: "name"
      },
      shouldSort: true,
    },
    {
      name: "quantity",
      displayName: "Qty",
      Component: ({ item }) => (
        <TableText item={{ qty: item.items.length }} accessor={"qty"} />
      ),
      props: {
        width: 150,
      },
    },
  ];

  return (
    <TableLayout
      noItemsMessage={<Typography>There are no categories to list.</Typography>}
      columns={categoryColumnsTable}
      actions={[
        {
          Component: EditCategoryButtonInTable,
          permission: "CATEGORIES.CATEGORIES_UPDATE",
        },
      ]}
      data={list}
      loading={isFetching}
      aria-label="categories table"
      className={"products-table"}
    />
  );
};

export default CategoriesTable;
