import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

import ModalWithOpener from "components/ModalWithOpener";

const ModalConfirm = ({
  message,
  Component,
  modalTitle,
  actionOnAccept,
  acceptButtonProps,
  actionOnAcceptText = "Ok",
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleAccept = (event) => {
    actionOnAccept(event);

    setOpenModal(false);
  };

  const handleOpen = (event) => {
    event.stopPropagation();

    setOpenModal(true);
  };

  const handleCancel = (event) => {
    setOpenModal(false);

    event.stopPropagation();
  };

  return (
    <ModalWithOpener
      OpenerComponent={<Component onClick={handleOpen} />}
      open={openModal}
      onClose={handleCancel}
    >
      <>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          {typeof message === "string" ? (
            <Typography component={"p"}>{message}</Typography>
          ) : (
            message
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAccept}
            color="secondary"
            {...acceptButtonProps}
          >
            {actionOnAcceptText}
          </Button>
        </DialogActions>
      </>
    </ModalWithOpener>
  );
};

ModalConfirm.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  modalTitle: PropTypes.string.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  actionOnAccept: PropTypes.func.isRequired,
  actionOnAcceptText: PropTypes.string,
  alertMode: PropTypes.bool,
};

export default ModalConfirm;
