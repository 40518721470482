import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import FormSelect from "components/FormSelect";
import { fetchWarehouseRows, unsetRowsList } from "store/warehouse/actions";
import { fetchOpenSpaces, unsetOpenSpacesList } from "store/open-space/actions";

const LocationWarehouseFormSelect = ({ field, form: { setFieldValue } }) => {
  const dispatch = useDispatch();

  const { list, rowsList } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );
  const { list: openSpacesList, fetched: openSpacesFetched } = useSelector(
    ({ openSpaceReducer }) => openSpaceReducer
  );
  const { selected: selectedContainer } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(
    field.value || "-1"
  );

  useEffect(() => {
    if (selectedContainer && selectedWarehouseId === "-1") {
      const warehouseId = selectedContainer.shelf
        ? selectedContainer.shelf.rack.row.warehouse._id
        : selectedContainer.openSpace
        ? selectedContainer.openSpace.warehouse._id
        : "-1";

      setSelectedWarehouseId(warehouseId);
      setFieldValue("warehouse", warehouseId);
    }
  }, [selectedWarehouseId, selectedContainer, setFieldValue]);

  const onChangeWarehouse = (value) => {
    setSelectedWarehouseId(value);
    setFieldValue(field.name, value);
  };

  useEffect(() => {
    if (selectedWarehouseId !== "-1") {
      setFieldValue("row", "-1");
      setFieldValue("rack", "-1");
      setFieldValue("type", "");

      dispatch(unsetOpenSpacesList());
      dispatch(unsetRowsList());
    }
  }, [dispatch, setFieldValue, selectedWarehouseId]);

  useEffect(() => {
    console.log('rowsList', rowsList);
    if (selectedWarehouseId !== "-1" && !rowsList.length) {
      console.log('useEffect fetchNewRows');
      fetchNewRows(selectedWarehouseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsList, selectedWarehouseId]);
  
  useEffect(() => {
    if (selectedWarehouseId !== "-1" && !openSpacesList.length && !openSpacesFetched) {
      fetchNewOpenSpaces(selectedWarehouseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSpacesList, selectedWarehouseId, openSpacesFetched]);

  function fetchNewRows(warehouse) {
    dispatch(fetchWarehouseRows(warehouse));
  }

  function fetchNewOpenSpaces(warehouse) {
    dispatch(fetchOpenSpaces({ warehouse }));
  }

  return (
    <FormSelect
      label={"Warehouse"}
      placeholder={"Select warehouse"}
      options={list}
      field={field}
      valueAccessor={"_id"}
      labelAccessor={"name"}
      fullWidth
      onChangeExternal={onChangeWarehouse}
    />
  );
};

LocationWarehouseFormSelect.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default LocationWarehouseFormSelect;
