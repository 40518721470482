function isValidNumber(number) {
  return number.toString().match(/^[0-9]*$/gm);
}

function validateNonNegativeNumber(number) {
  return isValidNumber(number) && parseFloat(number) > 0;
}

function validateUrl(url) {
  return url
    .toString()
    .match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
}
export { isValidNumber, validateNonNegativeNumber, validateUrl };
