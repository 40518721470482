import {
  SET_ROWS_LIST,
  SET_SELECTED_WAREHOUSE,
  SET_WAREHOUSE_STRUCTURE,
  SET_WAREHOUSES_LIST,
  UNSET_SELECTED_WAREHOUSE,
  SET_RACKS_LIST,
  SET_LEVELS_LIST,
  UNSET_RACKS_LIST,
  UNSET_ROWS_LIST,
  UNSET_LEVELS_LIST,
  SET_LOADING_WAREHOUSE_STRUCTURE,
  UNSET_LOADING_WAREHOUSE_STRUCTURE,
  SET_WAREHOUSE_MESSAGE,
  UNSET_WAREHOUSE_MESSAGE
} from "store/warehouse/types";

const warehouseReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_WAREHOUSES_LIST:
      return {
        ...state,
        list: payload.list,
      };
    case SET_SELECTED_WAREHOUSE:
      return {
        ...state,
        selected: payload.selected,
      };
    case UNSET_SELECTED_WAREHOUSE:
      return {
        ...state,
        selected: null,
      };
    case SET_ROWS_LIST:
      return {
        ...state,
        rowsList: payload.rowsList,
      };
    case SET_RACKS_LIST:
      return {
        ...state,
        racksList: payload.racksList,
      };
    case SET_LEVELS_LIST:
      return {
        ...state,
        levelsList: payload.levelsList,
      };
    case UNSET_RACKS_LIST:
      return {
        ...state,
        racksList: [],
      };
    case UNSET_ROWS_LIST:
      return {
        ...state,
        rowsList: [],
      };
    case UNSET_LEVELS_LIST:
      return {
        ...state,
        levelsList: [],
      };
    case SET_WAREHOUSE_STRUCTURE:
      return {
        ...state,
        warehouseStructure: payload.warehouseStructure,
      };
    case SET_LOADING_WAREHOUSE_STRUCTURE:
      return {
        ...state,
        structureLoading: true,
      };
    case UNSET_LOADING_WAREHOUSE_STRUCTURE:
      return {
        ...state,
        structureLoading: false,
      };
    case SET_WAREHOUSE_MESSAGE:
      return {
        ...state,
        message: payload.message,
      };
    case UNSET_WAREHOUSE_MESSAGE:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

const initialState = {
  list: [],
  selected: null,
  rowsList: [],
  racksList: [],
  levelsList: [],
  warehouseStructure: [],
  structureLoading: false,
  message: null,
};

export default warehouseReducer;
