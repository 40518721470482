import React, { useEffect, useRef } from 'react';

import './styles.scss';

const ImageWithZoom = ({ picture, title }) => {
  const imageRef = useRef();
  const zoomResultRef = useRef();
  const lensRef = useRef();
  
  const getCursorPos = e => {
    let a, x = 0, y = 0;

    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = imageRef.current.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;

    return {x, y};
  }

  const moveLens = e => {	
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    zoomResultRef.current.style.opacity = 1;
    /*get the cursor's x and y positions:*/
    const pos = getCursorPos(e);
    /*calculate the position of the lens:*/
    let x = pos.x - (lensRef.current.offsetWidth / 2);
    let y = pos.y - (lensRef.current.offsetHeight / 2);

    let cx = zoomResultRef.current.offsetWidth / lensRef.current.offsetWidth;
    let cy = zoomResultRef.current.offsetHeight / lensRef.current.offsetHeight;

    zoomResultRef.current.style.backgroundSize = (imageRef.current.width * cx) + "px " + (imageRef.current.height * cy) + "px";

    /*prevent the lens from being positioned outside the image:*/
    if (x > imageRef.current.width - lensRef.current.offsetWidth) {x = imageRef.current.width - lensRef.current.offsetWidth}
    if (x < 0) {x = 0;}
    if (y > imageRef.current.height - lensRef.current.offsetHeight) {y = imageRef.current.height - lensRef.current.offsetHeight;}
    if (y < 0) {y = 0;}
    /*set the position of the lens:*/
    lensRef.current.style.left = x + "px";
    lensRef.current.style.top = y + "px";
    /*display what the lens "sees":*/
    zoomResultRef.current.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
  }

  useEffect(() => {
    imageRef.current.parentElement.insertBefore(lensRef.current, imageRef.current);
    zoomResultRef.current.style.backgroundImage = "url('" + imageRef.current.src + "')";
  }, [picture]);

  const removeResult = () => {
    zoomResultRef.current.style.opacity = 0;
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="img-zoom-container">
        <div style={{position: 'relative'}}>
          <img src={picture} alt={title} ref={imageRef} onMouseMove={moveLens} onTouchMove={moveLens} onMouseLeave={removeResult} />
          <div className="img-zoom-lens" ref={lensRef} onMouseMove={moveLens} onTouchMove={moveLens} onMouseLeave={removeResult} />
        </div>
        <div className="img-zoom-result" ref={zoomResultRef} />
      </div>
    </div>
  );
}

export default ImageWithZoom;