import React from "react";

import { Grid } from "@material-ui/core";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import ContainersSearchBar from "screens/Containers/components/ContainersSearchBar";
import FilterContainers from "screens/Containers/components/FilterContainers";
import AddContainerModal from "screens/Containers/components/AddContainerModal";
import AuthWrapper from "components/AuthWrapper";

const containersHeaderComponents = [
  <Grid item xs={3} sm={3} md={3} lg={6}>
    <LayoutHeaderTitle title={"Containers"} />
  </Grid>,
  <Grid item xs={9} sm={9} md={9} lg={6} xl={6}>
    <Grid container alignItems={"center"} justify={"flex-end"} spacing={2}>
      <Grid item xs={3} md={3} lg={4}>
        <FilterContainers />
      </Grid>
      <Grid item xs={3} md={3} lg={4}>
        <ContainersSearchBar />
      </Grid>
      <AuthWrapper permission={"CONTAINERS.CONTAINERS_CREATE"}>
        <Grid item xs={3} md={3} lg={4}>
          <AddContainerModal />
        </Grid>
      </AuthWrapper>
    </Grid>
  </Grid>,
];

export default containersHeaderComponents;
