import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";

const ItemsQtyForTable = ({ item, accessor, ...props}) => {
  return <Typography {...props}>{item[accessor].length}</Typography>;
};

ItemsQtyForTable.propTypes = {
  item: PropTypes.object.isRequired,
  accessor: PropTypes.string.isRequired,
};

export default ItemsQtyForTable;
