import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { Paper, Typography } from "@material-ui/core";

import DeleteShelf from "screens/WarehouseDetail/components/warehouse-locations/DeleteShelf";

const LocationLevel = ({ level, locationConfig }) => {
  const { selected: selectedWarehouse } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );

  const itemsString = (level, rackIndex) => {
    return `WH${selectedWarehouse.number}.RO${locationConfig.name}.RA${locationConfig.racks[rackIndex].name}.L${level.name}`;
  };

  // We get all racks by level
  const racksByLevel = locationConfig.racks.map((rack, index) => {
    const levelFound = rack.shelves.find(
      (thisLevel) => thisLevel.name === level.name
    );

    if (levelFound) {
      return levelFound.name === level.name ? levelFound : null;
    }

    return levelFound;
  });

  return (
    <div
      className={classnames({
        "location-shelf-container": true,
        "location-shelf-container--first": level === 0,
      })}
    >
      {racksByLevel.map((level, index) =>
        !level ? (
          <div key={index} className={"location-shelf location-shelf--empty"} />
        ) : (
          <Paper className={"location-shelf"} key={index}>
            <Typography
              component={"span"}
              className={"location-shelf__content"}
            >
              {itemsString(level, index)}
            </Typography>
            <DeleteShelf shelf={level} />
          </Paper>
        )
      )}
    </div>
  );
};

LocationLevel.propTypes = {
  level: PropTypes.object.isRequired,
  locationConfig: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    racks: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        shelves: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }),
};

export default LocationLevel;
