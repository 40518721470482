import React from "react";
import {useHistory} from 'react-router-dom';
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";

import Icon from "components/Icon";

const GoToItemEditionButton = ({ item }) => {
  const history = useHistory();

  const navigateToItemEdition = () => {
    history.push(`/inventory/items/edit/${item._id}`);
  }

  return (
    <Button
      fullWidth
      startIcon={<Icon name={"edit"} />}
      color={"secondary"}
      variant={"contained"}
      onClick={navigateToItemEdition}
      style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 16, paddingLeft: 16 }}
    >
      Edit
    </Button>
  );
};

GoToItemEditionButton.propTypes = {
  item: PropTypes.object.isRequired,
};

export default GoToItemEditionButton;
