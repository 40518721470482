import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ArrowDropDown, ArrowRight } from "@material-ui/icons";

import { Button, DialogActions, Grid, Typography } from "@material-ui/core";

import FormWrapper from "components/FormWrapper";
import FormSelect from "components/FormSelect";
import ContainerAutocomplete from "screens/InventoryItems/components/ContainerAutocomplete";
import ConfirmLocationSavingModal from "screens/InventoryItems/components/ConfirmLocationSavingModal";

import {
  fetchWarehouseLevels,
  fetchWarehouseRacks,
  fetchWarehouseRows,
  fetchWarehouses,
  unsetLevelsList,
  unsetRacksList,
  unsetRowsList,
} from "store/warehouse/actions";
import { fetchOpenSpaces, unsetOpenSpacesList } from "store/open-space/actions";

import {
  fetchContainers,
  unsetContainersAutocompleteList,
} from "store/container/actions";

import "./LocationSelectionForm.scss";

const LocationSelectionForm = ({
  handleSubmit,
  handleCancelAction,
  cancelActionText = "cancel",
  onSubmitActionText = "save",
}) => {
  const dispatch = useDispatch();

  const { selected } = useSelector(({ itemReducer }) => itemReducer);
  const { list: containersList } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  const [selectedRack, setSelectedRack] = useState(
    selected && selected?.shelf ? selected.shelf.rack : null
  );

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [isBasicSearch, setIsBasicSearch] = useState(true);
  const [containerIsFull, setContainerIsFull] = useState(false);

  const { list: warehousesList, racksList, rowsList, levelsList } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );

  const { list: openSpacesList } = useSelector(
    ({ openSpaceReducer }) => openSpaceReducer
  );

  const initialValues = {
    warehouse: "-1",
    row: "-1",
    rack: "-1",
    shelf: "-1",
    containerOnly: "",
    isFull: false,
  };

  useEffect(() => {
    dispatch(fetchWarehouses(true));
  }, [dispatch]);

  function fetchNewRows(warehouse) {
    dispatch(fetchWarehouseRows(warehouse));
    dispatch(fetchOpenSpaces({ warehouse }, true));
  }

  const fetchRacksByRows = (row) => {
    dispatch(fetchWarehouseRacks(row));
  };

  const fetchShelves = (rack) => {
    dispatch(fetchWarehouseLevels(rack));
  };

  const fetchContainersByShelf = (shelf) => {
    dispatch(fetchContainers({ shelf }));
  };

  const fetchContainersByOpenSpace = (openSpace) => {
    dispatch(fetchContainers({ openSpace }));
  };

  const toggleSearchType = () => {
    setIsBasicSearch((isBasicSearch) => !isBasicSearch);
  };

  let locationFields = [
    {
      name: "containerOnly",
      component: ContainerAutocomplete,
      props: {
        label: "",
        placeholder: "Container",
        disabled: !isBasicSearch,
        onSelectExternal: (value) => {
          setContainerIsFull(value && value.length && JSON.parse(value).isFull);
        },
      },
    },
    {
      name: "selectSearch",
      type: "textOnly",
      component: (
        <Grid
          container
          onClick={toggleSearchType}
          className={"location-selection"}
          alignItems={"center"}
        >
          <Grid item>{isBasicSearch ? <ArrowRight /> : <ArrowDropDown />}</Grid>
          <Grid item>
            <Typography className={"location-selection-text"}>
              {isBasicSearch ? "Or try Advanced search" : "Basic search"}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
  ];

  if (!isBasicSearch) {
    locationFields = [
      ...locationFields,
      {
        name: "warehouse",
        component: FormSelect,
        props: {
          options: warehousesList,
          fullWidth: true,
          label: "Warehouse",
          placeholder: "Warehouse",
          valueAccessor: "_id",
          labelAccessor: "name",
          onChangeExternal: (value, setFieldValue) => {
            setSelectedWarehouse(value);
            fetchNewRows(value);

            setFieldValue("row", null);
            setFieldValue("rack", null);
            setFieldValue("containerOnly", "");

            setSelectedRow(null);
            setSelectedRack(null);
            setSelectedShelf(null);

            dispatch(unsetRacksList());
            dispatch(unsetRowsList());
            dispatch(unsetLevelsList());
            dispatch(unsetOpenSpacesList());
            dispatch(unsetContainersAutocompleteList());
          },
        },
      },
      {
        name: "row",
        component: FormSelect,
        props: {
          options: [
            {
              type: "openSpace",
              categoryName: "Open spaces",
              options: openSpacesList.map((row) => ({
                _id: row._id,
                name: row.name,
              })),
            },
            {
              type: "shelf",
              categoryName: "Rows",
              options: rowsList.map((row) => ({
                _id: row._id,
                name: row.name,
              })),
            },
          ],
          fullWidth: true,
          label: "Placeholder",
          placeholder: "Row",
          valueAccessor: "_id",
          labelAccessor: "name",
          onChangeExternal: (value, setFieldValue) => {
            setSelectedRow(value.value);
            setSelectedType(value.group);

            setSelectedRack(null);
            setSelectedShelf(null);

            setFieldValue("rack", null);
            setFieldValue("type", value.group);

            if (value.group === "shelf") {
              fetchRacksByRows(value.value);
            }

            if (value.group === "openSpace") {
              fetchContainersByOpenSpace(value.value);
            }

            dispatch(unsetRacksList());
            dispatch(unsetLevelsList());
            dispatch(unsetContainersAutocompleteList());
          },
          disabled:
            !selectedWarehouse && !rowsList.length && !openSpacesList.length,
          hasGroups: true,
          customCondition:
            !!selectedWarehouse && !!rowsList.length && !!openSpacesList.length,
        },
      },
    ];

    if (selectedRow && selectedType === "openSpace") {
      locationFields = [
        ...locationFields,
        {
          name: "container",
          component: FormSelect,
          props: {
            options: containersList,
            fullWidth: true,
            label: "Container",
            placeholder: "Container",
            valueAccessor: "_id",
            labelAccessor: "name",
            onChangeExternal: (value, setFieldValue, fullData) => {
              setFieldValue("type", "container");

              const containerFullness = JSON.parse(fullData).isFull;

              setFieldValue("isFull", containerFullness);
              setContainerIsFull(containerFullness);
            },
            disabled:
              !rowsList.length &&
              !openSpacesList.length &&
              !containersList.length,
            customCondition:
              !!rowsList.length &&
              !!openSpacesList.length &&
              !!containersList.length,
          },
        },
      ];
    }

    if (selectedRow && selectedType === "shelf") {
      locationFields = [
        ...locationFields,
        {
          name: "rack",
          component: FormSelect,
          props: {
            options: racksList,
            fullWidth: true,
            label: "Rack",
            placeholder: "Rack",
            valueAccessor: "_id",
            labelAccessor: "name",
            onChangeExternal: (value, setFieldValue) => {
              dispatch(unsetLevelsList());
              dispatch(unsetContainersAutocompleteList());
              setFieldValue("shelf", "-1");

              setSelectedRack(value);
              fetchShelves(value);
            },
            disabled:
              !racksList.length && !rowsList.length && !openSpacesList.length,
            customCondition:
              !!racksList.length &&
              !!rowsList.length &&
              !!openSpacesList.length,
          },
        },
        {
          name: "shelf",
          component: FormSelect,
          props: {
            options: levelsList,
            fullWidth: true,
            label: "Shelf",
            placeholder: "Shelf",
            valueAccessor: "_id",
            labelAccessor: "name",
            onChangeExternal: (value, setFieldValue) => {
              dispatch(unsetContainersAutocompleteList());
              setSelectedShelf(value);
              fetchContainersByShelf(value);
              setFieldValue("type", "shelf");
            },
            disabled:
              !selectedRack &&
              !levelsList.length &&
              !racksList.length &&
              !rowsList.length &&
              !openSpacesList.length,
            customCondition:
              !!selectedRack &&
              !!levelsList.length &&
              !!racksList.length &&
              !!rowsList.length &&
              !!openSpacesList.length,
          },
        },
        {
          name: "container",
          component: FormSelect,
          props: {
            options: containersList.map((container) => ({
              _id: container._id,
              name: `${container.type.name} ${container.name}`,
              isFull: container.isFull,
            })),
            fullWidth: true,
            label: "Container",
            placeholder: "Container",
            valueAccessor: "_id",
            labelAccessor: "name",
            onChangeExternal: (value, setFieldValue, fullData) => {
              setFieldValue("type", "container");

              const containerFullness = JSON.parse(fullData).isFull;

              setFieldValue("isFull", containerFullness);
              setContainerIsFull(containerFullness);
            },
            disabled:
              !selectedShelf &&
              !levelsList.length &&
              !racksList.length &&
              !rowsList.length &&
              !openSpacesList.length &&
              !containersList.length,
            customCondition:
              !!selectedShelf &&
              !!levelsList.length &&
              !!racksList.length &&
              !!rowsList.length &&
              !!openSpacesList.length &&
              !!containersList.length,
          },
        },
      ];
    }
  }

  const SaveButton = ({ formProps }) => {
    if (containerIsFull) {
      return <ConfirmLocationSavingModal {...formProps} />;
    } else {
      return (
        <Button color="secondary" variant={"contained"} {...formProps}>
          {onSubmitActionText}
        </Button>
      );
    }
  };

  return (
    <FormWrapper
      formProps={{ enableReinitialize: true }}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      fields={locationFields}
      OnSubmitActionComponent={(formProps) => {
        return (
          <DialogActions>
            <Button color="secondary" onClick={handleCancelAction}>
              {cancelActionText}
            </Button>
            {formProps && <SaveButton formProps={formProps} />}
          </DialogActions>
        );
      }}
    />
  );
};

LocationSelectionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancelAction: PropTypes.func.isRequired,
  cancelActionText: PropTypes.string,
};

export default LocationSelectionForm;
