import Warehouses from "screens/Warehouses";
import WarehouseCreateEdit from "screens/WarehouseCreateEdit";
import WarehouseDetail from "screens/WarehouseDetail";

const warehouseRoutes = [
  {
    path: "/warehouses",
    component: Warehouses,
    name: "Warehouses",
    featureKey: "WAREHOUSE.WAREHOUSE_READ",
    exact: true
  },
  {
    path: "/warehouses/detail/:id",
    component: WarehouseDetail,
    name: "WarehouseDetail",
    featureKey: "WAREHOUSE.WAREHOUSE_READ_DETAIL",
    exact: true
  },
  {
    path: "/warehouses/create",
    component: WarehouseCreateEdit,
    name: "WarehouseCreate",
    featureKey: "WAREHOUSE.WAREHOUSE_CREATE",
    exact: true
  },
  {
    path: "/warehouses/edit/:id",
    component: WarehouseCreateEdit,
    name: "WarehouseEdit",
    featureKey: "WAREHOUSE.WAREHOUSE_UPDATE",
    exact: true
  },
];

export default warehouseRoutes;
