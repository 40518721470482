import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";

import GridList from "components/GridList";
import OpenSpaceCard from "screens/WarehouseDetail/components/open-spaces/OpenSpaceCard";

import { fetchOpenSpaces } from "store/open-space/actions";

import {SUCCESS_STATUS} from "components/MessageService/constants";

const OpenSpacesList = (props) => {
  const dispatch = useDispatch();

  const { selected: selectedWarehouse } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );

  const { list } = useSelector(({ openSpaceReducer }) => openSpaceReducer);
  const { loading, messagePayload } = useSelector(({helperReducer}) => helperReducer);

  useEffect(() => {
    dispatch(fetchOpenSpaces({ warehouse: selectedWarehouse._id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      messagePayload &&
      messagePayload.type === SUCCESS_STATUS &&
      messagePayload.shouldUpdate
    ) {

      dispatch(fetchOpenSpaces({ warehouse: selectedWarehouse._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, messagePayload]);

  return (
    <GridList
      spacing={2}
      gridItemProps={{
        lg: 2,
        md: 3,
        sm: 3,
        xs: 6,
      }}
      noItemsMessage={
        <Typography>
          There's no created open spaces for this warehouse.
        </Typography>
      }
      ItemComponent={OpenSpaceCard}
      list={list}
      loading={loading}
    />
  );
};

OpenSpacesList.propTypes = {};

export default OpenSpacesList;
