import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Button } from "@material-ui/core";

import Icon from "components/Icon";
import {
  setCategoryGridListing,
  setCategoryTableListing,
} from "store/category/actions";

const CategoryListingDisplaySelector = () => {
  const CATEGORY_LIST_DISPLAY = "table";
  const CATEGORY_GRID_DISPLAY = "grid";

  const dispatch = useDispatch();

  const { selectedDisplayList } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );

  const handleSelectDisplayList = () => {
    dispatch(setCategoryTableListing());
  };

  const handleSelectDisplayGrid = () => {
    dispatch(setCategoryGridListing());
  };

  return (
    <Grid container>
      <Grid item>
        <Button
          onClick={handleSelectDisplayList}
          color={"secondary"}
          variant={
            selectedDisplayList === CATEGORY_LIST_DISPLAY
              ? "contained"
              : null
          }
        >
          <Icon name={"list"} fontSize={18}/>
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleSelectDisplayGrid}
          color={"secondary"}
          variant={
            selectedDisplayList === CATEGORY_GRID_DISPLAY
              ? "contained"
              : null
          }
        >
          <Icon name={"grid"} fontSize={18} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CategoryListingDisplaySelector;
