import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { Typography } from "@material-ui/core";

import GridList from "components/GridList";
import CategoryCard from "screens/Categories/components/CategoryCard";

import { fetchCategories } from "store/category/actions";
import { SUCCESS_STATUS } from "components/MessageService/constants";

const CategoriesGridList = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = queryString.parse(location.search);

  const { list, isFetching } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );
  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  useEffect(() => {
    if (
      messagePayload &&
      messagePayload.type === SUCCESS_STATUS &&
      messagePayload.shouldUpdate
    ) {

      dispatch(fetchCategories(searchParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, messagePayload]);

  return (
    <GridList
      gridItemProps={{
        xs: 2,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
      }}
      spacing={2}
      list={list}
      ItemComponent={CategoryCard}
      noItemsMessage={<Typography>There are no categories to list.</Typography>}
      loading={isFetching}
    />
  );
};

export default CategoriesGridList;
