import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { IconButton, Typography } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";

import ScreenLayout from "components/ScreenLayout";
import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import MainWarehouseIndicator from "screens/Warehouses/components/MainWarehouseIndicator";
import InnerPageSection from "components/InnerPageSection";
import AuthWrapper from "components/AuthWrapper";
import ModalAlert from "components/ModalAlert";

import {
  ERROR_STATUS_NOT_FOUND,
  SUCCESS_STATUS,
} from "components/MessageService/constants";

import {
  fetchSingleWarehouse,
  fetchWarehouseStructure,
  unsetWarehouseMessage,
} from "store/warehouse/actions";
import warehouseDetailSections from "screens/WarehouseDetail/sections";

import "./styles.scss";

const WarehouseDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const { message } = useSelector(({ warehouseReducer }) => warehouseReducer);

  const closeAlert = (event) => {
    event.stopPropagation();

    dispatch(unsetWarehouseMessage());
  };

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);
  const { selected: selectedWarehouse } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );

  // Warehouse detail fetch
  useEffect(() => {
    dispatch(fetchSingleWarehouse(params.id));
    dispatch(fetchWarehouseStructure(params.id));
  }, [dispatch, params]);

  // Basic handling for not existing routes.
  useEffect(() => {
    const handleNavigation = () => {
      if (messagePayload) {
        if (
          (params.id && messagePayload.type === ERROR_STATUS_NOT_FOUND) ||
          (messagePayload.type === SUCCESS_STATUS && !selectedWarehouse)
        ) {
          history.push("/warehouses");
        }
      }
    };

    handleNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload, params]);

  const goToEditForm = () => {
    history.push(`/warehouses/edit/${params.id}`);
  };

  return selectedWarehouse ? (
    <ScreenLayout
      headerComponents={[
        <div className={"warehouse-detail-main-header"}>
          <LayoutHeaderTitle
            title={`WH ${selectedWarehouse.number} - ${selectedWarehouse.name}`}
          />
          <div className={"warehouse-detail-main-indicator"}>
            <MainWarehouseIndicator
              isMain={selectedWarehouse.isMain}
              style={{ fontSize: 35 }}
            />
          </div>
          <AuthWrapper permission={"WAREHOUSE.WAREHOUSE_UPDATE"}>
            <IconButton onClick={goToEditForm}>
              <EditIcon />
            </IconButton>
          </AuthWrapper>
        </div>,
      ]}
    >
      {warehouseDetailSections.map((section, index) => (
        <InnerPageSection
          key={index}
          title={section.title}
          sectionTitleComponent={section.sectionTitleComponent}
          action={section.action}
        >
          {section.content}
        </InnerPageSection>
      ))}
      {!!message && (
        <ModalAlert
          open={!!message}
          closeModal={closeAlert}
          title={message.title}
        >
          <Typography>{message.message}</Typography>
        </ModalAlert>
      )}
    </ScreenLayout>
  ) : null;
};

WarehouseDetail.propTypes = {};

export default WarehouseDetail;
