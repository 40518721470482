import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

import "./styles.scss";

/**
 * Component to display sections in inner pages
 *
 * @param sectionTitleComponent
 * @param title
 * @param action
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const InnerPageSection = ({ sectionTitleComponent, title, action, children }) => {
  return (
    <div className={"inner-page-section"}>
      <div className="inner-page-section__header">
        <div className={"inner-page-section__header-item"}>
          {sectionTitleComponent || <Typography className={'inner-page-section-title'}>{title}</Typography>}
        </div>
        <div className={"inner-page-section__header-item"}>{action}</div>
      </div>
      <div className="inner-page-section__content">
        {children}
      </div>
    </div>
  );
};

InnerPageSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  sectionTitleComponent: PropTypes.object,
  title: PropTypes.string,
  action: PropTypes.object,
};

export default InnerPageSection;
