import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { IconButton } from "@material-ui/core";

import ModalConfirm from "components/ModalConfirm";
import Icon from "components/Icon";
import AuthWrapper from "components/AuthWrapper";

import { deleteRack } from "store/warehouse/actions";

const DeleteRack = ({ rack }) => {
  const dispatch = useDispatch();

  const deleteThisRack = (event) => {
    event.stopPropagation();

    dispatch(deleteRack(rack._id));
  };

  return (
    <AuthWrapper permission={"LOCATIONS.LOCATIONS_UPDATE"}>
      <ModalConfirm
        modalTitle={`Delete rack ${rack.name}`}
        actionOnAccept={deleteThisRack}
        message={"Are you sure that you want to delete this rack?"}
        Component={(props) => (
          <IconButton {...props}>
            <Icon name={"trash-can"} fontSize={15} />
          </IconButton>
        )}
        actionOnAcceptText={"Delete rack"}
      />
    </AuthWrapper>
  );
};

DeleteRack.propTypes = {
  rack: PropTypes.object.isRequired,
};

export default DeleteRack;
