import React, { useEffect, useState } from "react";
import Icon from "components/Icon";
import { Select } from "antd";
import { useSelector } from "react-redux";

import ItemTableLink from "screens/InventoryItems/components/ItemTableLink";
import ItemGallery from "screens/InventoryItems/components/ItemGallery";
import TableDate from "components/TableDate";
import TableText from "components/TableText";
import TableCurrency from "components/TableCurrency";

import TableItemListing from "screens/InventoryItems/components/TableItemListing";
import TableItemStatus from "screens/InventoryItems/components/TableItemStatus";
import TableItemCategory from "./TableItemCategory";
import axios from "axios";
import { baseUrl, headers } from "../../../lib/api/constants";

import "../styles.scss";
const { Option } = Select;

const ColumnsFilter = ({ setColumns, columns }) => {
  const { selected: selectedCategory } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );
  const [listCategories, setListCategories] = useState([]);

  async function getCategories() {
    const r = await axios
      .get(`${baseUrl}/categorieslist`, {
        method: "GET",
        headers,
      })
      .catch((err) => console.log(err));

    if (r?.data) {
      setListCategories(r.data.category);
    }
  }

  useEffect(() => {
    let abortController = new AbortController();
    if (listCategories.length === 0) {
      getCategories();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsArray = [
    {
      name: "imageUrl",
      displayName: <Icon name={"image"} classnames={"product-table-icon"} />,
      props: {
        width: 70,
        style: { textAlign: "center" },
      },
      Component: ItemGallery,
      status: true,
      disabled: true,
    },
    {
      name: "name",
      displayName: "Name",
      Component: ItemTableLink,
      props: {
        width: 120,
        style: {
          textAlign: "left",
          overflow: "auto",
          marginLeft: 20,
          marginRight: 20,
        },
      },
      componentProps: {
        accessor: "name",
      },
      shouldSort: true,
      status: true,
    },
    {
      name: "serialNumber",
      displayName: "Serial no.",
      props: {
        width: 140,
        style: { textAlign: "center" },
      },
      Component: ItemTableLink,
      componentProps: {
        accessor: "serialNumber",
        defaultValue: "N/A",
      },
      shouldSort: true,
      status: true,
    },
    {
      name: "number",
      displayName: "SKU",
      numeric: true,
      props: {
        width: 80,
        style: { textAlign: "center" },
      },
      componentProps: {
        accessor: "number",
      },
      Component: ItemTableLink,
      shouldSort: true,
      status: true,
    },
    {
      name: "manufacturer",
      displayName: "Manufacturer",
      props: {
        width: 150,
        style: {
          textAlign: "left",
          overflow: "auto",
        },
      },
      Component: ItemTableLink,
      componentProps: {
        accessor: "manufacturer",
      },
      shouldSort: true,
      status: true,
    },
    {
      name: "category",
      displayName: "Category",
      props: {
        width: 150,
        style: { textAlign: "right" },
      },
      // Component: ItemCategory,
      Component: ({ item }) => (
        <TableItemCategory item={item} listCategories={listCategories} />
      ),
      shouldSort: true,
      shouldBeHidden: !!selectedCategory,
      status: true,
    },

    {
      name: "location",
      displayName: "Location",
      props: {
        width: 170,
        style: {
          textAlign: "center",
          overflow: "auto",
          marginLeft: 20,
          marginRight: 20,
        },
      },
      Component: TableText,
      shouldSort: true,
      status: true,
    },
    {
      name: "dateOfAdquisition",
      displayName: "Acquisition date",
      Component: TableDate,
      componentProps: {
        accessor: "dateOfAdquisition",
      },
      shouldSort: true,
      numeric: true,
      props: {
        width: 180,
      },
      status: false,
    },
    {
      name: "source",
      displayName: "Source",
      Component: ItemTableLink,
      shouldSort: true,
      componentProps: {
        accessor: "source",
      },
      props: {
        width: 140,
        style: { textAlign: "center" },
      },
      status: false,
    },
    {
      name: "status",
      displayName: "Status",
      Component: ({ item }) => <TableItemStatus item={item} />,
      props: {
        width: 230,
        style: { textAlign: "right" },
      },
      shouldSort: true,
      status: true,
    },
    {
      name: "listings",
      displayName: "Listings",
      Component: TableItemListing,
      props: {
        width: 140,
      },
      status: true,
    },
    {
      name: "condition",
      displayName: "Condition",
      Component: ({ item }) => (
        <TableText
          accessor={"condition"}
          item={{
            condition: item.condition ? item.condition.name : "N/A",
          }}
        />
      ),
      props: {
        width: 170,
      },
      shouldSort: true,
      status: true,
    },
    {
      name: "cost",
      displayName: "Cost",
      Component: TableCurrency,
      numeric: true,
      props: {
        width: 140,
        style: { textAlign: "center" },
      },
      shouldSort: true,
      permission: "ITEMS.ITEMS_READ_DETAIL_PRICING",
      status: false,
    },
    {
      name: "finalPrice",
      displayName: "Final price",
      Component: TableCurrency,
      numeric: true,
      props: {
        width: 140,
        style: { textAlign: "center" },
      },
      shouldSort: true,
      permission: "ITEMS.ITEMS_READ_DETAIL_PRICING",
      status: true,
    },
  ];

  useEffect(() => {
    const columnsActives = columnsArray.filter((c) => c.status === true);
    setColumns(columnsActives);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCategories]);

  let array = [];
  const columnsActives = columnsArray.filter((c) => c.status === true);
  columnsActives.map((c) =>
    typeof c.displayName !== "object" ? array.push(c.displayName) : null
  );

  const modifycolumns = (selected) => {
    setColumns([...columns, selected]);
  };

  const DeleteColumn = (selected) => {
    const newColumns = columns.filter(
      (col) => col.displayName !== selected.displayName
    );
    setColumns(newColumns);
  };

  return (
    <Select
      size="large"
      mode="multiple"
      showArrow
      suffixIcon={
        <Icon
          name={"dropdown-arrow"}
          type={"solid"}
          fontSize={16}
          color={"rgba(0, 0, 0, 0.54)"}
        />
      }
      // placeholder="Select Colums"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSelect={(option, value) => {
        modifycolumns(value.item);
      }}
      onDeselect={(option, value) => DeleteColumn(value.item)}
      clearIcon={<Icon name={"cancel"} fontSize={20} color={"black"} />}
      className={"autocomplete-wrapper"}
      styles={{ overflow: "hidden" }}
      defaultValue={array}
    >
      {columnsArray.map((column) => {
        return (
          <Option
            key={column.name}
            value={column.displayName}
            item={column}
            disabled={typeof column.displayName === "object" ? true : false}
          >
            {column.displayName}
          </Option>
        );
      })}
    </Select>
  );
};

export default ColumnsFilter;
