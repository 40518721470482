const SET_WAREHOUSES_LIST = "WAREHOUSES/SET_WAREHOUSES_LIST";
const SET_SELECTED_WAREHOUSE = "WAREHOUSE/SET_SELECTED_WAREHOUSE";
const UNSET_SELECTED_WAREHOUSE = "WAREHOUSE/UNSET_SELECTED_WAREHOUSE";
const SET_ROWS_LIST = "WAREHOUSE/SET_ROWS_LIST";
const SET_RACKS_LIST = "WAREHOUSE/SET_RACKS_LIST";
const SET_LEVELS_LIST = "WAREHOUSE/SET_LEVELS_LIST";
const UNSET_ROWS_LIST = "WAREHOUSE/UNSET_ROWS_LIST";
const UNSET_RACKS_LIST = "WAREHOUSE/UNSET_RACKS_LIST";
const UNSET_LEVELS_LIST = "WAREHOUSE/UNSET_LEVELS_LIST";
const SET_WAREHOUSE_STRUCTURE = "WAREHOUSE/SET_WAREHOUSE_STRUCTURE";
const SET_LOADING_WAREHOUSE_STRUCTURE = "WAREHOUSE/SET_LOADING_WAREHOUSE_STRUCTURE";
const UNSET_LOADING_WAREHOUSE_STRUCTURE = "WAREHOUSE/UNSET_LOADING_WAREHOUSE_STRUCTURE";
const SET_WAREHOUSE_MESSAGE = "WAREHOUSE/SET_WAREHOUSE_MESSAGE";
const UNSET_WAREHOUSE_MESSAGE = "WAREHOUSE/UNSET_WAREHOUSE_MESSAGE";

export {
  SET_SELECTED_WAREHOUSE,
  SET_WAREHOUSES_LIST,
  UNSET_SELECTED_WAREHOUSE,
  SET_ROWS_LIST,
  SET_RACKS_LIST,
  SET_LEVELS_LIST,
  UNSET_ROWS_LIST,
  UNSET_RACKS_LIST,
  UNSET_LEVELS_LIST,
  SET_WAREHOUSE_STRUCTURE,
  SET_LOADING_WAREHOUSE_STRUCTURE,
  UNSET_LOADING_WAREHOUSE_STRUCTURE,
  SET_WAREHOUSE_MESSAGE,
  UNSET_WAREHOUSE_MESSAGE
};
