import React from "react";
import PropTypes from "prop-types";

import ModalGallery from "components/ModalGallery";
import { Typography } from "@material-ui/core";

const ItemGallery = ({ item }) => {
  return (
    <ModalGallery
      pictures={item.pictures}
      title={`${item.name} - ${item.model}`}
      noItemsMessage={
        <Typography>This item doesn't have pictures to display.</Typography>
      }
    />
  );
};

ItemGallery.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemGallery;
