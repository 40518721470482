import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Button, CircularProgress } from "@material-ui/core";

import Icon from "components/Icon";

import * as loadImage from "blueimp-load-image";

import { updateItemImage } from "store/item/actions";

import "./styles.scss";

const EditImageModal = ({ picture, title, selectedImageIndex, type }) => {
  const dispatch = useDispatch();

  const { isUploaded } = useSelector(({ itemReducer }) => itemReducer);

  const canvasContextRefDiv = useRef();

  const [selectedOrientation, setSelectedOrientation] = useState(1);

  useEffect(() => {
    async function fetchImage() {
      try {
        loadImage(
          picture,
          (image) => {
            if (canvasContextRefDiv.current?.hasChildNodes()) {
              canvasContextRefDiv.current.removeChild(
                canvasContextRefDiv.current.childNodes[0]
              );
            }

            canvasContextRefDiv.current?.appendChild(image);
          },
          {
            canvas: true,
            orientation: selectedOrientation,
            maxHeight: 320,
            maxWidth: 410,
            crossOrigin: "anonymous",
          }
        );
      } catch (e) {
        console.error(e);
      }
    }

    fetchImage();
  }, [picture, selectedOrientation]);

  useEffect(() => {
    setSelectedOrientation(1);
  }, [picture]);

  // Determines where to rotate
  const getRotationValue = (orientation) => {
    switch (selectedOrientation) {
      case 1:
        setSelectedOrientation(orientation > 0 ? 6 : 8);
        break;
      case 3:
        setSelectedOrientation(orientation > 0 ? 8 : 6);
        break;
      case 6:
        setSelectedOrientation(orientation > 0 ? 3 : 1);
        break;
      case 8:
        setSelectedOrientation(orientation > 0 ? 1 : 3);
        break;
      default:
        setSelectedOrientation(1);
    }
  };

  const rotateLeft = () => {
    getRotationValue(-1);
  };

  const rotateRight = () => {
    getRotationValue(1);
  };

  const saveImage = async () => {
    const canvasElement = canvasContextRefDiv.current.childNodes[0];

    const imageBase64 = canvasElement.toDataURL("image/png");
    const binaryImage = await fetch(imageBase64).then((imageResponse) =>
      imageResponse.blob()
    );

    let fileName = null;

    if (type === "AMAZON") {
      const urlPictureObject = new URL(picture);
      const { pathname } = urlPictureObject;
      fileName = pathname.replace("/uploads/amazons3/", "").replace(".png", "");
    }

    dispatch(updateItemImage(binaryImage, fileName, selectedImageIndex));
  };

  return (
    <div>
      <div ref={canvasContextRefDiv} className="canvas-context" />
      <Grid container justify="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <Button
                onClick={rotateLeft}
                color="secondary"
                disabled={!isUploaded}
              >
                <Icon name="rotate-left" fontSize={23} />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={rotateRight}
                color="secondary"
                disabled={!isUploaded}
              >
                <Icon name="rotate-right" fontSize={23} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                onClick={saveImage}
                color="secondary"
                variant="contained"
                size="small"
                disabled={selectedOrientation === 1 || !isUploaded}
              >
                {isUploaded ? (
                  <Icon name="check" fontSize={23} />
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditImageModal;
