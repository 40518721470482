import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";

import {
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
} from "@material-ui/core";

import Icon from "components/Icon";
import AuthWrapper from "components/AuthWrapper";

const TableLayoutHead = ({
  columns,
  withCheckbox,
  actionColumnProps = {},
  shouldDisplayActions = false,
}) => {
  // TODO: sort label logic could be moved to a different component

  const location = useLocation();
  const history = useHistory();

  const searchQueryParams = queryString.parse(location.search);

  /**
   * Keeps order preference following location's search query
   */
  const [order, setOrder] = useState(
    searchQueryParams?.sort?.includes("-") ? "desc" : "asc"
  );

  /**
   * Keeps order criteria following location's search query
   */
  const [orderBy, setOrderBy] = useState(
    searchQueryParams?.sort?.replace("-", "")
  );

  /**
   * Handle cell click for sorting action
   * Basically sets sorting config to allow search query update
   *
   * @param property
   * @returns {function(*): void}
   */
  const sortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    sortByAction({ orderBy: property, order: isAsc ? "desc" : "asc" });
  };

  /**
   * Writes search query in url to allow sorting
   *
   * @param config
   */
  const sortByAction = (config) => {
    const searchParams = queryString.parse(location.search);

    const searchQuery = new URLSearchParams({
      ...searchParams,
      sort: `${config.order === "desc" ? "-" : ""}${config.orderBy}`,
    });

    history.push({
      path: location.pathname,
      search: searchQuery.toString(),
    });
  };

  /**
   * Render sorting icon following order and selected criteria
   *
   * @param columnName
   * @returns {JSX.Element}
   */
  const renderSortIcon = (columnName) => {
    let iconName = "sort-alt";

    if (orderBy === columnName) {
      iconName = order === "asc" ? "sort-asc" : "sort-desc";
    }

    return <Icon name={iconName} classnames={"table-heading-sort-icon"} />;
  };

  return (
    <TableHead>
      <TableRow>
        {withCheckbox && (
          <TableCell padding={"checkbox"} className="checkBoxTable" />
        )}
        {columns?.map((column) => (
          <AuthWrapper
            permission={column.permission || "WILDCARD"}
            key={column.name}
          >
            {column.shouldBeHidden ? null : (
              <TableCell
                className={"table-layout-cell "}
                align={column.numeric ? "center" : "center"}
                padding={column.disablePadding ? "none" : "normal"}
                sortDirection={
                  column.shouldSort && orderBy === column.name ? order : false
                }
                {...column?.props}
              >
                {column.shouldSort ? (
                  <TableSortLabel
                    active={true}
                    direction={orderBy === column.name ? order : "asc"}
                    onClick={sortHandler(column.name)}
                    IconComponent={() => renderSortIcon(column.name)}
                  >
                    {column.displayName}
                  </TableSortLabel>
                ) : (
                  column.displayName
                )}
              </TableCell>
            )}
          </AuthWrapper>
        ))}
        {shouldDisplayActions && (
          <TableCell
            className={"table-heading-cell"}
            {...actionColumnProps}
            width={actionColumnProps?.width || "5%"}
            style={{ textAlign: "center" }}
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

TableLayoutHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      props: PropTypes.object,
      shouldSort: PropTypes.bool,
    })
  ).isRequired,
  sortByAction: PropTypes.func,
  sortOrder: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      sortBy: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
    }),
  ]),
  shouldDisplayActions: PropTypes.bool,
};

export default TableLayoutHead;
