import React from "react";

import itemSubcomponentsFormValidate from "screens/ItemCreateEdit/components/itemSubcomponentsFormValidate";
import FormInput from "components/FormInput";
import FormArrayBasic from "components/FormArrayBasic";

import { Typography } from "@material-ui/core";

const itemSubcomponentFormFields = [
  {
    name: "subcomponents",
    type: "fieldArray",
    component: FormArrayBasic,
    props: {
      unityName: "Subcomponent",
      formArrayInitialValues: {
        name: "",
        condition: "",
        serialNumber: "",
      },
      fields: [
        {
          name: "name",
          component: FormInput,
          props: {
            externalLabel: true,
            label: "",
            placeholder: "Insert subcomponent name",
          },
        },
        {
          name: "condition",
          component: FormInput,
          props: {
            externalLabel: true,
            label: "",
            placeholder: "Insert subcomponent condition",
          },
        },
        {
          name: "serialNumber",
          component: FormInput,
          props: {
            externalLabel: true,
            label: "",
            placeholder: "Insert subcomponent serial number",
          },
        },
      ],
      noItemsMessage: (
        <Typography>
          Subcomponents list is empty. Click on add component button to fill
          data related to item subcomponents.
        </Typography>
      ),
    },
  },
];

const itemSubcomponentsForm = (selected) => {
  return {
    fields: itemSubcomponentFormFields,
    validate: itemSubcomponentsFormValidate,
    displayLabel: "Subcomponents",
    name: "itemSubcomponents",
    initialValues: {
      subcomponents: selected ? selected.subcomponents : [],
    },
  };
};

export default itemSubcomponentsForm;
