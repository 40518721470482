import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Switch } from "@material-ui/core";

import { saveContainer } from "store/container/actions";

import { SUCCESS_STATUS } from "components/MessageService/constants";

const ContainerIsFullSwitch = ({ item }) => {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isFull, setIsFull] = useState(item.isFull || false);
  const [isLoading, setIsLoading] = useState(false);

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  useEffect(() => {
    if (messagePayload) {
      toggleSwitch(messagePayload.type === SUCCESS_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload]);

  const handleActiveStatus = () => {
    setSelectedItem(item._id);
    setIsLoading(true);

    dispatch(saveContainer({ isFull: !isFull }, item._id));
  };

  const toggleSwitch = (success) => {
    if (selectedItem === item._id) {
      setIsLoading(false);
      setSelectedItem(null);

      if (success) {
        setIsFull((isFull) => !isFull);
      }
    }
  };

  return (
    <Switch
      checked={isFull}
      onChange={handleActiveStatus}
      disabled={isLoading}
    />
  );
};

ContainerIsFullSwitch.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ContainerIsFullSwitch;
