import React from "react";

import { Grid } from "@material-ui/core";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import CategoriesSearchBar from "screens/Categories/components/CategoriesSearchBar";
import AuthWrapper from "components/AuthWrapper";
import CategoryCreateButton from "screens/Categories/components/CategoryCreateButton";
import CategoryListingDisplaySelector from "screens/Categories/components/CategoryListingDisplaySelector";

const catogoriesHeaderComponents = [
  <Grid item xs={3} md={3} lg={6}>
    <LayoutHeaderTitle title={"Categories"} />
  </Grid>,
  <Grid item xs={6} md={6} lg={4}>
    <CategoriesSearchBar />
  </Grid>,
  <AuthWrapper permission={"CATEGORIES.CATEGORIES_CREATE"}>
    <Grid item xs={3} md={3} lg={2}>
      <CategoryCreateButton />
    </Grid>
  </AuthWrapper>,
  <Grid item xs={12}>
    <CategoryListingDisplaySelector/>
  </Grid>
];

export default catogoriesHeaderComponents;
