import React from "react";
import PropTypes from "prop-types";

import { Select } from "antd";
import Icon from "components/Icon";

import "./styles.scss";

const { Option } = Select;

/**
 * Autocomplete reusable component for different implementations
 * (Beta testing)
 *
 * @param onSearch
 * @param onSelect
 * @param optionLabelSelector
 * @param optionValueSelector
 * @param options
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AutocompleteWrapper = ({
  onSearch,
  onSelect,
  options,
  optionLabelSelector,
  optionValueSelector,
  ...props
}) => {
  return (
    <Select
      onChange={onSelect}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      clearIcon={<Icon name={"cancel"} fontSize={20} color={"black"} />}
      suffixIcon={
        <Icon
          name={"dropdown-arrow"}
          type={"solid"}
          fontSize={16}
          color={"rgba(0, 0, 0, 0.54)"}
        />
      }
      className={"autocomplete-wrapper"}
      {...props}
    >
      {options.map((option, index) => (
        <Option
          key={optionValueSelector ? option[optionValueSelector] : index}
          value={
            optionValueSelector
              ? option[optionValueSelector]
              : JSON.stringify(option)
          }
        >
          {option[optionLabelSelector]}
        </Option>
      ))}
    </Select>
  );
};

AutocompleteWrapper.propTypes = {
  optionLabelSelector: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  optionValueSelector: PropTypes.string,
  options: PropTypes.any,
};

export default AutocompleteWrapper;
