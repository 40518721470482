import React from 'react';
import PropTypes from 'prop-types';

import ItemDetailTextHeader from "screens/InventoryItemDetail/components/ItemDetailTextHeader";
import PricingDetail from "screens/InventoryItemDetail/components/PricingDetail";
import CardContainer from "components/CardContainer";

const PricingList = ({selected}) => {
  return (
    <>
      <div className={"category-detail-section--main"}>
        <ItemDetailTextHeader text={"Pricing"} large />
      </div>
      <div
        className={
          "category-detail-section category-detail-section--currency-main"
        }
      >
        <PricingDetail
          item={selected}
          priceName={"Cost"}
          pricingKey={"cost"}
        />
      </div>
      <div
        className={
          "category-detail-section category-detail-section--currency"
        }
      >
        <PricingDetail
          item={selected.princingOne}
          priceName={"Price 1"}
          pricingKey={"value"}
          withTooltip
        />
      </div>
      <div
        className={
          "category-detail-section category-detail-section--currency"
        }
      >
        <PricingDetail
          item={selected.princingTwo}
          priceName={"Price 2"}
          pricingKey={"value"}
          withTooltip
        />
      </div>
      <div
        className={
          "category-detail-section category-detail-section--currency"
        }
      >
        <PricingDetail
          item={selected.princingThree}
          priceName={"Price 3"}
          pricingKey={"value"}
          withTooltip
        />
      </div>
      <div
        className={
          "category-detail-section--currency category-detail-section--currency-main"
        }
      >
        <CardContainer className={'category-detail-card'}>
          <PricingDetail
            item={selected}
            priceName={"Final price"}
            pricingKey={"finalPrice"}
          />
        </CardContainer>
      </div>
    </>
  );
};

PricingList.propTypes = {
  selected: PropTypes.object.isRequired
};

export default PricingList;