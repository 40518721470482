import React, { useState } from "react";

import {
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  Grid,
} from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";

const FormCheckboxes = ({ field, form, label, options, onChangeCheckbox }) => {
  const [selectedOptions, setSelectedOptions] = useState(field.value);

  const handleCheckboxChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
    form.setFieldValue(field.name, {
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });

    if (onChangeCheckbox)
      onChangeCheckbox({ [event.target.name]: event.target.checked });
  };

  const optionValue = (optionKey) => {
    return !!field.value[optionKey] && field.value[optionKey]
  }

  return (
    <FormFieldWrapper name={field.name}>
      <Grid container>
        <Grid item xs={4} style={{ marginTop: 12 }}>
          <FormLabel htmlFor={field.name} style={{ color: "black" }}>
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={8}>
          <FormGroup onChange={handleCheckboxChange}>
            {options.map((option) => (
              <FormControlLabel
                key={option.key}
                name={option.key}
                control={
                  <Checkbox
                    color={"secondary"}
                    value={optionValue(option.key)}
                    checked={!!optionValue(option.key)}
                  />
                }
                label={option.label}
                style={{ color: "#666" }}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </FormFieldWrapper>
  );
};

FormCheckboxes.propTypes = {};

export default FormCheckboxes;
