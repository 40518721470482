import React  from "react";
import PropTypes from "prop-types";

import FormInput from "components/FormInput";
import FormFileInput from "components/FormFileInput";
import FormInDialog from "components/FormInDialog";

import categoryFormValidate from "screens/Categories/components/validate";

const CategoryModal = ({
  OpenerComponent,
  title,
  submitActionText,
  cancelActionText,
  ...formProps
}) => {
  const categoryModalFields = [
    {
      name: "name",
      component: FormInput,
      props: {
        label: "Category name",
        required: true,
        fullWidth: true,
        variant: "outlined",
      },
    },
    {
      name: "pictures",
      component: FormFileInput,
      props: {
        placeholder: "",
        label: "Featured image",
        externalLabel: true,
        accept: "image/*",
      },
    },
  ];

  return (
    <FormInDialog
      validate={categoryFormValidate}
      OpenerComponent={(props) => <OpenerComponent {...props} />}
      title={title}
      submitActionText={submitActionText}
      cancelActionText={cancelActionText}
      formFields={categoryModalFields}
      {...formProps}
    />
  );
};

CategoryModal.propTypes = {
  OpenerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string.isRequired,
  submitActionText: PropTypes.string.isRequired,
  cancelActionText: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CategoryModal;
