import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { RiseOutlined } from "@ant-design/icons";

ChartJS.register(ArcElement, Tooltip, Legend);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

export default function PieChart({ itemForStatus }) {
  const sumaItems = itemForStatus
    ?.map((item) => item.qty)
    .reduce((prev, curr) => prev + curr, 0);

  const sumaItemsFormat = new Intl.NumberFormat(["ban", "id"]).format(
    sumaItems
  );
  const data = {
    labels: itemForStatus.map((res) => res.name),
    datasets: [
      {
        label: "# of Votes",
        data: itemForStatus.map((res) => res.qty),
        backgroundColor: [
          "red",
          "blue",
          "orange",
          "green",
          "yellow",
          "pink",
          "vaiolet",
          "brown",
          "aqua",
          "black",
          "fuchsia",
          "gray",
          "green",
          "lime",
          "maroon",
          "navy",
          "olive",
          "purple",
          "silver",
          "teal",
        ],
        borderColor: [
          "red",
          "blue",
          "orange",
          "green",
          "yellow",
          "pink",
          "vaiolet",
          "brown",
          "aqua",
          "black",
          "fuchsia",
          "gray",
          "green",
          "lime",
          "maroon",
          "navy",
          "olive",
          "purple",
          "silver",
          "teal",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        border: "1px solid #C3C3C3",
      }}
    >
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #C3C3C3",
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
          padding: 10,
        }}
      >
        <RiseOutlined style={{ color: "green", fontSize: 30 }} />
        <span style={{ fontWeight: "bold", fontSize: 20, paddingLeft: 10 }}>
          ITEMS BY STATUS
        </span>
      </div>
      <Pie data={data} options={options} style={{ padding: 20 }} />
      <div
        style={{
          width: "100%",
          borderTop: "1px solid #C3C3C3",
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: 20, paddingLeft: 10 }}>
          TOTAL ITEM´S: {sumaItemsFormat.replace(/\./g, ",")}
        </span>
      </div>
    </div>
  );
}
