import React, { useState } from "react";
import { useSelector } from "react-redux";

import AccordionLayout from "components/AccordionLayout";
import LocationRow from "screens/WarehouseDetail/components/warehouse-locations/LocationRow";

import RowActions from "screens/WarehouseDetail/components/warehouse-locations/RowActions";
import { CircularProgress } from "@material-ui/core";

const LocationsList = (props) => {
  const { warehouseStructure, structureLoading } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );
  const [expanded, setExpanded] = useState("1");

  const toggleExpanded = (rowToExpand) => {
    setExpanded(expanded === rowToExpand ? false : rowToExpand);
  };

  return structureLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={"secondary"} />
    </div>
  ) : (
    <div>
      {warehouseStructure.map((location) => (
        <AccordionLayout
          keyIndex={location._id}
          title={`Row ${location.name}`}
          key={location._id}
          summaryActions={<RowActions row={location} />}
          expanded={expanded === location._id}
          onChange={() => toggleExpanded(location._id)}
          accordionDetailsClassnames={"location-row-content"}
        >
          <LocationRow locationConfig={location} />
        </AccordionLayout>
      ))}
    </div>
  );
};

LocationsList.propTypes = {};

export default LocationsList;
