import {validateUrl} from "lib/form/validation";

const itemListingsFormValidate = (values) => {
  let errors = {};

  if (values.hasWebsiteListing) {
    if (!values.websiteListingDate) {
      errors = {
        ...errors,
        websiteListingDate: "Website listing date is required."
      }
    }

    if (!values.websiteURL) {
      errors = {
        ...errors,
        websiteURL: "Website listing URL is required."
      }
    } else if (!validateUrl(values.websiteURL)) {
      errors = {
        ...errors,
        websiteURL: "Website listing URL is not valid."
      }
    }
  }

  if (values.hasEbayListing) {
    if (!values.eBayListingDate) {
      errors = {
        ...errors,
        eBayListingDate: "eBay listing date is required."
      }
    }

    if (!values.eBayURL) {
      errors = {
        ...errors,
        eBayURL: "eBay listing URL is required."
      }
    } else if (!validateUrl(values.eBayURL)) {
      errors = {
        ...errors,
        eBayURL: "eBay listing url is not valid."
      }
    }
  }

  if (values.hasBeenListedByOutsideRep) {
    if (!values.outsideRepListingDate) {
      errors = {
        ...errors,
        outsideRepListingDate: "Outside representative listing date is required."
      }
    }

    if (!values.outsideRepURL) {
      errors = {
        ...errors,
        outsideRepURL: "Outside representative listing URL is required."
      }
    } else if (!validateUrl(values.outsideRepURL)) {
      errors = {
        ...errors,
        outsideRepURL: "Outside representative listing url is not valid."
      }
    }
  }

  return errors
}

export default itemListingsFormValidate;