import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListFilter from "components/ListFilter";
import { fetchItemConditions } from "store/item/actions";

const ConditionsFilter = () => {
  const dispatch = useDispatch();

  const { conditionsList } = useSelector(({ itemReducer }) => itemReducer);

  useEffect(() => {
    dispatch(fetchItemConditions());
  }, [dispatch]);

  return (
    <ListFilter
      options={conditionsList}
      filterKey={"condition"}
      selectedLabel={"All conditions"}
      unselectedLabel={"Condition"}
    />
  );
};

export default ConditionsFilter;
