import React from "react";
import { Grid } from "@material-ui/core";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
//import LinkContainer from "components/LinkContainer";

//import Icon from "components/Icon";
import AuthWrapper from "components/AuthWrapper";

const HeaderComponents = [
  <Grid item xs={3} md={3} lg={6}>
    <LayoutHeaderTitle title={"Dashboard"} />
  </Grid>,
  <AuthWrapper permission={"WILDCARD"}>
    <Grid item xs={6} md={6} lg={6}>
      <div style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}>
        {/* <LinkContainer
          to="/warehouses/create"
          Component={(props) => (
            // <Button
            //   color="secondary"
            //   variant="contained"
            //   startIcon={<Icon name={"plus"} />}
            //   {...props}
            // >
            //   Touch Me
            // </Button>
          )}
        /> */}
      </div>
    </Grid>
  </AuthWrapper>,
];

export default HeaderComponents;
