import React from "react";

import {Typography, Grid} from "@material-ui/core";

import ScreenLayout from "components/ScreenLayout";
import GoogleLoginButton from "components/GoogleLoginButton";

import './styles.scss';

const Login = () => {
  return (
    <ScreenLayout type={"clean"}>
      <Grid container direction={'column'} spacing={2} alignContent="center" alignItems="center">
        <Grid item>
          <Typography component={"h1"} variant={"h6"}>
            AFAB
          </Typography>
        </Grid>
        <Grid item>
          <Typography component={'h2'} variant={'h4'}>
            Welcome back!
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Please login with your Google Account to continue.
          </Typography>
        </Grid>
        <Grid item>
          <GoogleLoginButton className={'login__google-button'} />
        </Grid>
      </Grid>
    </ScreenLayout>
  );
};

export default Login;
