import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import rootReducer from "store/reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['authReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storeConfig = () => {
  let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );

  let persistor = persistStore(store);

  return { store, persistor };
};

export default storeConfig;