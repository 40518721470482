import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { baseUrl, headers } from "../../../lib/api/constants";

import ItemDetailList from "components/ItemDetailList";
import { CircularProgress } from "@material-ui/core";

const SimpleItemsList = ({ itemId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContainersByItem = async () => {
    setLoading(true);
    const response = await axios
      .get(`${baseUrl}/containers/${itemId}`, {
        method: "GET",
        headers,
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    if (response?.data) {
      setLoading(false);
      setItems(response.data?.data?.items);
    }
  };

  useEffect(() => {
    getContainersByItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alingItems: "center",
          paddingTop: "50px",
        }}
      >
        <CircularProgress color={"secondary"} />
      </div>
    );
  }

  if (items.length > 0) {
    return <ItemDetailList list={items} />;
  } else {
    return null;
  }
};

SimpleItemsList.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default SimpleItemsList;
