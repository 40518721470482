import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import {
  CardActionArea,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

import CategoryCardImage from "screens/Categories/components/CategoryCardImage";
import CategoryCardActions from "screens/Categories/components/CategoryCardActions";
import AuthWrapper from "components/AuthWrapper";

const CategoryCard = ({ item }) => {
  const history = useHistory();

  const navigateToCategoryItems = () => {
    history.push(`/inventory/categories/${item._id}/items`);
  };

  return (
    <Card>
      <CardActionArea>
        <CategoryCardImage
          onClick={navigateToCategoryItems}
          name={item.name ? item.name : ""}
          picture={item.pictures.length ? item.pictures[0] : null}
        />
      </CardActionArea>
      <CardContent>
        <div className={"category-card__content"}>
          <Typography style={{ flex: 3 }} onClick={navigateToCategoryItems}>
            {item.name ? item.name : ""} ({item.items.length})
          </Typography>
          <AuthWrapper
            permission={[
              "CATEGORIES.CATEGORIES_UPDATE",
              "CATEGORIES.CATEGORIES_DELETE",
            ]}
          >
            <CategoryCardActions category={item} />
          </AuthWrapper>
        </div>
      </CardContent>
    </Card>
  );
};

CategoryCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CategoryCard;
