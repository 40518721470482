import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import { FormLabel, Grid, Typography, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import Icon from "components/Icon";

import "./styles.scss";

const FormArrayBasic = ({
  unityName,
  fields,
  name,
  initialValues,
  formArrayInitialValues,
  noItemsMessage,
  ...props
}) => {
  const { form, remove, push } = props;

  useEffect(() => {
    if (!form.values[name]) {
      form.setFieldValue(name, initialValues || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFieldsGroup = (index) => {
    remove(index);
  };

  const addItemFieldsGroup = () => {
    push(formArrayInitialValues);
  };

  return (
    <div>
      {(!form.values[name] || form.values?.[name]?.length === 0) &&
        noItemsMessage}
      {form.values?.[name]?.map((value, index) =>
        fields.map(({ name: fieldName, component, props }, fieldIndex) => {
          return (
            <Grid
              container
              key={fieldName}
              className={"form-array-field-container"}
            >
              <Grid xs={4} item>
                {fieldIndex === 0 && (
                  <>
                    <FormLabel htmlFor={name} className={"form-array-label"}>
                      {`${unityName} #${index + 1}`}
                    </FormLabel>
                    <Grid
                      container
                      className={"field-array-remove-group-button"}
                      onClick={() => removeFieldsGroup(index)}
                    >
                      <Grid item xs={1}>
                        <Icon
                          name="cancel-circle"
                          style={{ color: "red", fontSize: 18 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography style={{ color: "#666", fontSize: 12 }}>
                          Delete
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid xs={8} item>
                <Field
                  name={`${name}[${index}].${fieldName}`}
                  component={component}
                  {...props}
                  error={!!form.errors[`${name}[${index}].${fieldName}`]}
                  helperText={
                    !!form.errors[`${name}[${index}].${fieldName}`] &&
                    form.errors[`${name}[${index}].${fieldName}`]
                  }
                />
              </Grid>
            </Grid>
          );
        })
      )}
      <Grid container className={"actions-container"}>
        <Grid item xs={4}>
          <div />
        </Grid>
        <Grid item xs={5} className={"add-field-group-button-container"}>
          <Button
            onClick={addItemFieldsGroup}
            variant={"contained"}
            startIcon={<AddIcon />}
            className={"add-item-to-group-button"}
          >{`Add ${unityName}`}</Button>
        </Grid>
      </Grid>
    </div>
  );
};

FormArrayBasic.propTypes = {
  unityName: PropTypes.string.isRequired,
  noItemsMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      component: PropTypes.func,
      props: PropTypes.object,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  formArrayInitialValues: PropTypes.object.isRequired,
  initialValues: PropTypes.array,
};

export default FormArrayBasic;
