import React from "react";
import PropTypes from "prop-types";

import { Dialog } from "@material-ui/core";

const ModalWithOpener = ({ OpenerComponent, children, ...props }) => {
  return (
    <>
      {OpenerComponent}
      {props.open && (
        <Dialog {...props} aria-labelledby="modal-with-opener">
          {children}
        </Dialog>
      )}
    </>
  );
};

ModalWithOpener.propTypes = {
  OpenerComponent: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.any]).isRequired,
};

export default ModalWithOpener;
