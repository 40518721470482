import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Typography, IconButton, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Icon from "components/Icon";
import DrawerContainer from "components/DrawerContainer";
import SimpleItemsList from "screens/Containers/components/SimpleItemsList";

import { unsetSelectedContainer } from "store/container/actions";

const useStyles = makeStyles({
  "container-items-list-title": {
    fontWeight: "bold",
    fontSize: 16,
  },
  "container-items-list--empty": {
    padding: "0 20px",
    width: "100%",
  },
  "container-items-list-drawer": {
    width: 280,
  },
  "list-loading-container": {
    textAlign: "center",
  },
});

const ContainerItemsListDrawer = ({ item }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { loading } = useSelector(({ helperReducer }) => helperReducer);
  const { selected: selectedContainer } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  const containerHeaderConfig = {
    title: (
      <span>
        Items in{" "}
        <span className={styles["container-items-list-title"]}>
          {item?.type?.name} #{item?.name}
        </span>
      </span>
    ),
  };

  const unsetContainer = () => {
    dispatch(unsetSelectedContainer());
  };

  return (
    <DrawerContainer
      item={item}
      headerConfig={containerHeaderConfig}
      actionAfterClosed={unsetContainer}
      OpenerComponent={(props) => (
        <IconButton {...props}>
          <Icon name={"eye"} color={"black"} />
        </IconButton>
      )}
      openFrom={"right"}
    >
      <div className={styles["container-items-list-drawer"]}>
        {loading && !selectedContainer && (
          <div className={styles["list-loading-container"]}>
            <CircularProgress color={"secondary"} size={25} />
          </div>
        )}
        {item?.items?.length ? (
          <SimpleItemsList itemId={item._id} />
        ) : (
          <Typography className={styles["container-items-list--empty"]}>
            This container is empty.
          </Typography>
        )}
      </div>
    </DrawerContainer>
  );
};

ContainerItemsListDrawer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ContainerItemsListDrawer;
