import React from 'react';
import PropTypes from 'prop-types';

import {Grid} from "@material-ui/core";

import ItemDetailTextHeader from "screens/InventoryItemDetail/components/ItemDetailTextHeader";
import ItemListing from "screens/InventoryItemDetail/components/ItemListing";

const ItemListingsList = ({selected}) => {
  return (
    <>
      <div>
        <ItemDetailTextHeader text={"Listings"} medium />
      </div>
      <Grid container className={"category-detail-section"}>
        <Grid item xs={4}>
          <ItemListing
            listing={selected.listedOnWebsite}
            notListedMessage={"Not listed on website"}
            listedMessage={"Listed on website"}
          />
        </Grid>
        <Grid item xs={4}>
          <ItemListing
            listing={selected.listedOnEbay}
            notListedMessage={"Not listed on eBay"}
            listedMessage={"Listed on eBay"}
          />
        </Grid>
        <Grid item xs={4}>
          <ItemListing
            listing={selected.listedOnOutsideRepSite}
            notListedMessage={"Not listed on Outside rep website"}
            listedMessage={"Listed on Outside rep website"}
          />
        </Grid>
      </Grid>
    </>
  );
};

ItemListingsList.propTypes = {
  selected: PropTypes.object.isRequired
};

export default ItemListingsList;