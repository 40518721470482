import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Typography} from "@material-ui/core";

import './ItemQrCodeDescription.scss'

const ItemQrCodeDescription = ({item}) => {
  return (
    <Grid container direction={"column"} alignItems={"center"} className={'item-qr-code-description'}>
      <Grid item>
        <Typography className={'item-qr-code-description-text item-qr-code-description-text--name'}>
          {item.name} - {item.model}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={'item-qr-code-description-text'}>SKU: {item.number}</Typography>
      </Grid>
    </Grid>
  );
};

ItemQrCodeDescription.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemQrCodeDescription;