import React from "react";
import {
  ShoppingCartOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import "./styles.scss";

export default function ReadyForSaleChart({ dataItemsReadyForSale }) {
  const itemLength = dataItemsReadyForSale.length;
  // const priceOne = dataItemsReadyForSale
  //   ?.map((item) => item.princingOne?.value)
  //   .reduce((prev, curr) => prev + curr, 0);

  // const priceTwo = dataItemsReadyForSale
  //   ?.map((item) => item.princingTwo?.value)
  //   .reduce((prev, curr) => prev + curr, 0);

  // const priceThree = dataItemsReadyForSale
  //   ?.map((item) => item.princingThree?.value)
  //   .reduce((prev, curr) => prev + curr, 0);

  const finalPrice = dataItemsReadyForSale
    ?.map((item) => item.finalPrice)
    .reduce((prev, curr) => prev + curr, 0);

  // const number = finalPrice;

  const finalPriceFormat = new Intl.NumberFormat(["ban", "id"]).format(
    finalPrice
  );

  const quantityItemsFormat = new Intl.NumberFormat(["ban", "id"]).format(
    itemLength
  );

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        border: "1px solid #C3C3C3",
      }}
    >
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #C3C3C3",
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
          padding: 10,
        }}
      >
        <ShoppingCartOutlined style={{ color: "green", fontSize: 30 }} />
        <span style={{ fontWeight: "bold", fontSize: 20, paddingLeft: 10 }}>
          READY FOR SALE
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          padding: "10px",
          alignItems: "center",
          paddingTop: "15px",
          paddingBottom: "25px",
        }}
      >
        <DollarCircleOutlined className="finalPriceIcon" />
        <span className="finalPriceNumber">
          {" "}
          {finalPriceFormat.replace(/\./g, ",")}
        </span>
      </div>
      <span
        style={{
          fontWeight: "bold",
          fontSize: 15,
          paddingLeft: 10,
          paddingBottom: "10px",
          color: "blue",
        }}
      >
        <LineChartOutlined
          style={{ color: "blue", fontSize: 30, paddingRight: "5px" }}
        />{" "}
        Quantity : {quantityItemsFormat.replace(/\./g, ",") || 0}
      </span>
    </div>
  );
}
