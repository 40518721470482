import React from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

import "./styles.scss";

const SearchBar = ({
  onChange,
  searchKeyAccessor,
  timeout = 0,
  searchValueLengthToSearch = 1,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();

  const onChangeSearchValue = (event) => {
    const searchQuery = queryString.parse(location.search);

    const searchQueryParams = new URLSearchParams({
      ...searchQuery,
      page: "1",
    });

    searchQueryParams.delete(searchKeyAccessor);

    if (event.target.value) {
      searchQueryParams.append(searchKeyAccessor, event.target.value);
    }

    const timeoutInstance = setTimeout(function () {
      history.push({
        path: location.path,
        search: new URLSearchParams(searchQueryParams).toString(),
      });
    }, timeout);

    return () => clearTimeout(timeoutInstance);
  };

  const onChangeValueHandler = (event) => {
    if (
      event.target.value.length >= searchValueLengthToSearch ||
      event.target.value.length === 0
    ) {
      onChangeSearchValue(event);
    }
  };

  return (
    <TextField
      {...props}
      variant={"outlined"}
      onChange={onChangeValueHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <SearchIcon className={"search-bar-icon"} />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchBar.propTypes = {
  searchKeyAccessor: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default SearchBar;
