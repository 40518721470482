import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import classnames from "classnames";

import { Button, TextField } from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";
import ChangelogList from "components/ChangelogList";

import * as arrayOperations from "lib/array/operations";

import "./styles.scss";

const FormTextareaOnDemand = ({
  field,
  onClickButtonText,
  initialValue,
  label,
  placeholder,
  notesField,
  ListChangelogComponent = ChangelogList,
  ...props
}) => {
  const form = useFormikContext();

  const [text, setText] = useState("");
  const [textList, setListText] = useState([
    ...(field.value || []),
    ...(form.values[notesField] || []),
  ]);

  const onChangeText = (event) => {
    if (initialValue.length === 0 && event.target.value === "") {
      setText("");
    }

    if (event.target.value.length > initialValue.length) {
      setText(event.target.value);
    }
  };

  const onPresaveText = () => {
    form.setFieldValue(field.name, "");
    setText("");

    setListText([
      { comment: text, key: (textList?.length || 0) + 1 },
      ...textList,
    ]);

    form.setFieldValue(field.name, [
      { comment: text, key: (form.values[field.name]?.length || 0) + 1 },
      ...form.values[field.name],
    ]);
  };

  const deleteComment = (comment) => {
    const nonDeletedListTexts = arrayOperations.removeByValue(
      textList,
      comment
    );

    setListText(nonDeletedListTexts);

    form.setFieldValue(
      field.name,
      nonDeletedListTexts.filter(comment => !!comment.key)
    );
  };

  return (
    <FormFieldWrapper name={field.name} label={label}>
      <>
        <div className={'form-textarea-field-container'}>
          <TextField
            color={"secondary"}
            label={placeholder}
            multiline={true}
            rows={3}
            onChange={onChangeText}
            value={text}
            fullWidth
            variant={"outlined"}
            placeholder={placeholder}
          />
        </div>
        <Button
          onClick={onPresaveText}
          variant={"contained"}
          className={classnames({
            "form-area-on-demand-button": true,
            "form-area-on-demand-button--disabled": !text,
          })}
          disabled={!text}
        >
          {onClickButtonText}
        </Button>
        <div className={"form-textarea-on-demand-notes-list"}>
          {
            <ListChangelogComponent
              list={textList}
              deleteAction={deleteComment}
              className={"category-changelog-list"}
            />
          }
        </div>
      </>
    </FormFieldWrapper>
  );
};

FormTextareaOnDemand.propTypes = {
  field: PropTypes.object.isRequired,
};

export default FormTextareaOnDemand;
