import React from "react";
import PropTypes from "prop-types";

import { Button, DialogActions } from "@material-ui/core";

/**
 * Component to display modal form actions
 *
 * @param closeModal
 * @param closeModalText
 * @param onSubmitActionText
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ModalActions = ({
  closeModal,
  closeModalText,
  onSubmitActionText,
  ...props
}) => {
  return (
    <DialogActions>
      <Button color="secondary" onClick={closeModal}>
        {closeModalText}
      </Button>
      <Button color="secondary" {...props}>
        {onSubmitActionText}
      </Button>
    </DialogActions>
  );
};

ModalActions.propTypes = {
  onClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeModalText: PropTypes.string.isRequired,
  onSubmitActionText: PropTypes.string.isRequired,
};

export default ModalActions;
