import warehouseRoutes from "routes/warehouse";
import containersRoutes from "routes/containers";
import inventoryRoutes from "routes/inventory";
import itemsRoutes from "routes/items";
import userRoutes from "routes/users";
import dasboardRoutes from "routes/dasboard";

const routes = [
  ...warehouseRoutes,
  ...containersRoutes,
  ...inventoryRoutes,
  ...itemsRoutes,
  ...userRoutes,
  ...dasboardRoutes,
];

export default routes;
