import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import { FormLabel, Grid, FormHelperText } from "@material-ui/core";

import "./styles.scss";

// TODO: we could include here "externalLabel" prop option to handle both type of form fields and avoid extension for every form field that uses form field wrapper i.e.: FormInput and FormFileInput components' implementations
const FormFieldWrapper = ({
  name,
  label,
  required,
  children,
  customError = true,
  errorMessage = "",
}) => {
  const form = useFormikContext();

  const { errors, touched } = form;

  return (
    <div className={"form-field-wrapper"}>
      {label && (
        <Grid xs={4} item>
          <FormLabel htmlFor={name} className={"form-field-wrapper__label"}>
            {label}{" "}
            {required && (
              <sup className={"form-field-wrapper__label-required"}>(*)</sup>
            )}
          </FormLabel>
        </Grid>
      )}
      <Grid item xs={label ? 8 : 12}>
        <>
          {children}
          {customError && touched[name] && !!errors[name] && (
            <FormHelperText className={"form-field-wrapper__error-text"}>
              {errorMessage || errors[name]}
            </FormHelperText>
          )}
        </>
      </Grid>
    </div>
  );
};

FormFieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  children: PropTypes.object,
  required: PropTypes.bool,
};

export default FormFieldWrapper;
