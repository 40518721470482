import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import LinkContainer from "components/LinkContainer";

const LinkToItemHistory = ({ item }) => {
  return (
    <LinkContainer
      to={`/inventory/items/history/${item._id}`}
      Component={(props) => (
        <IconButton {...props}>
          <Icon name={"history"} color={"black"} fontSize={18} />
        </IconButton>
      )}
    />
  );
};

LinkToItemHistory.propTypes = {
  item: PropTypes.object.isRequired,
};

export default LinkToItemHistory;
