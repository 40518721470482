const containerLocationValidation = (values) => {
  let errors = {};

  if (!values.warehouse) {
    errors = {
      ...errors,
      warehouse: "A warehouse must be selected",
    };
  } else if (!values.row) {
    errors = {
      ...errors,
      row: "You must select a warehouse's row or open space",
    };
  } else if (values.row.type === "row") {
    if (!values.rack) {
      errors = {
        ...errors,
        rack: "A rack must be selected",
      };
    }

    if (!values.shelf) {
      errors = {
        ...errors,
        shelf: "A shelf must be selected",
      };
    }
  }
  return errors;
};

export default containerLocationValidation;
