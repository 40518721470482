import React from "react";
import axios from "axios";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";

import { useDispatch } from "react-redux";
import { login } from "store/auth/actions";

import './styles.scss';
import {ReactComponent as GoogleIcon} from "assets/icons/icons8-google.svg"
import API_RESOURCES from "lib/api/resources";

const GoogleLoginButton = (props) => {
  const dispatch = useDispatch();

  const onGoogleLogin = useGoogleLogin({
      onSuccess: (codeResponse) => {
        axios
          .get(API_RESOURCES.GET_USER_DATA_BY_GOOGLE, {
            headers: { Authorization: `Bearer ${codeResponse.access_token}` },
          })
          .then(res => {
            dispatch(
              login({
                token: codeResponse.access_token,
                email: res.data.email,
              })
            );
          })
          .catch(err => {
            console.error(err);
            googleLogout();
          })
      },
      onError: (error) => {
        console.error(error);
        googleLogout();
      }
  });

  return (
    <>
      <button onClick={(event) => onGoogleLogin()} className="custom-google-sign-in">
        <GoogleIcon />
        Sign in with Google
      </button>
    </>
  );
};

GoogleLoginButton.propTypes = {};

export default GoogleLoginButton;
