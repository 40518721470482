import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Button } from "@material-ui/core";

import Icon from "components/Icon";

import FormInDialog from "../../../../components/FormInDialog";
import FormInput from "../../../../components/FormInput";

import { addRack } from "store/warehouse/actions";

import rackFormValidate from "./rackFormValidate";

const AddRackButton = ({ row }) => {
  const dispatch = useDispatch();

  const addRackToRow = (values) => {
    dispatch(addRack({ row: row._id, name: values.name }));
  };

  const resetModalState = () => {};

  const addRackInitialValues = {
    name: "",
  };

  const addContainerFields = [
    {
      name: "name",
      component: FormInput,
      props: {
        label: "Rack name",
        placeholder: "Rack name",
        variant: "outlined",
      },
    },
  ];

  return (
    <FormInDialog
      actionOnClose={resetModalState}
      validate={rackFormValidate}
      initialValues={addRackInitialValues}
      formFields={addContainerFields}
      title={"Add new rack"}
      submitActionText={"Save"}
      cancelActionText={"Cancel"}
      onSubmit={addRackToRow}
      shouldCancelPropagation
      OpenerComponent={(props) => (
        <Button
          {...props}
          className={"row-actions-button"}
          color={"secondary"}
          startIcon={<Icon name={"plus"} />}
        >
          Add rack
        </Button>
      )}
    />
  );
};

AddRackButton.propTypes = {
  row: PropTypes.object.isRequired,
};

export default AddRackButton;
