import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { InputLabel } from "@material-ui/core";
import { DatePicker } from "antd";

import Icon from "components/Icon";

import "components/DatePickerWrapper/styles.scss";

const DateRangePickerWrapper = ({
  onChange,
  startDateInitialValue,
  endDateInitialValue,
  label,
  ...props
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(
    startDateInitialValue ? moment(startDateInitialValue) : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    endDateInitialValue ? moment(endDateInitialValue) : null
  );

  const onSelectDate = (date) => {
    if (date) {
      const [startDate, endDate] = date;

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);

      onChange({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });
    } else {
      resetValue();
    }
  };

  const resetValue = () => {
    onChange({ startDate: null, endDate: null });

    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  return (
    <div style={{ position: "relative" }}>
      {label && (
        <InputLabel className={"date-picker-wrapper-label"}>{label}</InputLabel>
      )}
      <DatePicker.RangePicker
        onChange={onSelectDate}
        fullWidth
        defaultValue={[selectedStartDate, selectedEndDate]}
        className={"date-picker-wrapper"}
        suffixIcon={<Icon name={"calendar"} style={{ color: "black" }} />}
        {...props}
      />
    </div>
  );
};

DateRangePickerWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  startDateInitialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  endDateInitialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default DateRangePickerWrapper;
