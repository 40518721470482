import {
  SET_ITEM_CONDITIONS_LIST,
  SET_ITEM_STATUS_LIST,
  SET_ITEMS_LIST,
  SET_SELECTED_ITEM,
  UNSET_SELECTED_ITEM,
  SET_SELECTED_ITEMS_LIST,
  UNSET_SELECTED_ITEMS_LIST,
  SET_ITEM_HISTORY_LIST,
  SET_ITEM_LOADING,
  UNSET_ITEM_LOADING,
  SET_IMAGE_UPLOADING,
  UNSET_IMAGE_UPLOADING,
} from "store/item/types";

const itemReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ITEM_CONDITIONS_LIST:
      return {
        ...state,
        conditionsList: payload.conditionsList,
      };
    case SET_ITEM_STATUS_LIST:
      return {
        ...state,
        statusList: payload.statusList,
      };
    case SET_ITEMS_LIST:
      return {
        ...state,
        list: payload.data.rows,
        count: payload.data.total,
        page: payload.data.currentPage,
      };
    case SET_SELECTED_ITEM:
      return {
        ...state,
        selected: payload.selected,
      };
    case UNSET_SELECTED_ITEM:
      return {
        ...state,
        selected: null,
      };
    case SET_SELECTED_ITEMS_LIST:
      return {
        ...state,
        selectedItems: payload.selectedItems,
      };
    case UNSET_SELECTED_ITEMS_LIST:
      return {
        ...state,
        selectedItems: [],
      };
    case SET_ITEM_HISTORY_LIST:
      return {
        ...state,
        itemHistory: {
          ...state.itemHistory,
          list: payload.listPayload.rows,
          count: payload.listPayload.total,
          page: payload.listPayload.currentPage,
        },
      };
    case SET_ITEM_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case UNSET_ITEM_LOADING:
      return {
        ...state,
        isFetching: false,
      };
    case SET_IMAGE_UPLOADING:
      return {
        ...state,
        isUploaded: true,
      };
    case UNSET_IMAGE_UPLOADING:
      return {
        ...state,
        isUploaded: false
      };
    default:
      return state;
  }
};

const initialState = {
  conditionsList: [],
  statusList: [],
  list: [],
  count: 0,
  page: 1,
  selected: null,
  selectedItems: [],
  isFetching: false,
  itemHistory: {
    list: [],
    count: 0,
    page: 1,
  },
  isUploaded: false,
};

export default itemReducer;
