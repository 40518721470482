const containerFormValidation = (values) => {
  let errors = {};

  if (!values.type) {
    errors = {
      ...errors,
      type: "Container's type must be selected",
    };
  }

  if (!values.name) {
    errors = {
      ...errors,
      name: "Next number or letter suggestion is loading.",
    };
  } 

  return errors;
};

export default containerFormValidation;