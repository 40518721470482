import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";

import CardContainer from "components/CardContainer";
import AuthWrapper from "components/AuthWrapper";
import OpenSpaceCardActions from "screens/WarehouseDetail/components/open-spaces/OpenSpaceCardActions";

import "./OpenSpaceCard.scss";

const OpenSpaceCard = ({ item }) => {
  return (
    <CardContainer className={"open-space-card"}>
      <>
        <div className={"open-space-card__item"}>
          <div className={"open-space-card__actions"}>
            <Typography className={"open-space-number"}>
              Open space {item.number}
            </Typography>
            <AuthWrapper
              permission={[
                "OPEN_SPACES.OPEN_SPACES_UPDATE",
                "OPEN_SPACES.OPEN_SPACES_READ",
                "OPEN_SPACES.OPEN_SPACES_DELETE",
              ]}
            >
              <OpenSpaceCardActions openSpace={item} />
            </AuthWrapper>
          </div>
          <Typography className={"open-space-name"}>{item.name}</Typography>
        </div>
        <div>
          <Typography className={"open-space-description"}>
            {item.description || "There's no description for this open space."}
          </Typography>
        </div>
      </>
    </CardContainer>
  );
};

OpenSpaceCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default OpenSpaceCard;
