import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { DatePicker } from "antd";
import { InputLabel } from "@material-ui/core";

import Icon from "components/Icon";

import "./styles.scss";

const DatePickerWrapper = ({ onChange, initialValue, label, className = '', ...props }) => {
  const [selectedDate, setSelectedDate] = useState(
    initialValue ? moment(initialValue) : null
  );

  const onSelectDate = (date) => {
    if (date) {
      onChange(date.format("YYYY-MM-DD"));
      setSelectedDate(date);
    } else {
      resetValue();
    }
  };

  const resetValue = () => {
    onChange(null);
    setSelectedDate(null);
  };

  return (
    <div style={{ position: "relative" }}>
      {label && (
        <InputLabel className={"date-picker-wrapper-label"}>{label}</InputLabel>
      )}
      <DatePicker
        onChange={onSelectDate}
        fullWidth
        defaultValue={selectedDate}
        className={`date-picker-wrapper ${className}`}
        suffixIcon={<Icon name={"calendar"} style={{ color: "black" }} />}
        {...props}
      />
    </div>
  );
};

DatePickerWrapper.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DatePickerWrapper;
