import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import { Typography, CardHeader } from "@material-ui/core";

import CardContainer from "components/CardContainer";
import MainWarehouseIndicator from "screens/Warehouses/components/MainWarehouseIndicator";

import "./WarehouseCard.scss";

const WarehouseCard = ({ item }) => {
  const history = useHistory();

  const goToWarehouseDetail = () => {
    history.push(`/warehouses/detail/${item._id}`);
  };

  return (
    <CardContainer
      className={classnames({
        "warehouse-card": true,
        "warehouse-card__is-main": item.isMain,
      })}
      variant="outlined"
      onClick={goToWarehouseDetail}
    >
      <>
        <div className={"warehouse-card__header"}>
          <div className={"warehouse-card__header-title"}>
            <Typography className={"warehouse-card__number"}>
              Warehouse {item.number}
            </Typography>
            <CardHeader
              title={item.name}
              className={"warehouse-card__title"}
            />
          </div>
          <MainWarehouseIndicator
            isMain={item.isMain}
            className={classnames({
              "warehouse-icon__is-main": item.isMain,
              "warehouse-icon__is-not-main": !item.isMain,
            })}
          />
        </div>
        <Typography className="warehouse-card__address-title">
          {item.address}
        </Typography>
      </>
    </CardContainer>
  );
};

WarehouseCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
};

export default WarehouseCard;
