import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";

import "./HeaderActionsList.scss";
import AuthWrapper from "components/AuthWrapper";

function HeaderActionsList({ list }) {
  return (
    <Container>
      <ul className="header-actions-list">
        {list.map((item, index) => (
          <AuthWrapper key={index} permission={item.permission}>
            <li className="header-actions-list__item">
              <Link to={item.path}>
                <Typography>{item.name ? item.name : ""}</Typography>
              </Link>
            </li>
          </AuthWrapper>
        ))}
      </ul>
    </Container>
  );
}

HeaderActionsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default HeaderActionsList;
