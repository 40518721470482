import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormSelect from "components/FormSelect";

import { useSelector } from "react-redux";

const LocationLevelFormSelect = ({
  field,
  form: { values, setFieldValue },
}) => {
  const [selectedLevel, setSelectedLevel] = useState(
    field.value !== "-1" ? field.value : "-1"
  );

  const { levelsList } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );
  const { selected: selectedContainer } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  useEffect(() => {
    if (selectedContainer && values.rack !== "-1" && selectedLevel === "-1") {
      const shelfId = selectedContainer.shelf
        ? selectedContainer.shelf._id
        : "-1";

      setSelectedLevel(shelfId);
      setFieldValue("shelf", shelfId);
    }
  }, [
    selectedContainer,
    values.rack,
    levelsList,
    selectedLevel,
    setFieldValue,
  ]);

  const onSelectLevel = (value) => {
    setSelectedLevel(value);
    setFieldValue("shelf", value);
  };

  if (values.type !== 'shelf' || !values.rack || values.rack === '-1') {
    return null
  }

  return (
    <FormSelect
      label={"Shelf"}
      placeholder={"Select shelf"}
      options={levelsList}
      field={field}
      valueAccessor={"_id"}
      labelAccessor={"name"}
      fullWidth
      disabled={!levelsList.length}
      onChangeExternal={onSelectLevel}
    />
  );
};

LocationLevelFormSelect.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default LocationLevelFormSelect;
