import FormInput from "components/FormInput";
import FormFileInput from "components/FormFileInput";
import FormSelect from "components/FormSelect";
import FormDate from "components/FormDate";
import FormTextareaOnDemand from "components/FormTextareaOnDemand";
import FormAutocomplete from "components/FormAutocomplete";
import CategoryAutocomplete from "screens/ItemCreateEdit/components/CategoryAutocomplete";

import itemInfoValidate from "screens/ItemCreateEdit/components/itemInfoValidate";

import { YEARS_LIST } from "lib/form/constants";
import { formatDateForDatePicker } from "lib/date/format";

const itemInfoFields = (statusList) => {
  return [
    {
      name: "category",
      component: CategoryAutocomplete,
      props: {
        externalLabel: true,
        label: "Category",
        requiredMessage: "A category must be selected",
        required: true,
      },
    },
    {
      name: "name",
      component: FormInput,
      props: {
        fullWidth: true,
        label: "Item name",
        placeholder: "Insert item name",
        required: true,
        externalLabel: true,
      },
    },
    {
      name: "model",
      component: FormInput,
      props: {
        fullWidth: true,
        label: "Model",
        placeholder: "Insert model name",
        required: true,
        externalLabel: true,
      },
    },
    {
      name: "manufacturer",
      component: FormInput,
      props: {
        fullWidth: true,
        label: "Manufacturer",
        placeholder: "Insert manufacturer name",
        required: true,
        externalLabel: true,
      },
    },
    {
      name: "yearManufactured",
      component: FormAutocomplete,
      props: {
        label: "Year manufactured",
        placeholder: "Select year",
        options: YEARS_LIST,
        optionLabelSelector: "label",
        optionValueSelector: "value",
      },
    },
    {
      name: "serialNumber",
      component: FormInput,
      props: {
        externalLabel: true,
        label: "Serial number",
        placeholder: "Insert item serial number",
      },
    },
    {
      name: "description",
      component: FormInput,
      props: {
        externalLabel: true,
        label: "Item description",
        placeholder: "Insert item description",
        multiline: true,
        rows: 5,
      },
    },
    {
      name: "pictures",
      component: FormFileInput,
      props: {
        label: "Item pictures",
        externalLabel: true,
        multiple: true,
        accept: "image/*",
      },
    },
    {
      name: "status",
      component: FormSelect,
      props: {
        label: "Item status",
        placeholder: "Select status",
        fullWidth: true,
        options: statusList,
        valueAccessor: "_id",
        labelAccessor: "name",
        initialValue: statusList.find((status) => status.name === "Draft")?._id,
        required: true,
        externalLabel: true,
      },
    },
    {
      name: "statusNoteField",
      component: FormTextareaOnDemand,
      props: {
        externalLabel: true,
        label: " ",
        placeholder: "Status note",
        initialValue: "",
        onClickButtonText: "Save note",
        notesField: "statusNote",
      },
    },
    {
      name: "source",
      component: FormInput,
      props: {
        externalLabel: true,
        label: "Source",
        placeholder: "Insert source",
        inputProps: {
          maxLength: 70,
        },
      },
    },
    {
      name: "dateOfAdquisition",
      component: FormDate,
      props: {
        externalLabel: true,
        label: "Acquisition date",
        placeholder: "Select date",
        inputProps: {
          max: formatDateForDatePicker(new Date()),
        },
      },
    },
  ];
};

// const productAutocompleteField = {
//   name: "category",
//   component: CategoryAutocomplete,
//   props: {
//     externalLabel: true,
//     label: "Category",
//     requiredMessage: "A category must be selected",
//     required: true,
//   },
// };

const getInitialValues = (selected) => {
  let initialValues;

  if (selected) {
    initialValues = {
      name: selected.name,
      model: selected.model,
      manufacturer: selected.manufacturer,
      yearManufactured: selected.yearManufactured
        ? selected.yearManufactured.toString()
        : "",
      serialNumber: selected.serialNumber,
      description: selected.description,
      pictures: selected.pictures,
      status: selected.status?._id || "",
      statusNoteField: "",
      statusNote: selected.statusNote,
      source: selected.source,
      dateOfAdquisition: formatDateForDatePicker(selected.dateOfAdquisition),
      location: selected.location,
      category: selected.category?._id || "",
      // this field must be added whenever we want to use image deletion feature
      imagesToDelete: [],
      selected: selected._id,
    };
  } else {
    initialValues = {
      name: "",
      model: "",
      manufacturer: "",
      yearManufactured: "",
      serialNumber: "",
      description: "",
      pictures: [],
      status: "",
      statusNote: [],
      statusNoteField: "",
      source: "",
      dateOfAdquisition: "",
      location: "no",
      category: "",
    };
  }

  return initialValues;
};

const itemInfoForm = (statusList, aaaa, selected) => {
  return {
    // fields: [productAutocompleteField, ...itemInfoFields(statusList)],
    // : itemInfoFields(statusList),
    fields: itemInfoFields(statusList),
    validate: (values) => itemInfoValidate(values, statusList, selected),
    displayLabel: "Item information",
    name: "itemInfo",
    initialValues: getInitialValues(selected),
  };
};

export default itemInfoForm;
