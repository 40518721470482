import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from "react-router-dom";
import TableText from "components/TableText";

const CategoryTableLink = ({item, accessor, ...props}) => {
  const history = useHistory();

  const goToItemDetail = () => {
    history.push(`/inventory/categories/${item._id}/items`);
  }

  return (
    <TableText
      item={item}
      accessor={accessor}
      style={{ cursor: "pointer" }}
      onClick={goToItemDetail}
      {...props}
    />
  );
};

CategoryTableLink.propTypes = {
  item: PropTypes.object.isRequired
};

export default CategoryTableLink;
