import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const LayoutHeaderTitle = ({ titleComponent, title }) => {
  return titleComponent || <Typography variant="h1">{title}</Typography>;
};

LayoutHeaderTitle.propTypes = {
  titleComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
};

export default LayoutHeaderTitle;
