import React from "react";
import PropTypes from "prop-types";

import { List, ListItem, Divider, Typography } from "@material-ui/core";

import ItemDetailList from "components/ItemDetailList";
import SingleToggleComponent from "components/SingleToggleComponent";

const ContainersList = ({ list }) => {
  const ContainerDetail = ({ container }) => {
    return (
      <SingleToggleComponent
        textIfClosed={
          <Typography>
            {container.type.name} #{container.name} ({container.items.length})
          </Typography>
        }
      >
        {container.items.length ? (
          <ItemDetailList list={container.items} />
        ) : (
          <Typography>No items to display</Typography>
        )}
      </SingleToggleComponent>
    );
  };

  return (
    <List>
      {list.map((container, index) => (
        <ListItem key={container._id} disableGutters>
          {index > 0 && <Divider />}
          <ContainerDetail container={container} />
        </ListItem>
      ))}
    </List>
  );
};

ContainersList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default ContainersList;
