import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const ModalAlert = ({
  title,
  children,
  open,
  closeModal,
  closeModalText = "Ok",
}) => {
  return open && (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color={"secondary"} onClick={closeModal}>
          {closeModalText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalAlert.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalAlert;
