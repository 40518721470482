import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DrawerContainer from "components/DrawerContainer";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  fetchSingleItem,
  unsetSelectedItem,
  unsetCheckedItems,
  deleteItem,
} from "store/item/actions";

import { SUCCESS_STATUS } from "components/MessageService/constants";
import { unsetContainersAutocompleteList } from "store/container/actions";
import {
  unsetLevelsList,
  unsetRacksList,
  unsetRowsList,
} from "store/warehouse/actions";
import { unsetOpenSpacesList } from "store/open-space/actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#C70000",
    color: "#fff",
    fontSize: 10,
  },
}));

const DeleteItem = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);
  const { selectedItems } = useSelector(({ itemReducer }) => itemReducer);
  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  const DeleteItemsSelected = (values) => {
    if (selectedItems.length > 1) {
      selectedItems.map((item) => dispatch(deleteItem(item, values)));
    } else {
      dispatch(deleteItem(selectedItems[0], values));
    }
  };

  useEffect(() => {
    if (messagePayload && messagePayload.type === SUCCESS_STATUS) {
      setOpenDrawer(false);
      dispatch(unsetCheckedItems());
      dispatch(unsetContainersAutocompleteList());
      dispatch(unsetRacksList());
      dispatch(unsetLevelsList());
      dispatch(unsetRowsList());
      dispatch(unsetOpenSpacesList());
    }
  }, [dispatch, messagePayload]);

  const toggleDrawer = () => {
    setOpenDrawer((openDrawer) => !openDrawer);
  };

  useEffect(() => {
    if (openDrawer && selectedItems.length === 1) {
      dispatch(fetchSingleItem(selectedItems[0]));
    } else {
      dispatch(unsetSelectedItem());
    }
  }, [dispatch, openDrawer, selectedItems]);

  const ButtonOpener = (props) => {
    return (
      <div>
        <Button
          disabled={!selectedItems.length}
          variant="contained"
          color="secondary"
          // className={classes.button}
          startIcon={<DeleteOutlineIcon />}
          {...props}
        >
          Delete Item
        </Button>
      </div>
    );
  };

  return (
    <DrawerContainer
      openFrom={"right"}
      uncontrolledAction={toggleDrawer}
      uncontrolledIsOpen={openDrawer}
      OpenerComponent={(props) => <ButtonOpener onClick={toggleDrawer} />}
    >
      <div style={{ padding: 20, width: 300 }}>
        <div style={{ paddingBottom: 30, paddingTop: 20 }}>
          <Typography style={{ fontWeight: "bold" }}>
            Delete {selectedItems.length} selected item
            {selectedItems.length > 1 ? `s` : ""}
          </Typography>
        </div>

        <div
          style={{
            paddingBottom: 30,
            paddingTop: 30,
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button onClick={toggleDrawer} color={"secondary"}>
            Cancel
          </Button>
          <Button
            onClick={DeleteItemsSelected}
            disabled={!selectedItems.length}
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
    </DrawerContainer>
  );
};

export default DeleteItem;
