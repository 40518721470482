import React from 'react';
import PropTypes from 'prop-types';

import FormInDialog from "components/FormInDialog";
import FormInput from "components/FormInput";

import openSpaceFormValidate from "screens/WarehouseDetail/components/open-spaces/openSpaceFormValidate";

const OpenSpaceModal = ({OpenerComponent, title, submitActionText, cancelActionText, ...formProps}) => {
  const openSpaceModalFields = [
    {
      name: "name",
      component: FormInput,
      props: {
        label: "Open space name",
        placeholder: "Open space name",
        variant: "outlined",
        fullWidth: true,
      },
    },
    {
      name: "description",
      component: FormInput,
      props: {
        label: "Description",
        placeholder: "Open space description",
        multiline: true,
        variant: "outlined",
        fullWidth: true,
      },
    },
  ];

  return (
    <FormInDialog
      validate={openSpaceFormValidate}
      OpenerComponent={(props) => <OpenerComponent {...props} />}
      title={title}
      submitActionText={submitActionText}
      cancelActionText={cancelActionText}
      formFields={openSpaceModalFields}
      {...formProps}
    />
  );
};

OpenSpaceModal.propTypes = {
  OpenerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string.isRequired,
  submitActionText: PropTypes.string.isRequired,
  cancelActionText: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OpenSpaceModal;