import React from "react";
import PropTypes from "prop-types";
import {
  Star as FavoriteIconFilled,
  StarBorderOutlined as FavoriteIconEmpty,
} from "@material-ui/icons";

const MainWarehouseIndicator = ({ isMain, ...props }) => {
  return isMain ? <FavoriteIconFilled {...props} /> : <FavoriteIconEmpty {...props} />;
};

MainWarehouseIndicator.propTypes = {
  isMain: PropTypes.bool.isRequired
};

export default MainWarehouseIndicator;
