import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FormFieldWrapper from "components/FormFieldWrapper";
import FormAutocompleteCategory from "components/FormAutoCompleteCategory";
import axios from "axios";
import { fetchCategories } from "store/category/actions";
import { baseUrl, headers } from "../../../lib/api/constants";

const CategoryAutocomplete = ({ label, field, requiredMessage, required }) => {
  const dispatch = useDispatch();
  const [searchCategory, setSearchCategory] = useState("");

  const [listCategories, setListCategories] = useState([]);

  async function getCategories() {
    const r = await axios
      .get(`${baseUrl}/categorieslist`, {
        method: "GET",
        headers,
      })
      .catch((err) => console.log(err));

    if (r?.data) {
      setListCategories(r.data.category);
    }
  }

  useEffect(() => {
    let abortController = new AbortController();
    if (listCategories.length === 0) {
      getCategories();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function searchCategoryAction() {
      if (searchCategory) {
        dispatch(fetchCategories({ search: searchCategory || "" }));
      }
    }

    const timeout = setTimeout(searchCategoryAction, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, searchCategory]);

  return (
    <FormFieldWrapper
      name={field.name}
      label={label}
      errorMessage={requiredMessage}
      required={required}
    >
      <FormAutocompleteCategory
        required={required}
        requiredMessage={requiredMessage}
        onSearchExternal={setSearchCategory}
        options={listCategories}
        label={label}
        field={field}
        optionLabelSelector={"name"}
        optionValueSelector={"_id"}
      />
    </FormFieldWrapper>
  );
};

export default CategoryAutocomplete;
