import React from "react";
import PropTypes from "prop-types";

import { Dropdown, Menu } from "antd";

/**
 * Uses antd dropdown component
 * Allows to display an actions menu with custom components
 *
 * @param OpenerComponent
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleMenuWithOpener = ({ OpenerComponent, items }) => {
  const menu = (
    <Menu>
      {items.map((menuItem) => (
        <Menu.Item key={menuItem.key}>{menuItem.component}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <OpenerComponent />
    </Dropdown>
  );
};

SimpleMenuWithOpener.propTypes = {
  OpenerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    })
  ),
};

export default SimpleMenuWithOpener;
