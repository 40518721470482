import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import "./styles.scss";

/**
 * Component to display a single accordion with custom title & content
 *
 * @param title
 * @param keyIndex
 * @param children
 * @param summaryActions
 * @param accordionDetailsClassnames
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AccordionLayout = ({
  title,
  keyIndex,
  children,
  summaryActions,
  accordionDetailsClassnames,
  ...props
}) => {
  return (
    <Accordion className={"accordion-layout"} {...props}>
      <AccordionSummary
        className={"accordion-summary"}
        expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
        aria-controls={`${keyIndex}-content`}
        id={`${children}-header`}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={3}>
            <Typography className={"accordion-summary-title"}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {props.expanded && !!summaryActions && summaryActions}
      <AccordionDetails
        className={classnames({
          [accordionDetailsClassnames]: !!accordionDetailsClassnames,
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

AccordionLayout.propTypes = {
  title: PropTypes.string.isRequired,
  keyIndex: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.object,
  ]),
  summaryActions: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default AccordionLayout;
