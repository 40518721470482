import React from "react";

import { Grid } from "@material-ui/core";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import AddUserModal from "screens/Users/components/AddUserModal";
import AuthWrapper from "components/AuthWrapper";

const usersHeaderComponents = [
  <Grid item xs={3} md={3} lg={6}>
    <LayoutHeaderTitle title={"Users"} />
  </Grid>,
  <AuthWrapper permission={"USERS.USERS_CREATE"}>
    <Grid item xs={3} md={3} lg={6}>
      <div style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}>
        <AddUserModal />
      </div>
    </Grid>
  </AuthWrapper>,
];

export default usersHeaderComponents;
