import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "@material-ui/core";

import DrawerContainer from "components/DrawerContainer";
import LocationSelectionForm from "components/LocationSelectionForm";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import {
  fetchSingleItem,
  saveItemLocation,
  unsetSelectedItem,
  unsetCheckedItems,
} from "store/item/actions";
// import { makeStyles } from "@material-ui/core/styles";

import { SUCCESS_STATUS } from "components/MessageService/constants";
import { unsetContainersAutocompleteList } from "store/container/actions";
import {
  unsetLevelsList,
  unsetRacksList,
  unsetRowsList,
} from "store/warehouse/actions";
import { unsetOpenSpacesList } from "store/open-space/actions";

const SetLocationButton = () => {
  const dispatch = useDispatch();

  // const useStyles = makeStyles((theme) => ({
  //   button: {
  //     margin: theme.spacing(1),
  //     // background: "#482880",
  //     color: "#fff",
  //     fontSize: 10,
  //   },
  // }));

  // const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { selectedItems } = useSelector(({ itemReducer }) => itemReducer);
  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  const submitLocationToItems = (values) => {
    dispatch(saveItemLocation(selectedItems, values));
  };

  useEffect(() => {
    if (messagePayload && messagePayload.type === SUCCESS_STATUS) {
      setOpenDrawer(false);
      dispatch(unsetCheckedItems());
      dispatch(unsetContainersAutocompleteList());
      dispatch(unsetRacksList());
      dispatch(unsetLevelsList());
      dispatch(unsetRowsList());
      dispatch(unsetOpenSpacesList());
    }
  }, [dispatch, messagePayload]);

  const toggleDrawer = () => {
    setOpenDrawer((openDrawer) => !openDrawer);
  };

  useEffect(() => {
    if (openDrawer && selectedItems.length === 1) {
      dispatch(fetchSingleItem(selectedItems[0]));
    } else {
      dispatch(unsetSelectedItem());
    }
  }, [dispatch, openDrawer, selectedItems]);

  const ButtonOpener = (props) => {
    return (
      <Button
        style={{ marginLeft: 10, marginRight: 10 }}
        disabled={!selectedItems.length}
        color={"secondary"}
        variant={"contained"}
        startIcon={<LocationOnIcon />}
        // className={classes.button}
        {...props}
      >
        Set location
      </Button>
    );
  };

  return (
    <DrawerContainer
      openFrom={"right"}
      uncontrolledAction={toggleDrawer}
      uncontrolledIsOpen={openDrawer}
      OpenerComponent={(props) => <ButtonOpener onClick={toggleDrawer} />}
    >
      <div style={{ padding: 20, width: 300 }}>
        <div style={{ paddingBottom: 30 }}>
          <Typography style={{ fontWeight: "bold" }}>
            Assign location to {selectedItems.length} selected item
            {selectedItems.length > 1 ? `s` : ""}
          </Typography>
        </div>
        <LocationSelectionForm
          onSubmitActionText={"Save location"}
          handleSubmit={submitLocationToItems}
          handleCancelAction={toggleDrawer}
        />
      </div>
    </DrawerContainer>
  );
};

export default SetLocationButton;
