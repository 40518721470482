import { createMuiTheme } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: "2rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "1.7rem",
      padding: "15px 0",
    },
    fontFamily: ["Roboto"],
  },
  palette: {
    primary: {
      main: "#EFEFEF",
    },
    secondary: {
      main: deepPurple[500],
    },
  },
});

export default theme;
