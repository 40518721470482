import React, {useState} from "react";

import SearchBar from "components/SearchBar";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const ItemsSearchBar = () => {
  const location = useLocation();

  const searchParams = queryString.parse(location.search);
  const [searchObject, setSearchObject] = useState(searchParams);

  const searchItems = (payloadQuery) => {
    setSearchObject(payloadQuery);
  };

  return (
    <div>
      <SearchBar
        defaultValue={searchObject?.search}
        searchKeyAccessor={'search'}
        name={"itemSearch"}
        onChange={searchItems}
        placeholder={"Search items"}
        fullWidth
        margin="dense"
        searchValueLengthToSearch={3}
      />
    </div>
  );
};

export default ItemsSearchBar;
