const SET_LOADING_STATUS = "HELPER/SET_LOADING_STATUS";
const UNSET_LOADING_STATUS = "HELPER/UNSET_LOADING_STATUS";
const SET_FETCH_MESSAGE = "HELPER/SET_FETCH_MESSAGE";
const UNSET_FETCH_MESSAGE = "HELPER/UNSET_FETCH_MESSAGE";

export {
  SET_LOADING_STATUS,
  UNSET_LOADING_STATUS,
  SET_FETCH_MESSAGE,
  UNSET_FETCH_MESSAGE,
};
