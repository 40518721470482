import {
  setFetchMessage,
  setLoadingStatus,
  unsetLoadingStatus,
} from "store/helper/actions";
import {SET_ROLES_LIST, SET_USERS_LIST} from "store/user/types";

import * as api from "lib/api";
import API_RESOURCES from "lib/api/resources";

export const setUsersList = (usersList) => ({
  type: SET_USERS_LIST,
  payload: {
    usersList,
  },
});

export const fetchUsersList = () => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());
    dispatch(setUsersList([]));

    try {
      const response = await api.get(API_RESOURCES.USERS);

      if (response) {
        dispatch(setUsersList(response.data));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

/**
 * Action to create / edit users
 *
 * @param userPayload
 * @param userId
 * @param shouldUpdate
 * @returns {function(*): Promise<*>}
 */
export const saveUser = (userPayload, userId, shouldUpdate = true) => {
  return async (dispatch) => {
    try {
      let response;

      if (userId) {
        response = await api.put(
          `${API_RESOURCES.USERS}/${userId}`,
          userPayload
        );
      } else {
        response = await api.post(API_RESOURCES.USERS, userPayload);
      }

      if (response) {
        dispatch(setFetchMessage({ ...response, shouldUpdate }));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

/**
 * Set roles list in store
 *
 * @param rolesList
 * @returns {{payload: {rolesList: *}, type: string}}
 */
const setRolesList = (rolesList) => ({
  type: SET_ROLES_LIST,
  payload: {
    rolesList,
  },
});

/**
 * Fetch roles list
 * @returns {function(*): Promise<void>}
 */
export const fetchUserRoles = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.ROLES);

      if (response) {
        dispatch(setRolesList(response.data));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};
