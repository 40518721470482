import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import { Typography } from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";
import AutocompleteWrapper from "components/AutocompleteWrapper";

const FormAutocomplete = ({
  field,
  options,
  label,
  placeholder,
  onSearchExternal,
  onSelectExternal,
  requiredMessage,
  required,
  disabled,
  optionLabelSelector = "label",
  optionValueSelector,
  ...props
}) => {
  const form = useFormikContext();

  const [selectedValue, setSelectedValue] = useState(field.value);

  const onChange = (value) => {
    form.setFieldValue(field.name, value);
    setSelectedValue(value);

    if (onSearchExternal) onSearchExternal(value);
  };

  const onSelectValue = (value) => {
    form.setFieldValue(field.name, value);
    setSelectedValue(value);

    if (onSelectExternal) onSelectExternal(value);
  };

  useEffect(() => {
    if (!selectedValue || !selectedValue.length) {
      form.setFieldError(field.name, "");
      onChange("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <FormFieldWrapper
      name={field.name}
      label={label}
      errorMessage={requiredMessage}
      required={required}
      customError={
        form.touched[field.name] && !!form.errors[field.name] && !selectedValue
      }
    >
      <AutocompleteWrapper
        optionLabelSelector={optionLabelSelector}
        optionValueSelector={optionValueSelector}
        size={"large"}
        options={options}
        onSearch={onChange}
        onSelect={onSelectValue}
        allowClear
        placeholder={placeholder || label}
        notFoundContent={<Typography>No results to display.</Typography>}
        defaultValue={field.value || ""}
        {...props}
      />
    </FormFieldWrapper>
  );
};

FormAutocomplete.propTypes = {
  optionLabelSelector: PropTypes.string,
  optionValueSelector: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default FormAutocomplete;
