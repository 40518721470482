import React from "react";
import { Button, Grid } from "@material-ui/core";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import LinkContainer from "components/LinkContainer";

import Icon from "components/Icon";
import AuthWrapper from "components/AuthWrapper";

const headerComponents = [
  <Grid item xs={3} md={3} lg={6}>
    <LayoutHeaderTitle title={"Warehouses"} />
  </Grid>,
  <AuthWrapper permission={"WAREHOUSE.WAREHOUSE_CREATE"}>
    <Grid item xs={6} md={6} lg={6}>
      <div style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}>
        <LinkContainer
          to="/warehouses/create"
          Component={(props) => (
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Icon name={"plus"} />}
              {...props}
            >
              Add Warehouse
            </Button>
          )}
        />
      </div>
    </Grid>
  </AuthWrapper>,
];

export default headerComponents;
