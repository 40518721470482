import React from "react";

import { Button, Grid } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import LinkContainer from "components/LinkContainer";
import ItemTitle from "screens/InventoryItemDetail/components/ItemTitle";
import GoToItemHistoryButton from "screens/InventoryItemDetail/components/GoToItemHistoryButton";
import AuthWrapper from "components/AuthWrapper";
import Icon from "components/Icon";

const inventoryItemDetailHeaderComponents = (item) => [
  <Grid item xs={6}>
    <LayoutHeaderTitle titleComponent={<ItemTitle item={item} />} />
  </Grid>,
  <Grid item xs={6}>
    <Grid container justify={"flex-end"} alignItems={"center"} spacing={2}>
      <AuthWrapper permission={"ITEMS.ITEMS_CREATE"}>
        <Grid item>
          <LinkContainer
            to={`/inventory/items/clone/${item._id}`}
            Component={(props) => (
              <Button
                {...props}
                variant={"outlined"}
                startIcon={<Icon name={"clone"} />}
                color={"secondary"}
                style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 16, paddingLeft: 16 }}
              >
                Clone item
              </Button>
            )}
          />
        </Grid>
      </AuthWrapper>
      <AuthWrapper permission={"ITEMS.ITEMS_READ_DETAIL_HISTORY"}>
        <Grid item>
          <GoToItemHistoryButton item={item} />
        </Grid>
      </AuthWrapper>
      <AuthWrapper permission={"ITEMS.ITEMS_UPDATE"}>
        <Grid item>
          <LinkContainer
            to={`/inventory/items/edit/${item._id}`}
            Component={(props) => (
              <Button
                {...props}
                variant={"contained"}
                startIcon={<EditIcon />}
                color={"secondary"}
              >
                Edit item
              </Button>
            )}
          />
        </Grid>
      </AuthWrapper>
    </Grid>
  </Grid>
];

export default inventoryItemDetailHeaderComponents;
