import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";

import TableLayout from "components/TableLayout";
import TableText from "components/TableText";
import EditUserButton from "screens/Users/components/EditUserButton";
import UserActiveSwitch from "screens/Users/components/UserActiveSwitch";
import TableUserRole from "screens/Users/components/TableUserRole";

import { fetchUsersList } from "store/user/actions";

import { SUCCESS_STATUS } from "components/MessageService/constants";

const UsersTable = () => {
  const dispatch = useDispatch();

  const { list } = useSelector(({ userReducer }) => userReducer);
  const { loading, messagePayload } = useSelector(
    ({ helperReducer }) => helperReducer
  );

  useEffect(() => {
    dispatch(fetchUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (
      messagePayload &&
      messagePayload.type === SUCCESS_STATUS &&
      messagePayload.shouldUpdate
    ) {
      dispatch(fetchUsersList());
    }
  }, [dispatch, messagePayload]);

  const columns = [
    {
      name: "active",
      displayName: "Active",
      Component: UserActiveSwitch,
      props: {
        width: 40,
      },
    },
    {
      name: "name",
      displayName: "Name",
      Component: TableText,
      shouldSort: true,
      props: {
        width: 150,
      },
    },
    {
      name: "email",
      displayName: "Email",
      Component: TableText,
      shouldSort: true,
      props: {
        width: 300,
      },
    },
    {
      name: "role",
      displayName: "Role",
      Component: TableUserRole,
      props: {
        width: 300,
      },
    },
  ];

  return (
    <TableLayout
      data={list}
      columns={columns}
      loading={loading}
      noItemsMessage={<Typography>There are no users to list.</Typography>}
      actions={[{ Component: EditUserButton, permission: "USERS.USERS_UPDATE" }]}
    />
  );
};

export default UsersTable;
