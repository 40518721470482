import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { DialogContent, DialogTitle } from "@material-ui/core";

import FormWrapper from "components/FormWrapper";
import ModalWithOpener from "components/ModalWithOpener";
import ModalFormActions from "components/FormInDialog/components/ModalFormActions";

import { SUCCESS_STATUS } from "components/MessageService/constants";

const FormInDialog = ({
  OpenerComponent,
  title,
  submitActionText,
  cancelActionText,
  formFields,
  actionOnOpen,
  actionOnClose,
  ...formProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  const openModal = () => {
    setIsOpen(true);

    if (actionOnOpen) actionOnOpen();
  };

  const closeModal = () => {
    setIsOpen(false);

    if (actionOnClose) actionOnClose();
  };

  useEffect(() => {
    if (messagePayload && messagePayload.type === SUCCESS_STATUS) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload]);

  return (
    <ModalWithOpener
      open={isOpen}
      OpenerComponent={<OpenerComponent onClick={openModal} />}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      style={{ width: "100%" }}
    >
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormWrapper
            {...formProps}
            fields={formFields}
            OnSubmitActionComponent={(props) => (
              <ModalFormActions
                onSubmitActionText={submitActionText}
                closeModalText={cancelActionText}
                closeModal={closeModal}
                {...props}
              />
            )}
          />
        </DialogContent>
      </>
    </ModalWithOpener>
  );
};

FormInDialog.propTypes = {
  OpenerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string.isRequired,
  submitActionText: PropTypes.string.isRequired,
  cancelActionText: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formFields: PropTypes.array.isRequired,
};

export default FormInDialog;
