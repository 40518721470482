import itemPricingFormValidate from "screens/ItemCreateEdit/components/itemPricingFormValidate";
import FormInput from "components/FormInput";

const itemPricingFormFields = [
  {
    name: "cost",
    component: FormInput,
    props: {
      externalLabel: true,
      label: "Cost",
      placeholder: "Insert cost or leave empty to set as “TBD",
    },
  },
  {
    name: "price1",
    component: FormInput,
    props: {
      externalLabel: true,
      label: "Pricing",
      placeholder: "Price 1",
    },
  },
  {
    name: "price1Description",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "Price 1 description",
      label: " ",
    },
  },
  {
    name: "price2",
    component: FormInput,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Price 2",
    },
  },
  {
    name: "price2Description",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "Price 2 description",
      label: " ",
    },
  },
  {
    name: "price3",
    component: FormInput,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Price 3",
    },
  },
  {
    name: "price3Description",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "Price 3 description",
      label: " ",
    },
  },
  {
    name: "finalPrice",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "Insert final price",
      label: "Final price",
    },
  },
  {
    name: "thirtyDaysOfPosted",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "30 days after posted",
      label: "Discount allowed (%)",
      type: "number",
      initialValue: 10,
    },
  },
  {
    name: "ninetyDaysOfPosted",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "90 days after posted",
      label: " ",
      type: "number",
      initialValue: 15,
    },
  },
  {
    name: "oneHundredEightyDaysOfPosted",
    component: FormInput,
    props: {
      externalLabel: true,
      placeholder: "180 days after posted",
      label: " ",
      type: "number",
      initialValue: 20,
    },
  },
];

const itemPricingForm = (statusList, selected) => {
  return {
    fields: itemPricingFormFields,
    validate: (values) => itemPricingFormValidate(values, statusList),
    displayLabel: "Pricing",
    name: "pricing",
    initialValues: selected ? {
      cost: selected?.cost,
      price1: selected?.princingOne?.value,
      price1Description: selected?.princingOne?.description,
      price2: selected?.princingTwo?.value,
      price2Description: selected?.princingTwo?.description,
      price3: selected?.princingThree?.value,
      price3Description: selected?.princingThree?.description,
      finalPrice: selected?.finalPrice,
      thirtyDaysOfPosted: selected?.discountAllowed?.thirtyDaysOfPosted,
      ninetyDaysOfPosted: selected?.discountAllowed?.ninetyDaysOfPosted,
      oneHundredEightyDaysOfPosted: selected?.discountAllowed?.oneHundredEightyDaysOfPosted,
    } : {
      cost: "",
      price1: "",
      price1Description: "",
      price2: "",
      price2Description: "",
      price3: "",
      price3Description: "",
      finalPrice: "",
      thirtyDaysOfPosted: 10,
      ninetyDaysOfPosted: 15,
      oneHundredEightyDaysOfPosted: 20,
    },
  };
};

export default itemPricingForm;
