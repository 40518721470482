import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from "@material-ui/core";

const UserInTable = ({item}) => {
  return (
    <Typography>{item?.user?.name}</Typography>
  );
};

UserInTable.propTypes = {
  item: PropTypes.object.isRequired
};

export default UserInTable;