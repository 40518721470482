import {
  discardedStatus,
  readyToBeOnSale,
} from "screens/ItemCreateEdit/components/validationShared";

const SOURCE_MAX_LENGTH = 50;

const itemInfoValidate = (values, statusList, selected) => {
  let errors = {};

  if (readyToBeOnSale(values.status, statusList) && !values.source) {
    errors = {
      ...errors,
      source: "Source is required given selected status",
    };
  }

  if (!values.status || values.status === "-1") {
    errors = {
      ...errors,
      status: "Item status must be selected",
    };
  } else {
    if (
      (!values.yearManufactured || values.yearManufactured === {}) &&
      !discardedStatus(values.status, statusList)
    ) {
      errors = {
        ...errors,
        yearManufactured: "Manufactured year is required given selected status",
      };
    }
  }

  if (!selected && !values.category) {
    errors = {
      ...errors,
      category: "A category must be selected for this item",
    };
  }

  if (values.source && values.source.length > SOURCE_MAX_LENGTH) {
    errors = {
      ...errors,
      source: `Source must have less than ${SOURCE_MAX_LENGTH} characters.`,
    };
  }

  if (!values.name) {
    errors = {
      ...errors,
      name: "Product name is required"
    }
  }

  if (!values.manufacturer) {
    errors = {
      ...errors,
      manufacturer: "Manufacturer is required"
    }
  }

  if (!values.model) {
    errors = {
      ...errors,
      model: "Model is required"
    }
  }

  return errors;
};

export default itemInfoValidate;
