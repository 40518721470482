import {
  SET_FETCH_MESSAGE,
  SET_LOADING_STATUS,
  UNSET_FETCH_MESSAGE,
  UNSET_LOADING_STATUS,
} from "store/helper/types";

export const setLoadingStatus = () => ({
  type: SET_LOADING_STATUS,
});

export const unsetLoadingStatus = () => ({
  type: UNSET_LOADING_STATUS,
});

export const setFetchMessage = (messagePayload) => ({
  type: SET_FETCH_MESSAGE,
  payload: {
    messagePayload,
  },
});

export const unsetFetchMessage = () => ({
  type: UNSET_FETCH_MESSAGE,
});
