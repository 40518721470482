import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import TableLayout from "components/TableLayout";
import TableDate from "components/TableDate";
import HistoricMovementsDetail from "screens/ItemHistory/components/HistoricMovementsDetail";
import UserInTable from "screens/ItemHistory/components/UserInTable";

import { baseUrl, headers } from "../../../lib/api/constants";
import API_RESOURCES from "lib/api/resources";

const HistoryTable = ({ selectedItem }) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const { isFetching } = useSelector(({ itemReducer }) => itemReducer);

  const getListHistory = async () => {
    const url = `${baseUrl}/items/${selectedItem._id}/${API_RESOURCES.ITEM_HISTORY}`;

    const params = {
      method: "GET",
      headers: headers,
    };
    return fetch(url, params)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.data?.currentPage < 0) {
          let number = result.data.currentPage;

          setPage(Math.abs(number));
        } else {
          setPage(result.data?.currentPage);
        }
        setList(result.data.rows);
        setCount(result.data.total);
      })
      .catch((err) => {
        return err.message;
      });
  };

  useEffect(() => {
    if (selectedItem && !list.length) {
      getListHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const columns = [
    {
      name: "date",
      displayName: "Timestamp",
      Component: TableDate,
      componentProps: {
        type: "timestamp",
      },
      props: {
        width: 200,
        style: {
          verticalAlign: "top",
        },
      },
    },
    {
      name: "user",
      displayName: "User",
      Component: UserInTable,
      props: {
        width: 200,
      },
      style: {
        verticalAlign: "top",
      },
    },
    {
      name: "details",
      displayName: "Details",
      Component: HistoricMovementsDetail,
      props: {
        width: 500,
      },
    },
  ];

  return (
    <TableLayout
      keyAccessor={"date"}
      data={list}
      columns={columns}
      loading={isFetching}
      pagination={{ count, page }}
      noItemsMessage={
        <Typography>There are no historic movements to display.</Typography>
      }
    />
  );
};

export default HistoryTable;
