const itemSubcomponentsFormValidate = (values) => {
  let errors = {};

  const subcomponents = values.subcomponents;

  for (let index in subcomponents) {
    if (!subcomponents[index].name) {
      errors = {
        ...errors,
        [`subcomponents[${index}].name`]: "Subcomponent name is required",
      }
    }

    if (!subcomponents[index].serialNumber) {
      errors = {
        ...errors,
        [`subcomponents[${index}].serialNumber`]: "Subcomponent serialNumber is required",
      };
    }

    if (!subcomponents[index].condition) {
      errors = {
        ...errors,
        [`subcomponents[${index}].condition`]: "Subcomponent condition is required",
      };
    }
  }

  return errors;
};

export default itemSubcomponentsFormValidate;
