import itemListingsFormValidate from "screens/ItemCreateEdit/components/itemListingsFormValidate";

import FormInput from "components/FormInput";
import FormDate from "components/FormDate";
import FormSwitch from "components/FormSwitch";
import { formatDateForDatePicker } from "lib/date/format";

const itemListingFields = [
  {
    name: "hasWebsiteListing",
    props: {
      label: "Has website listing?",
      fields: [
        {
          name: "hasWebsiteListing",
          component: FormSwitch,
          gridProps: {
            xs: 2,
          },
          props: {
            label: "",
            checked: false,
          },
        },
        {
          name: "websiteListingDate",
          component: FormDate,
          gridProps: {
            xs: 6,
          },
          props: {
            label: "",
            placeholder: "Select listing date",
            variant: "outlined",
            dependsOnValue: "hasWebsiteListing",
            attributeToChange: "disabled",
            habilitateAttributeValues: [false],
          },
        },
      ],
    },
  },
  {
    name: "websiteURL",
    component: FormInput,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Insert website URL",
      dependsOnValue: "hasWebsiteListing",
      attributeToChange: "disabled",
      habilitateAttributeValues: [false],
    },
  },
  {
    name: "hasEbayListing",
    props: {
      label: "Listed on eBay?",
      fields: [
        {
          name: "hasEbayListing",
          component: FormSwitch,
          gridProps: {
            xs: 2,
          },
          props: {
            label: "",
            checked: false,
          },
        },
        {
          name: "eBayListingDate",
          component: FormDate,
          gridProps: {
            xs: 6,
          },
          props: {
            label: "",
            placeholder: "Select listing date",
            variant: "outlined",
            dependsOnValue: "hasEbayListing",
            attributeToChange: "disabled",
            habilitateAttributeValues: [false],
          },
        },
      ],
    },
  },
  {
    name: "eBayURL",
    component: FormInput,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Insert eBay URL",
      dependsOnValue: "hasEbayListing",
      attributeToChange: "disabled",
      habilitateAttributeValues: [false],
    },
  },
  {
    name: "hasBeenListedByOutsideRep",
    props: {
      label: "Listed by outside rep?",
      fields: [
        {
          name: "hasBeenListedByOutsideRep",
          component: FormSwitch,
          gridProps: {
            xs: 2,
          },
          props: {
            label: "",
            checked: false,
          },
        },
        {
          name: "outsideRepListingDate",
          component: FormDate,
          gridProps: {
            xs: 6,
          },
          props: {
            label: "",
            placeholder: "Select listing date",
            variant: "outlined",
            dependsOnValue: "hasBeenListedByOutsideRep",
            attributeToChange: "disabled",
            habilitateAttributeValues: [false],
          },
        },
      ],
    },
  },
  {
    name: "outsideRepURL",
    component: FormInput,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Insert outside rep URL",
      dependsOnValue: "hasBeenListedByOutsideRep",
      attributeToChange: "disabled",
      habilitateAttributeValues: [false],
    },
  },
];

const itemListingsForm = (selected) => {
  return {
    fields: itemListingFields,
    validate: itemListingsFormValidate,
    displayLabel: "Listings",
    name: "itemListings",
    initialValues: selected
      ? {
          hasWebsiteListing: selected.listedOnWebsite
            ? selected.listedOnWebsite.value
            : false,
          websiteListingDate: selected?.listedOnWebsite?.date
            ? formatDateForDatePicker(selected?.listedOnWebsite?.date)
            : formatDateForDatePicker(new Date()),
          websiteURL: selected?.listedOnWebsite?.link,
          hasEbayListing: selected.listedOnEbay
            ? selected.listedOnEbay.value
            : false,
          eBayListingDate: selected?.listedOnEbay?.date
            ? formatDateForDatePicker(selected?.listedOnEbay?.date)
            : formatDateForDatePicker(new Date()),
          eBayURL: selected?.listedOnEbay?.link,
          hasBeenListedByOutsideRep: selected.listedOnOutsideRepSite
            ? selected.listedOnOutsideRepSite.value
            : false,
          outsideRepListingDate: selected?.listedOnOutsideRepSite?.date
            ? formatDateForDatePicker(selected?.listedOnOutsideRepSite?.date)
            : formatDateForDatePicker(new Date()),
          outsideRepURL: selected?.listedOnOutsideRepSite?.link,
        }
      : {
          hasWebsiteListing: false,
          websiteListingDate: "",
          websiteURL: "",
          hasEbayListing: false,
          eBayListingDate: "",
          eBayURL: "",
          hasBeenListedByOutsideRep: false,
          outsideRepListingDate: "",
          outsideRepURL: "",
        },
  };
};

export default itemListingsForm;
