import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ListFilter from "components/ListFilter";

import {fetchItemStatuses} from "store/item/actions";

const StatusFilter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchItemStatuses());
  }, [dispatch]);

  const {statusList} = useSelector(({itemReducer}) => itemReducer);

  return (
    <ListFilter
      options={statusList}
      filterKey={"status"}
      selectedLabel={"All statuses"}
      unselectedLabel={"Status"}
    />
  );
};

export default StatusFilter;