import React from 'react';
import PropTypes from 'prop-types';
import TableText from "components/TableText";
import {useSelector} from "react-redux";
import {Typography} from "@material-ui/core";

const TableUserRole = ({item}) => {
  const {rolesList} = useSelector(({userReducer}) => userReducer);

  const userRole = item.roles[0];
  const role = rolesList.find(r => r._id === userRole);

  return role ? (
    <TableText
      accessor={"role"}
      item={{ role: role.name }}
    />
  ) : <Typography>-</Typography>;
};

TableUserRole.propTypes = {
  item: PropTypes.object.isRequired
};

export default TableUserRole;