import {
  LOGIN,
  LOGOUT,
  SET_PREVIOUS_LOCATION,
  UNSET_PREVIOUS_LOCATION,
} from "store/auth/types";

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        authObject: payload.userPayload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        authObject: null
      };
    case SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: payload.previousLocation,
      };
    case UNSET_PREVIOUS_LOCATION:
      return { ...state, previousLocation: null };
    default:
      return state;
  }
};

const initialState = {
  isAuthenticated: false,
  authObject: {
    email: null,
    name: null,
    role: null,
    token: null,
  },
  previousLocation: null,
};

export default authReducer;
