import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";

import DeleteRowButton from "screens/WarehouseDetail/components/warehouse-locations/DeleteRowButton";
import AddRackButton from "screens/WarehouseDetail/components/warehouse-locations/AddRackButton";
import AuthWrapper from "components/AuthWrapper";

import "./RowActions.scss";

const RowActions = ({ row }) => {
  return (
    <AuthWrapper permission={'LOCATIONS.LOCATIONS_UPDATE'}>
      <div className={'row-actions-container'}>
        <Grid container justify={"flex-end"}>
          <Grid item xs={3}>
            <Grid container justify={"flex-end"}>
              <Grid item className={"row-actions-button"}>
                <DeleteRowButton row={row} />
              </Grid>
              <Grid item className={"row-actions-button"}>
                <AddRackButton row={row} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </AuthWrapper>
  );
};

RowActions.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowActions;
