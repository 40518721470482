import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import LinkContainer from "components/LinkContainer";

// TODO: extend this component (icon button or so)
const EditItemButton = ({ item }) => {
  return (
    <LinkContainer
      to={`/inventory/items/edit/${item._id}`}
      Component={(props) => (
        <IconButton {...props}>
          <Icon
            name={"edit"}
            color={"black"}
            classnames={"item-table-action-icon"}
            fontSize={18}
          />
        </IconButton>
      )}
    />
  );
};

EditItemButton.propTypes = {
  // TODO: handle shape of product
  item: PropTypes.object.isRequired,
};

export default EditItemButton;
