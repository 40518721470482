import React from "react";

import { Button, Grid, Typography } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import LinkContainer from "components/LinkContainer";
import ItemsSearchBar from "screens/InventoryItems/components/ItemsSearchBar";
import AuthWrapper from "components/AuthWrapper";

const categoryItemsHeaderComponents = (category) => [
  <Grid item xs={3} md={3} lg={6}>
    <LayoutHeaderTitle
      titleComponent={
        <Typography variant={"h1"}>
          <span>Items</span>{" "}
          {category && (
            <>
              in{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {category ? category.name : ""}
              </span>{" "}
              category
            </>
          )}
        </Typography>
      }
    />
  </Grid>,
  <Grid item xs={6} md={6} lg={4}>
    <ItemsSearchBar />
  </Grid>,
  <AuthWrapper permission={"ITEMS.ITEMS_CREATE"}>
    <Grid item xs={2} md={2} lg={2} style={{ paddingRight: 20 }}>
      <LinkContainer
        to={
          category
            ? `/inventory/categories/${category._id}/items/create`
            : "/inventory/items/create"
        }
        Component={(props) => (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            startIcon={<AddIcon />}
            {...props}
          >
            Add item
          </Button>
        )}
      />
    </Grid>
  </AuthWrapper>,
];

export default categoryItemsHeaderComponents;
