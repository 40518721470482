import {
  SET_ITEM_CONDITIONS_LIST,
  SET_ITEM_STATUS_LIST,
  SET_ITEMS_LIST,
  SET_SELECTED_ITEM,
  SET_SELECTED_ITEMS_LIST,
  UNSET_SELECTED_ITEM,
  SET_ITEM_LOADING,
  UNSET_ITEM_LOADING,
  UNSET_SELECTED_ITEMS_LIST,
  SET_ITEM_HISTORY_LIST,
  SET_IMAGE_UPLOADING,
  UNSET_IMAGE_UPLOADING,
} from "store/item/types";
import {
  setFetchMessage,
  setLoadingStatus,
  unsetLoadingStatus,
} from "store/helper/actions";

import * as api from "lib/api";
import API_RESOURCES from "lib/api/resources";
import { parseObjectToArray } from "lib/array/operations";

import { deleteFiles, fileCreation } from "lib/files/operations";
import { addComments } from "lib/audit/operations";
import { SUCCESS_STATUS } from "components/MessageService/constants";

import queryString from "query-string";

import { uploadFileS3 } from "lib/files/operations";
// import ItemCategory from "screens/InventoryItems/components/ItemCategory";

/**
 * Action to set item conditions list in store
 *
 * @param conditionsList
 * @returns {{payload: {conditionsList: *}, type: string}}
 */
export const setItemConditionsList = (conditionsList) => ({
  type: SET_ITEM_CONDITIONS_LIST,
  payload: {
    conditionsList,
  },
});

/**
 * Action to fetch item conditions list
 *
 * @returns {function(*): Promise<void>}
 */
export const fetchItemConditions = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.ITEM_CONDITIONS);

      if (response.data) {
        dispatch(setItemConditionsList(response.data.rows));
      }
    } catch (error) {
      dispatch(setFetchMessage(error));
    }
  };
};

/**
 * Action to set item statuses list in store
 *
 * @param statusList
 * @returns {{payload: {statusList: *}, type: string}}
 */
export const setItemStatusList = (statusList) => ({
  type: SET_ITEM_STATUS_LIST,
  payload: {
    statusList,
  },
});

/**
 * Action to fetch item statuses list
 *
 * @returns {function(*): Promise<void>}
 */
export const fetchItemStatuses = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.ITEM_STATUSES);

      if (response.data) {
        dispatch(setItemStatusList(response.data.rows));
      }
    } catch (error) {
      dispatch(setFetchMessage(error));
    }
  };
};

const ITEM_COMMON_COMMENT = {
  entity: "items",
};

async function parseItemPayload(
  itemRawPayload,
  category,
  hasPricingPermission
) {
  try {
    let payload = {};

    if (itemRawPayload.statusNoteField) {
      const comments = await addComments(
        itemRawPayload.statusNoteField.map((note) => ({
          ...ITEM_COMMON_COMMENT,
          comment: note.comment,
          attribute: "statusNote",
        }))
      );

      if (comments) {
        payload = {
          ...payload,
          statusNote: [
            ...(itemRawPayload.statusNote || []).map((note) => note._id),
            ...comments.map((comment) => comment.data._id),
          ],
        };
      }
    }

    if (itemRawPayload.conditionNoteField) {
      const comments = await addComments(
        itemRawPayload.conditionNoteField.map((note) => ({
          ...ITEM_COMMON_COMMENT,
          comment: note.comment,
          attribute: "conditionNote",
        }))
      );

      if (comments) {
        payload = {
          ...payload,
          conditionNote: [
            ...(itemRawPayload.conditionNote || []).map((note) => note._id),
            ...comments.map((comment) => comment.data._id),
          ],
        };
      }
    }

    if (itemRawPayload.serviceNoteField) {
      const comments = await addComments(
        itemRawPayload.serviceNoteField.map((note) => ({
          ...ITEM_COMMON_COMMENT,
          comment: note.comment,
          attribute: "serviceNote",
        }))
      );

      if (comments) {
        payload = {
          ...payload,
          specifyServices: {
            description: [
              ...(itemRawPayload.serviceNote || []).map((note) => note._id),
              ...comments.map((comment) => comment.data._id),
            ],
          },
        };
      }
    }

    // Handle condition to determine if user can write pricing fields
    if (hasPricingPermission) {
      payload = {
        ...payload,
        princingOne: {
          value: itemRawPayload.price1,
          description: itemRawPayload.price1Description,
        },
        princingTwo: {
          value: itemRawPayload.price2,
          description: itemRawPayload.price2Description,
        },
        princingThree: {
          value: itemRawPayload.price3,
          description: itemRawPayload.price3Description,
        },
        finalPrice: itemRawPayload.finalPrice,
        cost: itemRawPayload.cost,
        discountAllowed: {
          thirtyDaysOfPosted: itemRawPayload.thirtyDaysOfPosted,
          ninetyDaysOfPosted: itemRawPayload.ninetyDaysOfPosted,
          oneHundredEightyDaysOfPosted:
            itemRawPayload.oneHundredEightyDaysOfPosted,
        },
      };
    }

    return {
      ...payload,
      name: itemRawPayload.name,
      model: itemRawPayload.model,
      category: itemRawPayload.category,
      manufacturer: itemRawPayload.manufacturer,
      dateOfAdquisition: itemRawPayload.dateOfAdquisition || null,
      description: itemRawPayload.description || "",
      status: itemRawPayload.status,
      condition: itemRawPayload.condition,
      yearManufactured: itemRawPayload.yearManufactured
        ? parseInt(itemRawPayload.yearManufactured)
        : null,
      serialNumber: itemRawPayload.serialNumber || "",
      software: itemRawPayload.software,
      softwareVersion: itemRawPayload.softwareVersion,
      accesories: {
        value: itemRawPayload.accesories,
        description: itemRawPayload.accessoriesDescription,
      },
      specifyServices: {
        ...payload.specifyServices,
        value: parseObjectToArray(itemRawPayload.specifyServices),
      },
      source: itemRawPayload.source,
      tested: itemRawPayload.tested,
      testNote: itemRawPayload.testDescription,
      pictures: itemRawPayload.pictures
        ? [
            ...itemRawPayload.pictures.filter((image) => !!image._id),
            ...(
              await fileCreation(
                "items",
                itemRawPayload.pictures.filter((image) => !!image.size)
              )
            ).map((picture, index) => ({
              file: picture.data.fileName,
              isMain: index === 0,
            })),
          ]
        : [],
      location: "no",

      subcomponents:
        itemRawPayload?.subcomponents?.length > 0
          ? itemRawPayload.subcomponents
          : [],
      listedOnWebsite: {
        value: itemRawPayload.hasWebsiteListing,
        link: itemRawPayload.hasWebsiteListing ? itemRawPayload.websiteURL : "",
        date: itemRawPayload.hasWebsiteListing
          ? itemRawPayload.websiteListingDate
          : "",
      },
      listedOnEbay: {
        value: itemRawPayload.hasEbayListing,
        link: itemRawPayload.hasEbayListing ? itemRawPayload.eBayURL : "",
        date: itemRawPayload.hasEbayListing
          ? itemRawPayload.eBayListingDate
          : "",
      },
      listedOnOutsideRepSite: {
        value: itemRawPayload.hasBeenListedByOutsideRep,
        link: itemRawPayload.hasBeenListedByOutsideRep
          ? itemRawPayload.outsideRepURL
          : "",
        date: itemRawPayload.hasBeenListedByOutsideRep
          ? itemRawPayload.outsideRepListingDate
          : "",
      },
    };
  } catch (error) {
    console.log(error);
  }
}

/**
 * Action to create a product item
 *
 * @param itemRawPayload
 * @param toClone
 * @returns {function(*): Promise<void>}
 */
export const saveItem = (itemRawPayload, toClone = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingStatus());

    try {
      const { categoryReducer, authReducer } = getState();

      if (itemRawPayload.selected && itemRawPayload.imagesToDelete.length) {
        await deleteFiles(itemRawPayload.imagesToDelete);
      }

      let response;

      const isCloneView = window.location.pathname.includes("clone");

      if (itemRawPayload.selected && !isCloneView) {
        const canUpdatePricing =
          authReducer.authObject.actions.ITEMS.ITEMS_UPDATE_PRICING;

        response = await api.put(
          `${API_RESOURCES.ITEMS}/${itemRawPayload.selected}`,
          await parseItemPayload(
            itemRawPayload,
            itemRawPayload.category._id,
            canUpdatePricing
          )
        );
      } else {
        const createPricing =
          authReducer.authObject.actions.ITEMS.ITEMS_CREATE_PRICING;

        response = await api.post(
          API_RESOURCES.ITEMS,
          await parseItemPayload(
            itemRawPayload,
            itemRawPayload.category || categoryReducer.selected._id,
            createPricing
          )
        );
      }

      if (response) {
        if (toClone) {
          dispatch(setSelectedItem(response.data));
        }

        dispatch(setFetchMessage(response));
      }
    } catch (error) {
      dispatch(setFetchMessage(error));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

/**
 * Set items list in item reducer
 *
 * @param data
 * @returns {{payload: {list: *}, type: string}}
 */
const setItemsList = (data) => ({
  type: SET_ITEMS_LIST,
  payload: {
    data,
  },
});

/**
 * Action to fetch list items from server
 *
 * @param query
 * @returns {function(*): Promise<void>}
 */
export const fetchItemsList = (query) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      const response = await api.get(API_RESOURCES.ITEMS, {
        ...query,
        limit: 20,
      });

      if (response.data) {
        dispatch(setItemsList(response.data));
      }
    } catch (error) {
      dispatch(setFetchMessage(error));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

/**
 * Set selected item in store
 *
 * @param selected
 * @returns {{payload: {selected: *}, type: string}}
 */
const setSelectedItem = (selected) => ({
  type: SET_SELECTED_ITEM,
  payload: {
    selected,
  },
});

/**
 * Action to fetch one item
 *
 * @param itemId
 * @returns {function(*): Promise<void>}
 */
export const fetchSingleItem = (itemId) => {
  return async (dispatch) => {
    dispatch(setItemLoading());

    try {
      const response = await api.get(`${API_RESOURCES.ITEMS}/${itemId}`);

      if (response) {
        dispatch(setSelectedItem(response.data));
        dispatch(setImageUploading());
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
    }
  };
};

/**
 * Action to define an item prepared to be cloned
 * (it removes serial number, SKU number & specified services)
 *
 * @param itemId
 * @returns {function(*): Promise<void>}
 */
export const fetchItemToClone = (itemId) => {
  return async (dispatch) => {
    dispatch(setItemLoading());

    try {
      const response = await api.get(`${API_RESOURCES.ITEMS}/${itemId}`);

      if (response) {
        dispatch(
          setSelectedItem({
            ...response.data,
            serialNumber: "",
            number: "",
            specifyServices: { description: [], value: [] },
            conditionNote: [],
            statusNote: [],
            pictures: [],
          })
        );
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
    }
  };
};

export const unsetSelectedItem = () => ({
  type: UNSET_SELECTED_ITEM,
});

export const setCheckedItems = (selectedItems) => ({
  type: SET_SELECTED_ITEMS_LIST,
  payload: {
    selectedItems,
  },
});

export const unsetCheckedItems = () => ({
  type: UNSET_SELECTED_ITEMS_LIST,
});

const setItemLoading = () => ({
  type: SET_ITEM_LOADING,
});

const unsetItemLoading = () => ({
  type: UNSET_ITEM_LOADING,
});

const putMassiveItemsPromise = (items, url, payload) => {
  let requests = [];

  for (let item in items) {
    if (items.hasOwnProperty(item)) {
      requests = [
        api.put(`${API_RESOURCES.ITEMS}/${items[item]}/${url}`, payload),
      ];
    }
  }

  return Promise.all(requests);
};

export const saveItemLocation = (items, itemLocationPayload) => {
  return async (dispatch, getState) => {
    dispatch(setItemLoading());
    try {
      let url = API_RESOURCES.CONTAINER_LOCATION;
      let payload;
      if (itemLocationPayload.containerOnly) {
        payload = {
          placeHolder: JSON.parse(itemLocationPayload.containerOnly)._id,
        };
      } else {
        if (itemLocationPayload.type === "container") {
          payload = {
            placeHolder: itemLocationPayload.container,
          };
        }
        if (itemLocationPayload.type === "shelf") {
          payload = {
            placeHolder: itemLocationPayload.shelf,
          };
          url = API_RESOURCES.CONTAINER_LOCATION_SHELF;
        }
        if (itemLocationPayload.type === "openSpace") {
          payload = {
            placeHolder: itemLocationPayload.row,
          };
          url = API_RESOURCES.CONTAINER_LOCATION_OS;
        }
      }
      const response = await putMassiveItemsPromise(items, url, payload);
      if (response.length && response[0].type === SUCCESS_STATUS) {
        const { categoryReducer } = getState();
        const { selected: selectedCategory } = categoryReducer;
        const searchQueryParams = queryString.parse(window.location.search);
        if (selectedCategory) {
          dispatch(
            fetchItemsList({
              category: selectedCategory._id,
              ...searchQueryParams,
            })
          );
        } else {
          dispatch(fetchItemsList(searchQueryParams));
        }
      }
      if (response.length) {
        dispatch(setFetchMessage(response[0]));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
    }
  };
};

export const deleteItem = (itemId) => {
  return async (dispatch, getState) => {
    dispatch(setItemLoading());
    try {
      const response = await api.deleteMethodItem(
        `${API_RESOURCES.ITEMS}/${itemId}`
      );

      if (response) {
        dispatch(setFetchMessage(response));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
      dispatch(fetchItemsList());
    }
  };
};

export const saveItemStatus = (itemId, itemStatusPayload) => {
  return async (dispatch, getState) => {
    dispatch(setItemLoading());

    try {
      const response = await api.put(
        `${API_RESOURCES.ITEMS}/${itemId}/item-status`,
        { status: itemStatusPayload }
      );
      if (response.type === SUCCESS_STATUS) {
        const { categoryReducer } = getState();
        const { selected: selectedCategory } = categoryReducer;

        const searchQueryParams = queryString.parse(window.location.search);

        if (selectedCategory) {
          dispatch(
            fetchItemsList({
              category: selectedCategory._id,
              ...searchQueryParams,
            })
          );
        } else {
          dispatch(fetchItemsList(searchQueryParams));
        }
      }
      if (response) {
        dispatch(setFetchMessage(response));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
    }
  };
};

export const saveItemCategory = (itemId, ItemCategoryPayload) => {
  return async (dispatch, getState) => {
    dispatch(setItemLoading());

    try {
      const response = await api.put(
        `${API_RESOURCES.ITEMS}/${itemId}/item-category`,
        { category: ItemCategoryPayload }
      );
      if (response.type === SUCCESS_STATUS) {
        const { categoryReducer } = getState();
        const { selected: selectedCategory } = categoryReducer;

        const searchQueryParams = queryString.parse(window.location.search);

        if (selectedCategory) {
          dispatch(
            fetchItemsList({
              category: selectedCategory._id,
              ...searchQueryParams,
            })
          );
        } else {
          dispatch(fetchItemsList(searchQueryParams));
        }
      }
      if (response) {
        dispatch(setFetchMessage(response));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
    }
  };
};

/**
 * Action to set historic movements list in store
 *
 * @param listPayload
 * @returns {{payload: {listPayload: *}, type: string}}
 */
const setItemHistoryList = (listPayload) => ({
  type: SET_ITEM_HISTORY_LIST,
  payload: {
    listPayload,
  },
});

/**
 * Action to fetch item history
 *
 * @param itemId
 * @returns {function(*): Promise<void>}
 */
export const fetchItemHistory = (itemId) => {
  return async (dispatch) => {
    dispatch(setItemLoading());

    try {
      const response = await api.get(
        `${API_RESOURCES.ITEMS}/${itemId}/${API_RESOURCES.ITEM_HISTORY}`
      );

      if (response) {
        dispatch(setItemHistoryList(response.data));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetItemLoading());
    }
  };
};

const setImageUploading = () => ({
  type: SET_IMAGE_UPLOADING,
});

const unsetImageUploading = () => ({
  type: UNSET_IMAGE_UPLOADING,
});

/**
 * Image update upload
 * @param {*} imageBlob
 * @param {*} fileName
 * @param {*} orderIndex
 * @returns
 */
export const updateItemImage = (imageBlob, fileName, orderIndex) => {
  return async (dispatch, getState) => {
    dispatch(unsetImageUploading());

    try {
      const response = await uploadFileS3(imageBlob, fileName);

      if (response && response.data) {
        dispatch(
          setFetchMessage({
            ...response,
            message: "Picture was updated!",
            severity: "success",
          })
        );

        const { data } = response;
        const { selected: selectedItem } = getState().itemReducer;

        const newPicturesArray = selectedItem.pictures;
        newPicturesArray[orderIndex] = { file: data.fileName };

        dispatch(
          setSelectedItem({ ...selectedItem, pictures: newPicturesArray })
        );
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(setImageUploading());
    }
  };
};
