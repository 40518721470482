import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";

import "./styles.scss";

const PaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const renderPaginationIcon = (icon, disabled) => (
    <Icon
      name={icon}
      classnames={classnames({
        "pagination-actions-icon": true,
        "pagination-actions-icon--is-disabled": disabled,
      })}
    />
  );

  return (
    <div className={"pagination-actions"}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {renderPaginationIcon("chevron-double-left", page===0)}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {renderPaginationIcon("chevron-left", page===0)}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {renderPaginationIcon("chevron-right", page >= Math.ceil(count / rowsPerPage) - 1)}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {renderPaginationIcon("chevron-double-right", page >= Math.ceil(count / rowsPerPage) - 1)}
      </IconButton>
    </div>
  );
};

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default PaginationActions;
