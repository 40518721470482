import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Grid } from "@material-ui/core";

import { useWindowSize } from "lib/hooks/dimensions";

import "./styles.scss";

const ScreenLayout = ({
  children,
  type = "regular",
  headerComponents,
  contentContainerStyle,
}) => {
  const size = useWindowSize();

  return (
    <div
      className={classnames({
        "screen-layout-regular": type === "regular",
        "screen-layout-table": type === "table",
        "screen-layout-clean": type === "clean",
      })}
      style={type === "clean" ? { height: size.height } : {}}
    >
      {type !== "clean" && (
        <>
          <Grid
            container
            className={"screen-layout-header"}
            spacing={headerComponents.length > 1 ? 2 : 0}
          >
            {headerComponents.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </Grid>
          <div
            className={"screen-layout-content"}
            style={contentContainerStyle || {}}
          >
            {children}
          </div>
        </>
      )}
      {type === "clean" && children}
    </div>
  );
};

ScreenLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  table: PropTypes.oneOf(["table", "regular"]),
  headerComponents: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.array,
  ]),
};

export default ScreenLayout;
