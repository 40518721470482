import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getFile } from "lib/files/operations";

import defaultImage from "assets/img/default-image.jpg";

const CategoryTableImage = ({ item }) => {
  const [fetchedPicture, setFetchedPicture] = useState(defaultImage);

  useEffect(() => {
    async function fetchImage() {
      const image = await getFile(item.pictures[0].file);
      image.type === "LOCAL"
        ? setFetchedPicture(URL.createObjectURL(image.file))
        : setFetchedPicture(image.file);
    }

    if (item.pictures.length) {
      fetchImage();
    }
  }, [item.pictures]);

  return (
    <img
      src={fetchedPicture}
      alt={item.name ? item.name : ""}
      className={"category-table-thumbnail"}
    />
  );
};

CategoryTableImage.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CategoryTableImage;
