import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import containersHeaderComponents from "screens/Containers/components/containersHeaderComponents";

import ScreenLayout from "components/ScreenLayout";
import ContainersTable from "screens/Containers/components/ContainersTable";

import { fetchContainersTypes } from "store/container/actions";
import { fetchWarehouses } from "store/warehouse/actions";

import "./styles.scss";

const Containers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContainersTypes());
    dispatch(fetchWarehouses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenLayout headerComponents={containersHeaderComponents}>
      <ContainersTable />
    </ScreenLayout>
  );
};

export default Containers;
