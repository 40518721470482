import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_SUCCESS,
  ERROR_STATUS,
  ERROR_STATUS_NOT_FOUND,
  SUCCESS_STATUS,
} from "components/MessageService/constants";

import { baseUrl, headers } from "lib/api/constants";

import storeConfig from "store";

function getResponseType(status) {
  switch (status) {
    case 500:
    default:
      return {
        type: ERROR_STATUS,
        severity: ALERT_SEVERITY_ERROR,
      };
    case 400:
      return {
        type: ERROR_STATUS,
        severity: ALERT_SEVERITY_ERROR,
      };
    case 404:
      return {
        type: ERROR_STATUS_NOT_FOUND,
        severity: ALERT_SEVERITY_ERROR,
      };
    case 200:
      return {
        type: SUCCESS_STATUS,
        severity: ALERT_SEVERITY_SUCCESS,
      };
  }
}

function handleError(error) {
  throw Object.assign({
    message: error.message,
    code: 500,
    ...getResponseType(500),
  });
}

async function handleResponse(response) {
  const jsonResponse = await response.json();
  const { data, message, status } = jsonResponse;

  return {
    data,
    message,
    code: status,
    ...getResponseType(response.status),
  };
}

async function handleBlobResponse(response) {
  return response.blob();
}

function formatQueryParams(queryParams) {
  const stringQueryParams = new URLSearchParams(queryParams).toString();

  let params = "";

  if (stringQueryParams) params = `?${stringQueryParams}`;

  return params;
}

function get(resource, queryParams = {}, responseType = "json") {
  return fetch(`${baseUrl}/${resource}${formatQueryParams(queryParams)}`, {
    method: "GET",
    headers,
  })
    .then(responseType === "blob" ? handleBlobResponse : handleResponse)
    .catch(handleError);
}

/**
 * Receives data and insert current user data in payload for PUT and POST methods
 *
 * @param data
 * @param withUser
 * @returns {function(*, *=): Promise<Object>}
 */
function parseRequestBody(data, withUser = true) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      try {
        storeConfig().store.subscribe(() => {
          let payload = data;

          if (withUser) {
            payload = {
              ...payload,
              currentUser: getState().authReducer.authObject,
            };
          }
          resolve(payload);
        });
      } catch (e) {
        reject(e);
      }
    });
  };
}

async function sendData(resource, data, edit = false, withUser = true) {
  const dispatch = storeConfig().store.dispatch;

  return fetch(`${baseUrl}/${resource}`, {
    method: edit ? "PUT" : "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(await dispatch(parseRequestBody(data, withUser))),
  })
    .then(handleResponse)
    .catch(handleError);
}

function post(resource, data, withUser = true) {
  return sendData(resource, data, false, withUser);
}

function put(resource, data) {
  return sendData(resource, data, true);
}

function deleteMethodItem(resource, data) {
  return fetch(`${baseUrl}/${resource}`, {
    method: "DELETE",
    headers,
    body: JSON.stringify(parseRequestBody({})),
  })
    .then(handleResponse)
    .catch(handleError);
}

function deleteMethod(resource, data) {
  return fetch(`${baseUrl}/${resource}${formatQueryParams(data)}`, {
    method: "DELETE",
    headers,
    body: JSON.stringify(parseRequestBody({})),
  })
    .then(handleResponse)
    .catch(handleError);
}

export { get, post, put, deleteMethod, deleteMethodItem };
