import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { Grid, CircularProgress } from "@material-ui/core";

import defaultImage from "assets/img/default-image.jpg";

import EditImageModal from "components/EditImageModal";

import { getFiles } from "lib/files/operations";

import "./styles.scss";
import { useSelector } from "react-redux";

const ImagePreviewList = ({ pictures, title }) => {
  const [fetchedPictures, setFetchedPictures] = useState([]);
  const [isFetchingPictures, setIsFetchingPictures] = useState(true);
  const [selectedPicture, setSelectedPicture] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImageType, setSelectedImageType] = useState();

  const { isUploaded } = useSelector(({ itemReducer }) => itemReducer);

  const createObjectUrlIfNeeded = (fetchedFile) => {
    return fetchedFile
      ? fetchedFile.type === "LOCAL"
        ? URL.createObjectURL(fetchedFile.file)
        : fetchedFile.file
      : defaultImage;
  };

  useEffect(() => {
    async function getImages() {
      const fetched = await getFiles(pictures);

      setIsFetchingPictures(false);
      setFetchedPictures(fetched);
      setSelectedPicture(createObjectUrlIfNeeded(fetched[selectedImageIndex]));
      setSelectedImageType(fetched[selectedImageIndex]?.type || "");
    }

    if (isUploaded) {
      getImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploaded]);

  const markImageAsSelected = (image, index) => {
    setSelectedImageIndex(index);
    setSelectedImageType(image.type);
    setSelectedPicture(createObjectUrlIfNeeded(image));
  };

  if (isFetchingPictures) {
    return <CircularProgress color={"secondary"} />;
  }

  return fetchedPictures && fetchedPictures.length ? (
    <div>
      <div>
        {selectedPicture && (
          <EditImageModal
            picture={selectedPicture}
            title={title}
            className="image-preview-list-image image-preview-list-image--main"
            selectedImageIndex={selectedImageIndex}
            type={selectedImageType}
          />
        )}
      </div>
      <Grid container spacing={2}>
        {fetchedPictures.map((picture, index) => (
          <Grid
            key={index}
            item
            className={"image-preview-list-container"}
            onClick={() => markImageAsSelected(picture, index)}
          >
            <img
              src={createObjectUrlIfNeeded(picture)}
              alt={index}
              className={"image-preview-list-image"}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  ) : (
    <Grid container spacing={2}>
      <img
        alt={"default"}
        src={defaultImage}
        className={classnames({
          "image-preview-list-image--main": true,
        })}
      />
    </Grid>
  );
};

export default ImagePreviewList;
