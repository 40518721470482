import { SET_ROLES_LIST, SET_USERS_LIST } from "store/user/types";

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USERS_LIST:
      return {
        ...state,
        list: payload.usersList,
      };
    case SET_ROLES_LIST:
      return {
        ...state,
        rolesList: payload.rolesList,
      };
    default:
      return state;
  }
};

const initialState = {
  list: [],
  rolesList: [],
  selected: null,
};

export default userReducer;
