import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";
import { formatDateForTable } from "lib/date/format";

import './ItemListings.scss';

const ItemListing = ({ listing, listedMessage, notListedMessage }) => {

  return listing && listing.value ? (
    <div>
      <Typography className={"listing-status listing-description"}>
        {listedMessage}
      </Typography>
      <Typography className={'listing-description'}>
        Listing date: {formatDateForTable(listing.date, "-")}
      </Typography>
      <Typography className={'listing-link'}>
        <a href={listing.link} target={"_blank"} rel={"noopener noreferrer"}>
          {listing.link}
        </a>
      </Typography>
    </div>
  ) : (
    <Typography className={"listing-status listing-description"}>
      {notListedMessage}
    </Typography>
  );
};

ItemListing.propTypes = {
  notListedMessage: PropTypes.string.isRequired,
  listing: PropTypes.object,
};

export default ItemListing;
