import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";

import Icon from "components/Icon";

const TableItemListing = ({ item }) => {
  const castStringLink = (link) => {
    if (link.startsWith("http")) {
      return link;
    }

    return `http://${link}`;
  };

  const onListingLinkClick = (link) => {
    window.open(castStringLink(link), '_blank');
  };

  return (
    <Grid container alignItems={"center"}>
      {item?.listedOnWebsite?.value && (
        <Grid xs={4} item className={"table-item-listing__link-item"}>
          <Icon
            name={"globe"}
            fontSize={20}
            onClick={() => onListingLinkClick(item.listedOnWebsite.link)}
          />
        </Grid>
      )}
      {item?.listedOnEbay?.value && (
        <Grid xs={4} item className={"table-item-listing__link-item"}>
          <Icon
            name={"ebay"}
            brand
            fontSize={25}
            onClick={() => onListingLinkClick(item.listedOnEbay.link)}
          />
        </Grid>
      )}
      {item?.listedOnOutsideRepSite?.value && (
        <Grid xs={4} item className={"table-item-listing__link-item"}>
          <Icon
            name={"user-tie"}
            fontSize={20}
            onClick={() => onListingLinkClick(item.listedOnOutsideRepSite.link)}
          />
        </Grid>
      )}
    </Grid>
  );
};

TableItemListing.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableItemListing;
