import {
  SET_CONTAINER_TYPES_LIST,
  SET_CONTAINERS_FETCHING_STATUS,
  SET_CONTAINERS_LIST,
  SET_CONTAINERS_LIST_FORM,
  SET_DEFAULT_COUNTERS,
  SET_SELECTED_CONTAINER, UNSET_CONTAINERS_AUTOCOMPLETE_LIST,
  UNSET_CONTAINERS_FETCHING_STATUS,
  UNSET_SELECTED_CONTAINER,
  UNSET_DEFAULT_COUNTERS,
} from "store/container/types";

const containerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DEFAULT_COUNTERS:
      return {
        ...state,
        defaultCounters: payload.defaultCounters,
      };
    case SET_SELECTED_CONTAINER:
      return {
        ...state,
        selected: payload.selected,
      };
    case UNSET_SELECTED_CONTAINER:
      return {
        ...state,
        selected: null,
      };
    case SET_CONTAINERS_LIST:
      return {
        ...state,
        list: payload.data.rows,
        count: payload.data.total,
        page: payload.data.currentPage,
        isFetching: false,
      };
    case SET_CONTAINERS_FETCHING_STATUS:
      return {
        ...state,
        isFetching: true,
      };
    case SET_CONTAINER_TYPES_LIST:
      return {
        ...state,
        typesList: payload.typesList,
      };
    case UNSET_CONTAINERS_FETCHING_STATUS:
      return {
        ...state,
        isFetching: false,
      };
    case SET_CONTAINERS_LIST_FORM:
      return {
        ...state,
        listForm: payload.listForm,
      };
    case UNSET_CONTAINERS_AUTOCOMPLETE_LIST:
      return {
        ...state,
        listForm: []
      }
    case UNSET_DEFAULT_COUNTERS:
        return {
          ...state,
          defaultCounters: null,
        }
    default:
      return state;
  }
};

const initialState = {
  defaultCounters: null,
  selected: null,
  list: [],
  count: 0,
  page: 0,
  isFetching: false,
  typesList: [],
  listForm: [],
};

export default containerReducer;
