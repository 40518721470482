import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import ScreenLayout from "components/ScreenLayout";
import ItemListFilters from "screens/InventoryItems/components/ItemListFilters";
import ItemsTable from "screens/InventoryItems/components/ItemsTable";
import categoryItemsHeaderComponents from "screens/InventoryItems/components/productItemsHeaderComponents";

import {
  fetchSingleCategory,
  unsetSelectedCategory,
} from "store/category/actions";
import { unsetSelectedItem } from "store/item/actions";

import { ERROR_STATUS_NOT_FOUND } from "components/MessageService/constants";

import "./styles.scss";

const InventoryItems = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);

  const { selected: selectedCategory } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );
  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  useEffect(() => {
    dispatch(unsetSelectedItem());

    if (params.categoryId) {
      dispatch(fetchSingleCategory(params.categoryId));
    } else {
      dispatch(unsetSelectedCategory());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Basic handling for not existing routes.
  useEffect(() => {
    const handleNavigation = () => {
      if (messagePayload) {
        if (
          params.categoryId &&
          messagePayload.type === ERROR_STATUS_NOT_FOUND
        ) {
          history.push("/category/items");
        }
      }
    };

    handleNavigation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload, params]);

  return (
    <ScreenLayout
      headerComponents={categoryItemsHeaderComponents(selectedCategory)}
      contentContainerStyle={{ marginTop: 0 }}
    >
      <ItemListFilters setColumns={setColumns} columns={columns} />
      <ItemsTable columns={columns} />
    </ScreenLayout>
  );
};

export default InventoryItems;
