import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "@material-ui/core";

const TooltipWrapper = ({ children, messageToDisplay }) => {
  return (
    <div>
      <Tooltip title={messageToDisplay}>{children}</Tooltip>
    </div>
  );
};

TooltipWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  messageToDisplay: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default TooltipWrapper;
