import React from "react";
import PropTypes from "prop-types";

import { CircularProgress, Grid } from "@material-ui/core";

import './styles.scss';

const GridList = ({
  list,
  ItemComponent,
  noItemsMessage,
  keyAccessor = "_id",
  gridItemProps,
  loading,
  ...props
}) => {
  return (
    <Grid container {...props}>
      <Grid xs={12} item className={'grid-list__no-content'}>
        {loading ? (
          <CircularProgress color={"secondary"} />
        ) : (
          list.length === 0 &&  noItemsMessage
        )}
      </Grid>
      {!loading && !!list.length &&
        list.map((item) => (
          <Grid
            item
            key={item[keyAccessor]}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            {...gridItemProps}
          >
            <ItemComponent item={item} />
          </Grid>
        ))}
    </Grid>
  );
};

GridList.propTypes = {
  list: PropTypes.array.isRequired,
  ItemComponent: PropTypes.func.isRequired,
  noItemsMessage: PropTypes.object,
  keyAccessor: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

export default GridList;
