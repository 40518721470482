import React, { useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import HeaderToolbar from "components/HeaderToolbar";
import MessageService from "components/MessageService";
import AuthRoute from "routes/components/AuthRoute";

import appRoutes from "routes";
import authRoutes from "routes/auth";

import { setPreviousLocation } from "store/auth/actions";

import "./App.scss";

function App(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { previousLocation } = useSelector(({ authReducer }) => authReducer);

  const { isAuthenticated } = useSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    if (location.pathname !== "/login") {
      dispatch(setPreviousLocation(location));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    } else {
      if (
        (previousLocation && previousLocation === "/login") ||
        location.pathname === "/login"
      ) {
        history.push("/inventory/items");
      } else {
        history.push(location.pathname);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const renderRoute = ({ name, path, component, routeProps }) => (
    <Route
      {...routeProps}
      key={name}
      path={path}
      component={component}
      exact={true}
    />
  );

  return (
    <>
      {isAuthenticated ? (
        <Grid container className={"app-grid-container"}>
          <HeaderToolbar />
          <Grid item xs={12}>
            <Switch>
              {appRoutes.map((route) => (
                <AuthRoute key={route.name} {...route} />
              ))}
            </Switch>
          </Grid>
        </Grid>
      ) : (
        <Switch>{authRoutes.map((route) => renderRoute(route))}</Switch>
      )}
      <MessageService />
    </>
  );
}

export default App;
