import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import SelectWrapper from "components/SelectWrapper";

const ListFilter = ({
  filterKey,
  unselectedLabel,
  selectedLabel,
  options,
  labelAccessor = "name",
  valueAccessor = "_id",
  multiple = false,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);

  const [filterValue, setFilterValue] = useState(
    multiple
      ? (searchQuery?.[filterKey] || "-1").split(",")
      : searchQuery?.[filterKey]
  );

  const onSelectFilterValue = (selectedValue) => {
    const searchParams = new URLSearchParams(searchQuery);

    searchParams.delete(filterKey);

    if (multiple) {
      if (selectedValue[selectedValue.length - 1] === "-1") {
        setFilterValue(null);
      } else {
        searchParams.append(filterKey, selectedValue);

        setFilterValue(selectedValue.filter(item => item !== '-1'));
      }
    } else {
      if (selectedValue !== "-1") {
        searchParams.append(filterKey, selectedValue);
      }

      setFilterValue(selectedValue);
    }

    history.push({
      path: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <SelectWrapper
      defaultValue={
        filterValue || filterValue?.length
          ? filterValue
          : multiple
          ? ["-1"]
          : "-1"
      }
      customValue={filterValue || filterValue?.length
        ? filterValue
        : multiple
          ? ["-1"]
          : "-1"}
      label={filterValue ? selectedLabel : unselectedLabel}
      onChange={onSelectFilterValue}
      margin="dense"
      fullWidth
      options={options}
      labelAccessor={labelAccessor}
      valueAccessor={valueAccessor}
      multiple={multiple}
      {...props}
    />
  );
};

ListFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  unselectedLabel: PropTypes.string.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  labelAccessor: PropTypes.string,
  valueAccessor: PropTypes.string,
};

export default ListFilter;
