import React from "react";

import DateRangeFilter from "components/DateRangeFilter";

const AcquisitionDateFilter = () => {
  return (
    <DateRangeFilter
      startDateFilterKey={'doaStart'}
      endDateFilterKey={'doaEnd'}
      label={"Acquisition date"}
      max={new Date()}
    />
  );
};

export default AcquisitionDateFilter;
