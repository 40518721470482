function removeByIndex(array, indexToDelete) {
  return array.splice(indexToDelete);
}

function removeByValue(array, valueToDelete) {
  return array.filter((item) => item !== valueToDelete);
}

function getFlatValues(array) {
  let object = {};

  array.map((element) => {
    object = {
      ...object,
      ...element,
    };
    return true;
  });

  return object;
}

function parseObjectToArray(object) {
  let array = [];

  Object.keys(object).map((index) => {
    if (object[index]) {
      array = [...array, index];
    }
    return true;
  });

  return array;
}

export { removeByIndex, removeByValue, getFlatValues, parseObjectToArray};
