import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { fetchSingleOpenSpace } from "store/open-space/actions";

import ItemDetailList from "components/ItemDetailList";
import ContainersList from "screens/WarehouseDetail/components/open-spaces/ContainersList";

const ItemsList = ({ itemId, type = "items" }) => {
  const dispatch = useDispatch();

  const { selected: selectedOpenSpace } = useSelector(
    ({ openSpaceReducer }) => openSpaceReducer
  );

  useEffect(() => {
    if (!selectedOpenSpace) {
      dispatch(fetchSingleOpenSpace(itemId));
    }
  }, [selectedOpenSpace, dispatch, itemId]);

  if (selectedOpenSpace) {
    if (type === "items") {
      return (
        <ItemDetailList
          list={selectedOpenSpace.items}
          itemProps={{ disableGutters: true }}
        />
      );
    } else {
      return <ContainersList list={selectedOpenSpace.containers} />;
    }
  } else {
    return null;
  }
};

ItemsList.propTypes = {
  itemId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default ItemsList;
