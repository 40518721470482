import {
  LOGIN,
  LOGOUT,
  SET_PREVIOUS_LOCATION,
  UNSET_PREVIOUS_LOCATION,
} from "store/auth/types";

import {setFetchMessage, setLoadingStatus} from "store/helper/actions";

import * as api from "lib/api";

import API_RESOURCES from "lib/api/resources";

import {SUCCESS_STATUS} from "components/MessageService/constants";

/**
 * Action to validate email received from OAuth SignIn process
 *
 * @param userPayload
 * @returns {function(*): Promise<void>}
 */
export const login = (userPayload) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      const response = await api.post(API_RESOURCES.LOGIN, {
        email: userPayload.email,
        accessToken: userPayload.token
      }, false);

      dispatch(setFetchMessage(response))

      if (response.type === SUCCESS_STATUS) {
        dispatch(setUserPayload(response.data));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  }
}

/**
 * Sets user object in store
 *
 * @param userPayload
 * @returns {{payload: {userPayload: {role: string, name: string, email: string, token: string}}, type: string}}
 */
export const setUserPayload = (userPayload) => ({
  type: LOGIN,
  payload: {
    userPayload
  },
});

export const logout = () => ({
  type: LOGOUT,
});

export const setPreviousLocation = (previousLocation) => ({
  type: SET_PREVIOUS_LOCATION,
  payload: {
    previousLocation,
  },
});

export const unsetPreviousLocation = () => ({
  type: UNSET_PREVIOUS_LOCATION,
});
