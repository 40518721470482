import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";

import AlertContent from "components/AlertContent";

import { unsetFetchMessage } from "store/helper/actions";

const MessageService = () => {
  const dispatch = useDispatch();

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  const handleClose = () => {
    dispatch(unsetFetchMessage());
  };

  return (
    <Snackbar
      open={!!messagePayload}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      {messagePayload && (
        <AlertContent
          severity={messagePayload.severity}
          variant={"filled"}
        >
          {messagePayload.message}
        </AlertContent>
      )}
    </Snackbar>
  );
};

MessageService.propTypes = {};

export default MessageService;
