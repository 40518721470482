import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";

import "./ItemTitle.scss";

const ItemTitle = ({ prefix, item }) => {
  return (
    <Typography variant={"h1"}>
      {prefix}
      {prefix ? " " : ""}
      <span className={"item-title"}>{item.name}</span> - Model:{" "}
      <span className={"item-model"}>{item.model}</span>
    </Typography>
  );
};

ItemTitle.propTypes = {
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default ItemTitle;
