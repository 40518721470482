import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Button } from "@material-ui/core";

import { addShelf } from "store/warehouse/actions";
import AuthWrapper from "components/AuthWrapper";

const AddLevelButton = ({ rack }) => {
  const dispatch = useDispatch();

  const addLevelToRack = () => {
    dispatch(addShelf({ rack: rack._id }));
  };

  return (
    <AuthWrapper permission={"LOCATIONS.LOCATIONS_UPDATE"}>
      <Button
        className={"location-rack-header-button"}
        onClick={addLevelToRack}
      >
        + Add level
      </Button>
    </AuthWrapper>
  );
};

AddLevelButton.propTypes = {
  rack: PropTypes.object.isRequired,
};

export default AddLevelButton;
