import React from "react";

import IconButton from "@material-ui/core/IconButton";

import LinkContainer from "components/LinkContainer";
import Icon from "components/Icon";

const CloneItemButton = ({ item }) => (
  <LinkContainer
    to={`/inventory/items/clone/${item._id}`}
    Component={(props) => (
      <IconButton {...props}>
        <Icon
          name={"clone"}
          color={"black"}
          classnames={"item-table-action-icon"}
          fontSize={18}
        />
      </IconButton>
    )}
  />
);

export default CloneItemButton;
