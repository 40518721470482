import React  from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import FormSwitch from "components/FormSwitch";
import FormInDialog from "components/FormInDialog";

import userFormValidation from "screens/Users/components/userFormValidation";

const UserModal = ({
  OpenerComponent,
  title,
  submitActionText,
  cancelActionText,
  ...formProps
}) => {
  const { rolesList } = useSelector(({ userReducer }) => userReducer);

  const userModalFields = [
    {
      name: "name",
      component: FormInput,
      props: {
        label: "Name",
        placeholder: "Name",
        fullWidth: true,
        variant: "outlined",
      },
    },
    {
      name: "email",
      component: FormInput,
      props: {
        label: "Email address",
        placeholder: "Email address",
        fullWidth: true,
        variant: "outlined",
      },
    },
    {
      name: "roles",
      component: FormSelect,
      props: {
        options: rolesList,
        labelAccessor: "name",
        valueAccessor: "_id",
        fullWidth: true,
        label: "Role",
        placeholder: "Role",
      },
    },
    {
      name: "active",
      component: FormSwitch,
      props: {
        checked: false,
        label: "Activate user",
      },
    },
  ];

  return (
    <FormInDialog
      validate={userFormValidation}
      formFields={userModalFields}
      submitActionText={submitActionText}
      title={title}
      cancelActionText={cancelActionText}
      OpenerComponent={props => <OpenerComponent {...props} />}
      {...formProps}
    />
  );
};

UserModal.propTypes = {
  OpenerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string.isRequired,
  submitActionText: PropTypes.string.isRequired,
  cancelActionText: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UserModal;
