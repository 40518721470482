import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@material-ui/core";

import ItemDetailTextHeader from "screens/InventoryItemDetail/components/ItemDetailTextHeader";

import {getDetailAttribute} from "lib/object/format";

const ItemDetailHeaderSection = ({selected}) => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <ItemDetailTextHeader
          text={getDetailAttribute(selected, "number")}
          small
        />
      </Grid>
      <Grid item xs={2}>
        <ItemDetailTextHeader
          text={getDetailAttribute(selected, "serialNumber", 'N/A')}
          small
        />
      </Grid>
      <Grid item xs={2}>
        <ItemDetailTextHeader
          text={getDetailAttribute(selected, "location")}
          small
        />
      </Grid>
      <Grid item xs={2}>
        <ItemDetailTextHeader
          text={getDetailAttribute(selected, "yearManufactured")}
          small
        />
      </Grid>
      <Grid item xs={2}>
        <ItemDetailTextHeader
          text={getDetailAttribute(
            selected,
            "dateOfAdquisition",
            "-",
            "date",
            "-"
          )}
          small
        />
      </Grid>
      <Grid item xs={2}>
        <ItemDetailTextHeader
          text={getDetailAttribute(selected, "source")}
          small
        />
      </Grid>
    </Grid>
  );
};

ItemDetailHeaderSection.propTypes = {
  selected: PropTypes.object.isRequired
};

export default ItemDetailHeaderSection;
