import React from "react";
import {useDispatch} from "react-redux";

import {Add as AddIcon} from "@material-ui/icons";
import {Button} from "@material-ui/core";

import CategoryModal from "screens/Categories/components/CategoryModal";

import {saveCategory} from "store/category/actions";

const CategoryCreateButton = () => {
  const dispatch = useDispatch();

  const handleCreateCategory = values => {
    dispatch(saveCategory(values));
  }

  const newCategoryInitialValues = {
    name: "",
    pictures: [],
  }

  return (
    <CategoryModal
      cancelActionText={"Discard"}
      submitActionText={"Save"}
      title={"Add category"}
      onSubmit={handleCreateCategory}
      initialValues={newCategoryInitialValues}
      OpenerComponent={props => <Button
        fullWidth
        color="secondary"
        variant="contained"
        startIcon={<AddIcon />}
        {...props}
      >
        Add category
      </Button>}
    />
  );
};

CategoryCreateButton.propTypes = {};

export default CategoryCreateButton;
