const ERROR_STATUS = "ERROR_STATUS";
const ERROR_STATUS_NOT_FOUND = "ERROR_STATUS_NOT_FOUND";
const SUCCESS_STATUS = "SUCCESS_STATUS";
const WARNING_STATUS = "WARNING_STATUS";
const INFO_STATUS = "INFO_STATUS";

const ALERT_SEVERITY_ERROR = "error";
const ALERT_SEVERITY_SUCCESS = "success";
const ALERT_SEVERITY_WARNING = "warning";
const ALERT_SEVERITY_INFO = "info";

export {
  ERROR_STATUS,
  SUCCESS_STATUS,
  WARNING_STATUS,
  INFO_STATUS,
  ERROR_STATUS_NOT_FOUND,
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARNING,
};
