import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormAutocomplete from "components/FormAutocomplete";

import { fetchContainersForAutocomplete } from "store/container/actions";

const ContainerAutocomplete = ({
  label,
  field,
  requiredMessage,
  required,
  onSelectExternal,
  ...props
}) => {
  const dispatch = useDispatch();
  const [searchContainer, setSearchContainer] = useState("");

  const { listForm } = useSelector(({ containerReducer }) => containerReducer);

  useEffect(() => {
    function searchContainerAction() {
      if (searchContainer) {
        dispatch(
          fetchContainersForAutocomplete({ search: searchContainer || "" })
        );
      }
    }

    const timeout = setTimeout(searchContainerAction, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, searchContainer]);

  return (
    <FormAutocomplete
      required={required}
      requiredMessage={requiredMessage}
      onSearchExternal={setSearchContainer}
      onSelectExternal={onSelectExternal}
      options={listForm}
      label={label}
      field={field}
      optionLabelSelector={"name"}
      dropdownStyle={{ zIndex: 2000 }}
      style={{zIndex: 5}}
      {...props}
    />
  );
};

export default ContainerAutocomplete;
