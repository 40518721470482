import queryString from "query-string";

import {
  setFetchMessage,
  setLoadingStatus,
  unsetLoadingStatus,
} from "store/helper/actions";

import * as api from "lib/api";
import API_RESOURCES from "lib/api/resources";

import { SUCCESS_STATUS } from "components/MessageService/constants";
import {
  SET_DEFAULT_COUNTERS,
  SET_SELECTED_CONTAINER,
  UNSET_SELECTED_CONTAINER,
  SET_CONTAINERS_LIST,
  SET_CONTAINERS_FETCHING_STATUS,
  UNSET_CONTAINERS_FETCHING_STATUS,
  SET_CONTAINER_TYPES_LIST,
  SET_CONTAINERS_LIST_FORM,
  UNSET_CONTAINERS_AUTOCOMPLETE_LIST,
  UNSET_DEFAULT_COUNTERS,
} from "store/container/types";

export const setDefaultCounters = (defaultCounters) => ({
  type: SET_DEFAULT_COUNTERS,
  payload: {
    defaultCounters,
  },
});

/**
 * Action to get initial values for initial container numbers
 *
 * @returns {function(*): Promise<void>}
 */
export const fetchContainersCounters = (containerType) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingStatus());

    try {
      const { containerReducer } = getState();
      const { typesList } = containerReducer;

      const palletType = typesList.find(type => type.name.toLowerCase() === 'pallet');
      const rollingCartType = typesList.find(type => type.name.toLowerCase() === 'rolling cart');

      let counters = [];
      
      // Get all counters
      const responseCounters = await api.get(API_RESOURCES.GET_COUNTERS);

      if (responseCounters && responseCounters.data) {
        const palletSeq = responseCounters.data.find(counter => counter._id === palletType._id);

        counters = [
          ...counters,
          palletSeq,
        ];
      }

      const responseRollingCarts = await api.get(API_RESOURCES.GET_ROLLING_CART_COUNTER);

      if (responseRollingCarts && responseRollingCarts.data) {
        counters = [
          ...counters,
          {
            _id: rollingCartType._id,
            seq: responseRollingCarts.data.nextLetter,
          }
        ];
        
        dispatch(setDefaultCounters(counters));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

/**
 * Save container action
 * resourceType will be "pallet or rollingcart" object id
 *
 * @param containerPayload
 * @param containerId
 * @returns {function(*): Promise<void>}
 */
export const saveContainer = (containerPayload, containerId) => {
  return async (dispatch) => {
    try {
      let response;

      if (containerId) {
        response = await api.put(
          `${API_RESOURCES.CONTAINERS}/${containerId}`,
          containerPayload
        );
      } else {
        response = await api.post(API_RESOURCES.CONTAINERS, containerPayload);
      }

      if (response) {
        dispatch(setFetchMessage(response));

        if (response.type === SUCCESS_STATUS) {
          const searchQueryParams = queryString.parse(window.location.search);

          if (!containerId) {
            dispatch(fetchContainers(searchQueryParams));
          }
        }
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

/**
 * Save / update container location
 * @param containerLocationPayload
 * @param containerId
 * @returns {function(*): Promise<*>}
 */
export const saveContainerLocation = (
  containerLocationPayload,
  containerId
) => {
  return async (dispatch) => {
    try {
      let containerLocationData;
      let containerTypeUrl;

      // Determines payload and url to be used to make PUT request
      if (containerLocationPayload.type === "shelf") {
        containerLocationData = {
          placeHolder: containerLocationPayload.shelf,
        };

        containerTypeUrl = API_RESOURCES.CONTAINER_LOCATION_SHELF;
      } else {
        containerLocationData = {
          placeHolder: containerLocationPayload.row,
        };

        containerTypeUrl = API_RESOURCES.CONTAINER_LOCATION_OS;
      }

      const response = await api.put(
        `${API_RESOURCES.CONTAINERS}/${containerId}/${containerTypeUrl}`,
        containerLocationData
      );

      if (response) {
        dispatch(setFetchMessage(response));

        if (response.type === SUCCESS_STATUS) {
          const searchQueryParams = queryString.parse(window.location.search);

          dispatch(fetchContainers(searchQueryParams));
        }
      }
    } catch (error) {
      dispatch(setFetchMessage(error));
    }
  };
};

export const setSelectedContainer = (selected) => ({
  type: SET_SELECTED_CONTAINER,
  payload: {
    selected,
  },
});

export const unsetSelectedContainer = () => ({
  type: UNSET_SELECTED_CONTAINER,
});

const setContainersList = (data) => ({
  type: SET_CONTAINERS_LIST,
  payload: {
    data,
  },
});

export const setFetchingContainersStatus = () => ({
  type: SET_CONTAINERS_FETCHING_STATUS,
});

export const unsetFetchingContainersStatus = () => ({
  type: UNSET_CONTAINERS_FETCHING_STATUS,
});

/**
 * Fetch containers action
 *
 * @param query
 * @returns {function(*): Promise<void>}
 */
export const fetchContainers = (query) => {
  return async (dispatch) => {
    dispatch(setFetchingContainersStatus());

    try {
      const response = await api.get(API_RESOURCES.CONTAINERS, {
        ...query,
        limit: 20,
      });

      if (response.data) {
        dispatch(setContainersList(response.data));
      }
    } catch (e) {
      console.log(e);
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetFetchingContainersStatus());
    }
  };
};

/**
 * Fetch one container action
 *
 * @param containerId
 */
export const fetchContainer = (containerId) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      const response = await api.get(
        `${API_RESOURCES.CONTAINERS}/${containerId}`
      );

      if (response.data) {
        dispatch(setSelectedContainer(response.data));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

/**
 * Container types list setter
 *
 * @param typesList
 * @returns {{payload: {typesList: *}, type: string}}
 */
const setContainerTypesList = (typesList) => ({
  type: SET_CONTAINER_TYPES_LIST,
  payload: {
    typesList,
  },
});

/**
 * Fetch action to get containers types
 *
 * @returns {function(*): Promise<*>}
 */
export const fetchContainersTypes = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.CONTAINER_TYPES);

      if (response && response.data) {
        dispatch(setContainerTypesList(response.data.rows));
      }
    } catch (e) {
      setFetchMessage(e);
      console.log(e);
    }
  };
};

const setContainersListForForm = (listForm) => ({
  type: SET_CONTAINERS_LIST_FORM,
  payload: {
    listForm,
  },
});

/**
 * Fetch containers action
 *
 * @param query
 * @returns {function(*): Promise<void>}
 */
export const fetchContainersForAutocomplete = (query) => {
  return async (dispatch) => {
    dispatch(setFetchingContainersStatus());

    try {
      const response = await api.get(API_RESOURCES.CONTAINERS, {
        ...query,
        limit: 20,
      });

      if (response.data) {
        dispatch(
          setContainersListForForm(
            response.data.rows.map((item) => ({
              _id: item._id,
              name: `${item.type.name} ${item.name}`,
              isFull: item.isFull
            }))
          )
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(setFetchMessage(e));
      dispatch(unsetFetchingContainersStatus());
    }
  };
};

export const unsetContainersAutocompleteList = () => ({
  type: UNSET_CONTAINERS_AUTOCOMPLETE_LIST
});

export const unsetDefaultCounters = () => ({
  type: UNSET_DEFAULT_COUNTERS
});