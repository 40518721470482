import React from "react";
import PropTypes from "prop-types";

import DatePickerWrapper from "components/DatePickerWrapper";
import FormFieldWrapper from "components/FormFieldWrapper";

import "./styles.scss";

const FormDate = ({
  field,
  label,
  form,
  dependsOnValue = "",
  attributeToChange = "",
  habilitateAttributeValues = [],
  ...props
}) => {
  const { setFieldValue, values } = form;

  const handleDateChange = (date) => {
    setFieldValue(field.name, date);
  };

  // Allow inclusion of attributes as props depending on certain form value
  const conditions = dependsOnValue
    ? {
        [attributeToChange]:
          habilitateAttributeValues.find(
            (habilitatedValue) => habilitatedValue === values[dependsOnValue]
          ) !== undefined,
      }
    : {};

  return (
    <div className={"form-date-input"}>
      <FormFieldWrapper name={field.name} label={label}>
        <DatePickerWrapper
          label={label}
          onChange={handleDateChange}
          className={"date-picker-input"}
          {...conditions}
          {...props}
        />
      </FormFieldWrapper>
    </div>
  );
};

FormDate.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default FormDate;
