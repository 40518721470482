import Login from "screens/Login";

const authRoutes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    exact: true,
  },
]

export default authRoutes;