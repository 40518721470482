import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {
  Button,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import ModalWithOpener from "components/ModalWithOpener";
import Icon from "components/Icon";
import ImageWithZoom from "components/ImageWithZoom";

import { getFiles } from "lib/files/operations";

import "./styles.scss";

const ModalGallery = ({ pictures, title, noItemsMessage }) => {
  const [openModal, setOpenModal] = useState(false);
  const [fetchedPictures, setFetchedPictures] = useState([]);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [isFetchingPictures, setIsFetchingPictures] = useState(true);

  useEffect(() => {
    async function fetchPictures() {
      const files = await getFiles(pictures);

      setFetchedPictures(files.map((file) => file.type === 'LOCAL' ? URL.createObjectURL(file.file): file.file));
      setIsFetchingPictures(false);
    }

    if (pictures && openModal) {
      fetchPictures();
    }
  }, [pictures, openModal]);

  useEffect(() => {
    if (fetchedPictures.length) {
      setSelectedPicture(fetchedPictures[0]);
    }
  }, [fetchedPictures]);

  return (
    <ModalWithOpener
      onClose={() => {
        setOpenModal(false);
      }}
      fullWidth={!isFetchingPictures}
      maxWidth={"lg"}
      open={openModal}
      OpenerComponent={
        <Button
          size={"small"}
          className={"modal-gallery-opener"}
          onClick={() => {
            setOpenModal(true);
          }}
          variant={"outlined"}
        >
          <Icon name={"image"} fontSize={18} color={"#666666"} />
        </Button>
      }
    >
      {!isFetchingPictures ? (
        <>
          <DialogTitle>{`${title} pictures`}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {fetchedPictures && fetchedPictures.length ? (
                <Grid item
                  xs={12}
                  className={"modal-gallery-image--selected-container"}>
                    <ImageWithZoom picture={selectedPicture} title={title} />
                </Grid>
              ) : (
                noItemsMessage
              )}
              <Grid item xs={12}>
                <ul className={"modal-gallery-pictures"}>
                  {fetchedPictures.map((picture, index) => (
                    <li
                      key={index}
                      className={classnames({
                        "modal-gallery-pictures__item": true,
                        "modal-gallery-pictures__item--selected":
                          picture === selectedPicture,
                      })}
                    >
                      <img
                        className={classnames({
                          "modal-gallery-pictures__image": true,
                          "modal-gallery-pictures__image--selected":
                            selectedPicture === picture,
                        })}
                        src={picture}
                        alt={index}
                        onClick={() => {
                          setSelectedPicture(picture);
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <CircularProgress color={"secondary"} />
          </Grid>
        </Grid>
      )}
    </ModalWithOpener>
  );
};

ModalGallery.propTypes = {
  title: PropTypes.string.isRequired,
  pictures: PropTypes.array.isRequired,
};

export default ModalGallery;
