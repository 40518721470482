import { combineReducers } from "redux";

import helperReducer from "store/helper/reducer";
import warehouseReducer from "store/warehouse/reducer";
import openSpaceReducer from "store/open-space/reducer";
import containerReducer from "store/container/reducer";
import categoryReducer from "store/category/reducer";
import itemReducer from "store/item/reducer";
import authReducer from "store/auth/reducer";
import userReducer from "store/user/reducer";

const rootReducer = combineReducers({
  authReducer,
  userReducer,
  helperReducer,
  warehouseReducer,
  openSpaceReducer,
  containerReducer,
  categoryReducer,
  itemReducer,
});

export default rootReducer;
