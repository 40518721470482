import React from "react";
import ListFilter from "components/ListFilter";

const ListingsFilter = () => {
  const listings = [
    { label: "eBay", value: "ebay" },
    { label: "Website", value: "website" },
    { label: "Outside rep", value: "outsideRep" },
  ];

  return (
    <ListFilter
      options={listings}
      unselectedLabel={"Listings"}
      selectedLabel={"All listings"}
      filterKey={"listings"}
      valueAccessor={"value"}
      labelAccessor={"label"}
      multiple
    />
  );
};


export default ListingsFilter;
