import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import {Typography} from "@material-ui/core";

import AutocompleteWrapper from "components/AutocompleteWrapper";
import Icon from "components/Icon";

const AutocompleteFilter = ({
  filterKey,
  label,
  onSearchExternal,
  options,
  disabled,
  optionValueSelector = "_id",
  optionLabelSelector = "name",
  ...props
}) => {
  const [value, setValue] = useState("");

  const onChange = (value) => {
    setValue(value);

    if (onSearchExternal) onSearchExternal(value);
  };

  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);

  const onSelectFilterValue = (selectedValue) => {
    const searchParams = new URLSearchParams(searchQuery);

    searchParams.delete(filterKey);

    if (selectedValue) {
      searchParams.append(filterKey, selectedValue);
    }

    history.push({
      path: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (!value || !value.length) {
      onSelectFilterValue("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <AutocompleteWrapper
      size={"large"}
      allowClear
      onSearch={onChange}
      onSelect={onSelectFilterValue}
      placeholder={label}
      optionValueSelector={optionValueSelector}
      optionLabelSelector={optionLabelSelector}
      options={options}
      clearIcon={<Icon name={"cancel"} fontSize={20} color={"black"} />}
      suffixIcon={
        <Icon
          name={"dropdown-arrow"}
          type={"solid"}
          fontSize={16}
          color={"rgba(0, 0, 0, 0.54)"}
        />
      }
      notFoundContent={<Typography>No results to display.</Typography>}
      {...props}
    />
  );
};

AutocompleteFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default AutocompleteFilter;
