import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import LocationRowFormSelect from "components/LocationRowFormSelect";
import LocationRackFormSelect from "components/LocationRackFormSelect";
import LocationLevelFormSelect from "components/LocationLevelFormSelect";
import LocationWarehouseFormSelect from "components/LocationWarehouseFormSelect";
import FormInDialog from "components/FormInDialog";

import {
  saveContainerLocation,
  fetchContainer,
  unsetSelectedContainer,
} from "store/container/actions";

import containerLocationValidation from "screens/Containers/components/containerLocationValidation";
import {
  unsetLevelsList,
  unsetRacksList,
  unsetRowsList,
} from "store/warehouse/actions";
import { unsetOpenSpacesList } from "store/open-space/actions";

const EditContainerButton = ({ item }) => {
  const dispatch = useDispatch();

  const { selected } = useSelector(({ containerReducer }) => containerReducer);
  const { rowsList, racksList, levelsList } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );
  const { list: openSpacesList } = useSelector(
    ({ openSpaceReducer }) => openSpaceReducer
  );

  const [clicked, setClicked] = useState(false);

  let editContainerFields = [
    {
      name: "warehouse",
      component: LocationWarehouseFormSelect,
      props: {},
    },
    {
      name: "row",
      component: LocationRowFormSelect,
      props: {},
    },
    {
      name: "rack",
      component: LocationRackFormSelect,
      props: {},
    },
    {
      name: "shelf",
      component: LocationLevelFormSelect,
      props: {},
    },
  ];

  const editContainerLocation = (values) => {
    dispatch(saveContainerLocation(values, selected._id));
  };

  const getContainerLocation = () => {
    setClicked(item._id);
  };

  useEffect(() => {
    if (clicked) {
      dispatch(fetchContainer(clicked));
    }
  }, [item._id, clicked, dispatch]);

  const resetModal = () => {
    dispatch(unsetSelectedContainer());
    dispatch(unsetLevelsList());
    dispatch(unsetRowsList());
    dispatch(unsetRacksList());
    dispatch(unsetOpenSpacesList());
    setClicked(false);
  };

  return (
    <FormInDialog
      actionOnClose={resetModal}
      actionOnOpen={getContainerLocation}
      onSubmit={editContainerLocation}
      initialValues={{
        warehouse: "-1",
        row: "-1",
        rack: "-1",
        shelf: "-1",
        type: "",
      }}
      validate={containerLocationValidation}
      enableReinitialize
      customCondition={
        !!selected ||
        (selected?.shelf
          ? rowsList.length && racksList.length && levelsList.length
          : selected?.openSpace
          ? rowsList.length && openSpacesList.length
          : true)
      }
      OpenerComponent={(props) => (
        <IconButton {...props}>
          <Icon name={"edit"} color={"black"} />
        </IconButton>
      )}
      title={`Edit ${item?.type?.name} #${item?.name} location`}
      submitActionText={"Save location"}
      cancelActionText={"Cancel"}
      formFields={editContainerFields}
    />
  );
};

EditContainerButton.propTypes = {
  // TODO: handle proptypes following container item payload
  item: PropTypes.object.isRequired,
};

export default EditContainerButton;
