import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Grid, Typography} from "@material-ui/core";
import {ArrowDropDown, ArrowRight} from "@material-ui/icons";

/**
 * Component to create a toggle display with a title
 * 
 * @param textIfOpen
 * @param textIfClosed
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const SingleToggleComponent = ({textIfOpen, textIfClosed, children}) => {
  const [show, setShow] = useState(false);

  const toggleItemsDisplay = () => {
    setShow((show) => !show);
  };

  return (
    <Grid
      container
      onClick={toggleItemsDisplay}
      alignItems={"center"}
      justify={'space-between'}
    >
      <Grid item xs={2}>{show ? <ArrowRight /> : <ArrowDropDown />}</Grid>
      <Grid item xs={10}>
        <Typography>
          {show ? (textIfOpen || textIfClosed) : textIfClosed}
        </Typography>
      </Grid>
      {show && children}
    </Grid>
  );
};

SingleToggleComponent.propTypes = {
  textIfOpen: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  textIfClosed: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
};

export default SingleToggleComponent;