import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { useHasPermission } from "lib/hooks/permissions";

const AuthRoute = ({ name, path, component, routeProps, featureKey }) => {
  const { isAuthenticated } = useSelector(({ authReducer }) => authReducer);

  const renderRoute = () => (
    <Route
      {...routeProps}
      key={name}
      path={path}
      component={component}
      exact={true}
    />
  );

  const hasPermission = useHasPermission(featureKey);

  if (!isAuthenticated) {
    return <Redirect to={"/login"} />;
  } else {
    if (hasPermission) {
      return renderRoute();
    } else {
      return <Redirect to={"/inventory/items"} />;
    }
  }
};

AuthRoute.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  routeProps: PropTypes.object,
  private: PropTypes.bool,
};

export default AuthRoute;
