import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import { Grid, IconButton } from "@material-ui/core";

import ChangelogCard from "components/ChangelogCard";
import Icon from "components/Icon";

const ChangelogList = ({ list, deleteAction, statusText = "Update", ...props }) => {
  const {authObject} = useSelector(({authReducer}) => authReducer);

  const deleteFromList = (item) => {
    if (deleteAction) {
      deleteAction(item);
    }
  };

  return list && list.length ? (
    <Grid container spacing={2} {...props}>
      {list.map((change, index) => (
        <Grid item xs={12} key={index}>
          <ChangelogCard
            description={change.comment}
            date={new Date()}
            byUser={change?.user?.name || authObject.name}
            statusText={statusText}
            action={
              !change._id && (
                <IconButton onClick={() => deleteFromList(change, index)}>
                  <Icon name={"cancel"} fontSize={16} />
                </IconButton>
              )
            }
          />
        </Grid>
      ))}
    </Grid>
  ) : null;
};

ChangelogList.propTypes = {
  list: PropTypes.array,
  deleteAction: PropTypes.func,
};

export default ChangelogList;
