import Users from "screens/Users";

const userRoutes = [
  {
    name: "Users",
    path: '/users',
    component: Users,
    exact: true,
    featureKey: "USERS.USERS_READ"
  }
]

export default userRoutes;