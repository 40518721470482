import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import OpenSpaceModal from "screens/WarehouseDetail/components/open-spaces/OpenSpaceModal";

import { saveOpenSpace } from "store/open-space/actions";

const AddOpenSpaceModal = () => {
  const dispatch = useDispatch();

  const {selected: selectedWarehouse} = useSelector(({warehouseReducer}) => warehouseReducer);

  const initialValues = {
    name: "",
    description: "",
    warehouse: selectedWarehouse._id
  };

  const handleCreateOpenSpace = (values) => {
    dispatch(saveOpenSpace(values));
  };

  return (
    <OpenSpaceModal
      initialValues={initialValues}
      cancelActionText={"Cancel"}
      submitActionText={"Save"}
      onSubmit={handleCreateOpenSpace}
      title={`Create open space`}
      OpenerComponent={(props) => (
        <Button
          {...props}
          startIcon={<AddIcon />}
          color={"secondary"}
          variant={"contained"}
        >
          Add open space
        </Button>
      )}
    />
  );
};

export default AddOpenSpaceModal;
