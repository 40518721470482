import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import SearchBar from "components/SearchBar";

const ContainersSearchBar = () => {
  const location = useLocation();

  const searchParams = queryString.parse(location.search);
  const [searchObject, setSearchObject] = useState(searchParams);

  const searchContainers = (payloadQuery) => {
    setSearchObject(payloadQuery);
  };

  return (
    <SearchBar
      timeout={1000}
      defaultValue={searchObject?.name}
      searchKeyAccessor={"name"}
      name={"containerSearch"}
      onChange={searchContainers}
      placeholder={"Search containers"}
      fullWidth
      margin="dense"
    />
  );
};

export default ContainersSearchBar;
