import React from "react";
import { Grid } from "@material-ui/core";
import InnerPageSection from "components/InnerPageSection";
import ConditionsFilter from "screens/InventoryItems/components/ConditionsFilter";
import ListingsFilter from "screens/InventoryItems/components/ListingsFilter";
import StatusFilter from "screens/InventoryItems/components/StatusFilter";
import AcquisitionDateFilter from "screens/InventoryItems/components/AcquisitionDateFilter";
import SetLocationButton from "screens/InventoryItems/components/SetLocationButton";
import CategoryFilter from "screens/InventoryItems/components/CategoryFilter";
import DeleteItem from "screens/InventoryItems/components/DeleteItem";
import ColumnsFilter from "screens/InventoryItems/components/ColumnsFilter";

const ItemListFilters = ({ setColumns, columns }) => {
  return (
    <InnerPageSection>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid xs={2} item>
          <AcquisitionDateFilter />
        </Grid>
        <Grid xs={2} item>
          <ConditionsFilter />
        </Grid>
        <Grid xs={2} item>
          <ListingsFilter />
        </Grid>
        <Grid xs={2} item>
          <StatusFilter />
        </Grid>
        <Grid xs={2} item>
          <CategoryFilter />
        </Grid>

        <div className="containerButtons">
          {/* <Grid xs={1} item style={{ marginRight: 20 }}> */}
          <SetLocationButton />
          {/* </Grid> */}
          {/* <Grid xs={1} item> */}
          <DeleteItem />
          {/* </Grid> */}
        </div>
        {/* <Grid xs={2} item> */}
        <div style={{ paddingLeft: 8, marginTop: 5, minWidth: "310px" }}>
          <ColumnsFilter setColumns={setColumns} columns={columns} />
        </div>
        {/* </Grid> */}
      </Grid>
    </InnerPageSection>
  );
};

export default ItemListFilters;
