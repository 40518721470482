import {
  discardedStatus,
  readyToBeOnSale,
} from "screens/ItemCreateEdit/components/validationShared";

const itemTechnicalSpecsValidate = (values, statusList) => {
  let errors = {};

  if (!values.condition || values.condition === "-1") {
    errors = {
      ...errors,
      condition: "Condition must be selected",
    };
  }

  if (values.status && !discardedStatus(values.status, statusList)) {
    if (values.software && values.software === 'yes' && !values.softwareVersion) {
      errors = {
        ...errors,
        softwareVersion: "Software version is required",
      };
    }

    if (!values.specifyServices) {
      errors = {
        ...errors,
        specifyServices: "At least one service must be selected",
      };
    }
  }

  if (
    readyToBeOnSale(values.status, statusList) &&
    values.accesories &&
    !values.accessoriesDescription
  ) {
    errors = {
      ...errors,
      accessoriesDescription: "Item accessories description is required",
    };
  }

  return errors;
};

export default itemTechnicalSpecsValidate;
