import {
  SET_SELECTED_CATEGORY,
  UNSET_SELECTED_CATEGORY,
  SET_CATEGORIES_LIST,
  SET_CATEGORIES_FETCHING,
  UNSET_CATEGORIES_FETCHING,
  SET_CATEGORIES_LISTING_GRID,
  SET_CATEGORIES_LISTING_TABLE,
} from "store/category/types";
import {
  setFetchMessage,
  setLoadingStatus,
  unsetLoadingStatus,
} from "store/helper/actions";
import { fileCreation } from "lib/files/operations";

import * as api from "lib/api/index";
import API_RESOURCES from "lib/api/resources";

export const setSelectedCategory = (selected) => ({
  type: SET_SELECTED_CATEGORY,
  payload: {
    selected,
  },
});

export const unsetSelectedCategory = () => ({
  type: UNSET_SELECTED_CATEGORY,
});

/**
 * Fetch single category method
 * Receives an id and writes it in the store as selected category
 *
 * @returns {function(*): Promise<void>}
 * @param categoryId
 */
export const fetchSingleCategory = (categoryId) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      const response = await api.get(
        `${API_RESOURCES.CATEGORIES}/${categoryId}`
      );

      if (!response.data) {
        dispatch(setFetchMessage(response));
      }

      dispatch(setSelectedCategory(response.data));
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

async function parseCategoryPayload(categoryRawPayload) {
  return {
    ...categoryRawPayload,
    pictures: [
      ...(categoryRawPayload?.pictures || []).filter((image) => !!image._id),
      ...(
        await fileCreation(
          "categories",
          (categoryRawPayload.pictures || []).filter((image) => !!image.size)
        )
      ).map((picture, index) => ({
        file: picture.data.fileName,
        isMain: index === 0,
      })),
    ],
  };
}

/**
 * Category saving action
 *
 * @returns {function(*): Promise<void>}
 * @param categoryPayload
 * @param categoryId
 * @param shouldUpdate
 */
export const saveCategory = (
  categoryPayload,
  categoryId,
  shouldUpdate = true
) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      let response;
      let newCategoryPayload = await parseCategoryPayload(categoryPayload);

      if (categoryId) {
        response = await api.put(
          `${API_RESOURCES.CATEGORIES}/${categoryId}`,
          newCategoryPayload
        );
      } else {
        response = await api.post(API_RESOURCES.CATEGORIES, newCategoryPayload);
      }

      if (response) {
        dispatch(setFetchMessage({ ...response, shouldUpdate }));
      }
    } catch (e) {
      console.log(e);
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

const setCategoriesList = (data) => ({
  type: SET_CATEGORIES_LIST,
  payload: {
    data,
  },
});

const setCategoriesFetchingStatus = () => ({
  type: SET_CATEGORIES_FETCHING,
});

const unsetCategoriesFetchingStatus = () => ({
  type: UNSET_CATEGORIES_FETCHING,
});

/**
 * Fetch categories action
 *
 * @param query
 * @returns {function(*): Promise<void>}
 */
export const fetchCategories = (query) => {
  return async (dispatch) => {
    dispatch(setCategoriesFetchingStatus());

    try {
      const response = await api.get(API_RESOURCES.CATEGORIES, {
        ...query,
      });

      if (response.data) {
        dispatch(setCategoriesList(response.data));
      }
    } catch (e) {
      console.log(e);
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetCategoriesFetchingStatus());
    }
  };
};

/**
 * Delete category method
 *
 * @param categoryId
 * @returns {function(*): Promise<void>}
 */
export const deleteCategory = (categoryId) => {
  return async (dispatch) => {
    dispatch(setCategoriesFetchingStatus());

    try {
      const response = await api.deleteMethod(
        `${API_RESOURCES.CATEGORIES}/${categoryId}`
      );

      dispatch(setFetchMessage({ ...response, shouldUpdate: true }));
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

/**
 * Action to set category listing preference as grid
 * @returns {{type: string}}
 */
export const setCategoryGridListing = () => ({
  type: SET_CATEGORIES_LISTING_GRID,
});

/**
 * Action to set category listing preference as table
 * @returns {{type: string}}
 */
export const setCategoryTableListing = () => ({
  type: SET_CATEGORIES_LISTING_TABLE,
});
