import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {Button, CircularProgress} from "@material-ui/core";

import "components/FormActionButtons/styles.scss";

// TODO: this component could be extended to be used for every in-page form
const FormActionButtons = ({
  navigateTo,
  submitButtonProps,
  submitActionText,
  cancelButtonAction,
  cancelButtonText,
  cancelButtonProps
}) => {
  const history = useHistory();

  const { loading } = useSelector(({ helperReducer }) => helperReducer);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div style={{ display: "flex" }}>
      {navigateTo && (
        <Button color={"secondary"} onClick={goBack}>
          Cancel
        </Button>
      )}
      {
        !navigateTo && (
          <Button onClick={cancelButtonAction} {...cancelButtonProps}>
            {cancelButtonText}
          </Button>
        )
      }
      <Button
        {...submitButtonProps}
        disabled={loading || submitButtonProps.disabled}
        color={"secondary"}
        variant={"contained"}
        startIcon={loading ? <CircularProgress size={25}/> : null}
      >
        {loading ? "Saving..." : submitActionText}
      </Button>
    </div>
  );
};

FormActionButtons.propTypes = {
  submitButtonProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  }),
  submitActionText: PropTypes.string.isRequired,
  navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cancelButtonAction: PropTypes.func,
  cancelButtonText: PropTypes.string,
  cancelButtonProps: PropTypes.object
};

export default FormActionButtons;
