import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import ScreenLayout from "components/ScreenLayout";
import CategoriesGridList from "screens/Categories/components/CategoriesGridList";
import CategoriesTable from "screens/Categories/components/CategoriesTable";
import catogoriesHeaderComponents from "screens/Categories/components/catogoriesHeaderComponents";

import { fetchCategories, unsetSelectedCategory } from "store/category/actions";

import "./styles.scss";

const Categories = () => {
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const { selectedDisplayList } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unsetSelectedCategory());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchCategories(searchParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (messagePayload) {
      dispatch(fetchCategories(searchParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload]);

  return (
    <ScreenLayout headerComponents={catogoriesHeaderComponents}>
      {selectedDisplayList === "grid" ? (
        <CategoriesGridList />
      ) : (
          <CategoriesTable />
        )}

    </ScreenLayout>
  );
};

export default Categories;
