import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import SimpleMenuWithOpener from "components/SimpleMenuWithOpener";
import AuthWrapper from "components/AuthWrapper";
import EditOpenSpaceButton from "screens/WarehouseDetail/components/open-spaces/EditOpenSpaceButton";
import DeleteOpenSpaceButton from "screens/WarehouseDetail/components/open-spaces/DeleteOpenSpaceButton";
import OpenSpaceItemsDrawer from "screens/WarehouseDetail/components/open-spaces/OpenSpaceItemsDrawer";

const OpenSpaceCardActions = ({ openSpace }) => {
  const cardActions = [
    {
      key: "openSpaceContent",
      component: (
        <AuthWrapper permission={"OPEN_SPACES.OPEN_SPACES_READ"}>
          <OpenSpaceItemsDrawer openSpace={openSpace} />
        </AuthWrapper>
      ),
    },
    {
      key: "editOpenSpace",
      component: (
        <AuthWrapper permission={"OPEN_SPACES.OPEN_SPACES_UPDATE"}>
          <EditOpenSpaceButton openSpace={openSpace} />
        </AuthWrapper>
      ),
    },
    {
      key: "deleteOpenSpace",
      component: (
        <AuthWrapper permission={"OPEN_SPACES.OPEN_SPACES_DELETE"}>
          <DeleteOpenSpaceButton item={openSpace} />
        </AuthWrapper>
      ),
    },
  ];

  return (
    <SimpleMenuWithOpener
      OpenerComponent={(props) => (
        <IconButton style={{ zIndex: 2 }} {...props}>
          <Icon name={"more-vertical"} color={"black"} />
        </IconButton>
      )}
      items={cardActions}
    />
  );
};

OpenSpaceCardActions.propTypes = {
  openSpace: PropTypes.object.isRequired,
};

export default OpenSpaceCardActions;
