import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Typography } from "@material-ui/core";

import CategoryModal from "screens/Categories/components/CategoryModal";
import { saveCategory } from "store/category/actions";

const EditCategoryButton = ({ item, OpenerComponent }) => {
  const dispatch = useDispatch();

  const handleEditCategoryClick = (values) => {
    dispatch(saveCategory(values, item._id));
  };

  const initialValues = {
    name: item.name,
    pictures: item.pictures,
    imagesToDelete: [],
  };

  return (
    <CategoryModal
      initialValues={initialValues}
      cancelActionText={"Cancel"}
      submitActionText={"Save"}
      onSubmit={handleEditCategoryClick}
      title={`Edit category ${item.name ? item.name : ""}`}
      OpenerComponent={(props) =>
        OpenerComponent ? (
          <OpenerComponent {...props} />
        ) : (
          <Typography {...props}>Edit</Typography>
        )
      }
    />
  );
};

EditCategoryButton.propTypes = {
  // TODO: handle shape of product
  item: PropTypes.object.isRequired,
};

export default EditCategoryButton;
