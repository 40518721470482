import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

import SearchBar from "components/SearchBar";

const CategoriesSearchBar = () => {
  const location = useLocation();

  const searchParams = queryString.parse(location.search);
  const [searchObject, setSearchObject] = useState(searchParams);

  const searchCategories = (payloadQuery) => {
    setSearchObject(payloadQuery);
  };

  return (
    <div>
      <SearchBar
        defaultValue={searchObject?.search}
        searchKeyAccessor={'search'}
        name={"categorySearch"}
        onChange={searchCategories}
        placeholder={"Search categories"}
        fullWidth
        margin="dense"
        searchValueLengthToSearch={3}
      />
    </div>
  );
};

export default CategoriesSearchBar;
