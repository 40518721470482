import {
  SET_FETCH_MESSAGE,
  SET_LOADING_STATUS,
  UNSET_FETCH_MESSAGE,
  UNSET_LOADING_STATUS,
} from "store/helper/types";

const helperReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING_STATUS:
      return {
        ...state,
        loading: true,
      };
    case UNSET_LOADING_STATUS:
      return {
        ...state,
        loading: false,
      };
    case SET_FETCH_MESSAGE:
      return {
        ...state,
        messagePayload: payload.messagePayload,
      };
    case UNSET_FETCH_MESSAGE:
      return {
        ...state,
        messagePayload: null,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  messagePayload: null,
};

export default helperReducer;
