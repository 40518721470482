import Dashboard from "screens/Dashboard";

const containersRoutes = [
  {
    name: "Dashboard",
    path: "/",
    component: Dashboard,
    exact: true,
    featureKey: "WILDCARD",
    // permission: "WILDCARD",
  },
];

export default containersRoutes;
