import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import defaultImage from "assets/img/default-image.jpg";

import { CardMedia } from "@material-ui/core";

import { getFile } from "lib/files/operations";

const CategoryCardImage = ({ picture, name, ...props }) => {
  const [fetchedPicture, setFetchedPicture] = useState(defaultImage);

  useEffect(() => {
    async function fetchImage() {
      const image = await getFile(picture.file);
      image.type === 'LOCAL' ? setFetchedPicture(URL.createObjectURL(image.file)) : setFetchedPicture(image.file);
    }

    if (picture) {
      fetchImage();
    }
  }, [picture]);

  return (
    <CardMedia
      className={'category-card__image'}
      image={fetchedPicture}
      title={name}
      {...props}
    />
  );
};

CategoryCardImage.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.object,
};

export default CategoryCardImage;
