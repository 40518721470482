import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import TableLayoutHead from "components/TableLayoutHead";
import PaginationLayout from "components/PaginationLayout";
import TableCheckbox from "components/TableCheckbox";
import AuthWrapper from "components/AuthWrapper";

import { useHasPermission } from "lib/hooks/permissions";

import "./styles.scss";

const TableLayout = ({
  columns,
  data,
  pagination,
  loading,
  noItemsMessage,
  className,
  rowClickAction,
  containerClassName,
  actionCellProps,
  onSelectRowAction,
  isSelected,
  actions = [],
  keyAccessor = "_id",
  ...props
}) => {
  /**
   * Render selected component for each row
   *
   * @param column
   * @param item
   * @returns {JSX.Element|null}
   * @constructor
   */
  const RowComponent = (column, item) => {
    if (column.shouldBeHidden) {
      return null;
    }

    return column ? (
      <TableCell
        align={column.numeric ? "center" : "center"}
        padding={column.disablePadding ? "none" : "normal"}
        className={classnames({
          "table-layout-cell": true,
          [column.props?.className]: !!column.props?.className,
          "table-column-fixed": column.fixedColumn,
          "table-column-clickable": !!column?.props?.onClick,
        })}
        {...column.props}
      >
        <column.Component
          item={item}
          accessor={column.name}
          {...column.componentProps}
        />
      </TableCell>
    ) : null;
  };

  const hasActions = useHasPermission(
    actions.map((action) => action.permission)
  );

  if (loading) {
    return (
      <Grid xs={12} item className={"grid-list__no-content"}>
        <CircularProgress color={"secondary"} />
      </Grid>
    );
  } else if (data.length === 0) {
    return (
      <Grid xs={12} item className={"grid-list__no-content"}>
        {noItemsMessage}
      </Grid>
    );
  }

  if (!loading && data.length > 0) {
    return (
      <>
        <div
          className={classnames({ [containerClassName]: !!containerClassName })}
        >
          <Table
            className={classnames({
              "table-layout": true,
              [className]: !!className,
            })}
            {...props}
          >
            <TableLayoutHead
              withCheckbox={!!onSelectRowAction}
              columns={columns}
              actionColumnProps={actionCellProps}
              shouldDisplayActions={hasActions && !!actions.length}
            />
            <TableBody>
              {data.map((dataItem) => (
                <TableRow
                  selected={
                    isSelected ? isSelected(dataItem[keyAccessor]) : false
                  }
                  className={"table-layout-row"}
                  key={dataItem[keyAccessor]}
                  onClick={() => !!rowClickAction?.()}
                >
                  {onSelectRowAction && (
                    <TableCheckbox
                      action={onSelectRowAction}
                      itemId={dataItem[keyAccessor]}
                      isSelected={
                        isSelected ? isSelected(dataItem[keyAccessor]) : false
                      }
                    />
                  )}
                  {columns.map((column) => (
                    <AuthWrapper
                      permission={column.permission || "WILDCARD"}
                      key={`${column.name}-${dataItem[keyAccessor]}`}
                    >
                      {RowComponent(column, dataItem)}
                    </AuthWrapper>
                  ))}
                  {hasActions && !!actions.length && (
                    <TableCell className="actionsItems">
                      {actions.map((action, index) => (
                        <AuthWrapper permission={action.permission} key={index}>
                          <action.Component item={dataItem} />
                        </AuthWrapper>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {pagination && <PaginationLayout data={data} config={pagination} />}
      </>
    );
  }
};

TableLayout.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      props: PropTypes.object,
    })
  ),
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func])
  ),
  data: PropTypes.array.isRequired,
  sortBy: PropTypes.func,
  keyAccessor: PropTypes.string,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  actionCellProps: PropTypes.object,
  noItemsMessage: PropTypes.object,
};

export default TableLayout;
