import React from "react";
import { IconButton } from "@material-ui/core";

import EditCategoryButton from "screens/Categories/components/EditCategoryButton";
import Icon from "components/Icon";

const EditCategoryButtonInTable = ({ item }) => {
  return (
    <EditCategoryButton
      item={item}
      OpenerComponent={(props) => (
        <IconButton {...props}>
          <Icon name={"edit"} />
        </IconButton>
      )}
    />
  );
};

EditCategoryButtonInTable.propTypes = {};

export default EditCategoryButtonInTable;
