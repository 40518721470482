import React from "react";

import { Grid } from "@material-ui/core";

import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import ItemTitle from "screens/InventoryItemDetail/components/ItemTitle";
import GoToItemDetailButton from "screens/ItemHistory/components/GoToItemDetailButton";
import GoToItemEditionButton from "screens/ItemHistory/components/GoToItemEditionButton";

const itemHistoryHeader = (item) => [
  <Grid item xs={8}>
    <LayoutHeaderTitle
      titleComponent={<ItemTitle item={item} prefix={"Item history for"} />}
    />
  </Grid>,
  <Grid item xs={4}>
    <Grid container justify={"flex-end"} alignItems={"center"} spacing={2}>
      <Grid xs={5} item>
        <GoToItemDetailButton item={item} />
      </Grid>
      <Grid xs={4} item>
        <GoToItemEditionButton item={item}/>
      </Grid>
    </Grid>
  </Grid>,
];

export default itemHistoryHeader;
