import React from "react";
import PropTypes from "prop-types";
import {useHistory } from 'react-router-dom';

import { Button } from "@material-ui/core";

import Icon from "components/Icon";

const GoToItemDetailButton = ({ item }) => {
  const history = useHistory();

  const navigateToDetail = () => {
    history.push(`/inventory/items/detail/${item._id}`);
  };

  return (
    <Button
      fullWidth
      startIcon={<Icon name={"file"} />}
      color={"secondary"}
      variant={"outlined"}
      onClick={navigateToDetail}
      style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 16, paddingLeft: 16 }}
    >
      Item details
    </Button>
  );
};

GoToItemDetailButton.propTypes = {
  item: PropTypes.object.isRequired
};

export default GoToItemDetailButton;
