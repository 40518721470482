import imageResizer from "smart-img-resize";

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function formatPicturesArray(filesArray) {
  return filesArray.map(async (file) => ({
    ...file,
    file: await toBase64(file),
  }));
}

function formatAssetFromServer(fileString) {
  return `data:image/jpeg;base64,${fileString}`;
}

/**
 * Returns file size in kB
 *
 * @param base64String
 * @returns {number}
 */
function fileSizeByBase64(base64String) {
  var head = "data:image/png;base64,";
  var imgFileSize = Math.round(((base64String.length - head.length) * 3) / 4);

  return imgFileSize / 1024;
}

/**
 * Function to resize images before they get uploaded
 *
 * @param file
 */
function fileResizing(file) {
  return new Promise((resolve, reject) => {
    imageResizer(
      file,
      {
        outputFormat: "png",
        targetWidth: 400,
        targetHeight: 400,
      },
      (err, base64Img) => {
        if (err) {
          reject(err);
        }

        resolve(base64Img);
      }
    );
  });
}

function createURL(file) {
  const reader = new FileReader();
  reader.onload = reader.readAsDataURL(file);
}

export {
  formatPicturesArray,
  toBase64,
  formatAssetFromServer,
  fileSizeByBase64,
  fileResizing,
  createURL,
};
