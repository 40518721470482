import {fileSizeByBase64} from "lib/assets/format";

const categoryFormValidate = values => {
  let errors = {};

  if (!values.name) {
    errors = {
      ...errors,
      name: "Product name is required"
    }
  }

  if (values.pictures && values.pictures?.[0] && fileSizeByBase64(values.pictures?.[0]) > 2048) {
    errors = {
      ...errors,
      pictures: "Image size is bigger than the minimum accepted (2MB)"
    }
  }

  return errors;
}

export default categoryFormValidate;