import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";

import { TablePagination } from "@material-ui/core";

import PaginationActions from "components/PaginationActions";

const PaginationLayout = ({ config, rowsPerPageOptions = [] }) => {
  const [page, setPage] = useState(config.page);

  const rowsPerPage = 20;

  const location = useLocation();
  const history = useHistory();

  const onPageChange = (event, newPage) => {
    setPage(newPage);

    const parsedParams = queryString.parse(location.search);
    const searchQuery = new URLSearchParams({
      ...parsedParams,
      page: newPage > -1 ? newPage + 1 : null,
    });

    history.push({
      pathname: location.pathname,
      search: `${searchQuery.toString()}`,
    });
  };

  const onChangeRowsPerPage = () => {};

  const paginationInfoText = ({ from, to, count }) =>
    `Showing ${from}-${to} of ${count}`;

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={config.count}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      onChangePage={onPageChange}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelDisplayedRows={paginationInfoText}
      ActionsComponent={PaginationActions}
    />
  );
};

PaginationLayout.propTypes = {
  config: PropTypes.shape({
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
};

export default PaginationLayout;
