import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import SelectWrapper from "components/SelectWrapper";

// TODO: extend use of filter list component (ListFilter/index.js)
const FilterContainers = () => {
  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);

  const [typeValue, setTypeValue] = useState(searchQuery?.type);

  const { typesList } = useSelector(({ containerReducer }) => containerReducer);

  const onSelectType = (selectedValue) => {
    const searchParams = new URLSearchParams(searchQuery);

    searchParams.delete("type");

    if (selectedValue !== "-1") {
      setTypeValue(selectedValue);
      searchParams.append("type", selectedValue);
    }

    history.push({
      path: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <SelectWrapper
      defaultValue={typeValue || '-1'}
      label={typeValue ? "All containers" : "Filter by type"}
      onChange={onSelectType}
      margin="dense"
      fullWidth
      options={typesList}
      valueAccessor={"_id"}
      labelAccessor={"name"}
    />
  );
};

export default FilterContainers;
