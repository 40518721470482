const openSpaceFormValidator = (values) => {
  let errors = {};

  if (!values.name) {
    return {
      ...errors,
      name: "Open space name is required",
    };
  }
};

export default openSpaceFormValidator;
