/**
 * Given a status id, determines if that given status corresponds to a discarded status
 * Discarded status is one of: 'Draft', 'Scrapping', 'Waste' or 'Donated'
 *
 * @param statusId
 * @param statusList
 * @returns {boolean}
 */
function discardedStatus(statusId, statusList) {
  const status = statusList?.find((status) => status?._id === statusId);

  return (
    status && (
    status.name === "Draft" ||
    status.name === "Scrapping" ||
    status.name === "Waste" ||
    status.name === "Donated")
  );
}

function readyToBeOnSale(statusId, statusList) {
  const status = statusList?.find((status) => status?._id === statusId);

  return (
    status && (
      status.name === "Ready for final price" ||
      status.name === "Ready for sale" ||
      status.name === "Sold")
  );
}

function alreadyOnSale(statusId, statusList) {
  const status = statusList?.find((status) => status?._id === statusId);

  return (
    status && (
      status.name === "Ready for sale" ||
      status.name === "Sold")
  );
}

export { discardedStatus, readyToBeOnSale, alreadyOnSale };
