import {ITEM_DETAIL_SECTIONS} from "lib/object/constants";
import {formatDateForTable} from "lib/date/format";

export function formatResult(value, type, defaultValue, ...args) {
  if (type === 'currency' && !value) {
    return Intl.NumberFormat(0);
  }

  if (!value) {
    return defaultValue
  }

  switch (type) {
    case 'date':
      return formatDateForTable(value, ...args);
    case 'currency':
      return `$ ${Intl.NumberFormat(value)}`;
    case 'text':
    default:
      return value
  }
}

export function getAttribute(object, key, objectMapper, type, defaultValue = "-", ...args) {
  if (!key) {
    return JSON.stringify(object);
  }

  if (!objectMapper[key]) {
    return defaultValue;
  }

  return `${objectMapper[key]}: ${formatResult(object[key], type, defaultValue, ...args)}`
}

export function getDetailAttribute(object, key, defaultValue, type='text', ...args) {
  return getAttribute(object, key, ITEM_DETAIL_SECTIONS, type, defaultValue, args);
}
