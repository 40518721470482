import FormInput from "components/FormInput";
import FormSwitch from "components/FormSwitch";
import FormSelect from "components/FormSelect";
import FormCheckboxes from "components/FormCheckboxes";
import FormTextareaOnDemand from "components/FormTextareaOnDemand";

import itemTechnicalSpecsValidate from "screens/ItemCreateEdit/components/itemTechnicalSpecsValidate";

import { UNDECISIVE_OPTIONS } from "lib/form/constants";
import { SERVICES_TO_APPLY } from "screens/ItemCreateEdit/constants";

const itemTechnicalSpecsFields = (itemConditionsList) => [
  {
    name: "software",
    props: {
      label: "Software",
      fields: [
        {
          name: "software",
          component: FormSelect,
          gridProps: {
            xs: 6,
          },
          props: {
            fullWidth: true,
            label: "",
            placeholder: "Has software?",
            options: UNDECISIVE_OPTIONS,
          },
        },
        {
          name: "softwareVersion",
          component: FormInput,
          gridProps: {
            xs: 6,
          },
          props: {
            label: "",
            placeholder: "Software version",
            variant: "outlined",
            dependsOnValue: "software",
            attributeToChange: "disabled",
            habilitateAttributeValues: ["-1", "no", "idk"],
          },
        },
      ],
    },
  },
  {
    name: "condition",
    component: FormSelect,
    props: {
      label: "Item condition",
      placeholder: "Select condition",
      fullWidth: true,
      options: itemConditionsList,
      valueAccessor: "_id",
      labelAccessor: "name",
      externalLabel: true,
      required: true,
    },
  },
  {
    name: "conditionNoteField",
    component: FormTextareaOnDemand,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Condition notes",
      initialValue: "",
      onClickButtonText: "Save note",
      notesField: "conditionNote",
    },
  },
  {
    name: "accesories",
    component: FormSwitch,
    props: {
      label: "Accessories",
      checked: false,
    },
  },
  {
    name: "accessoriesDescription",
    component: FormInput,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Accessories description",
      multiline: true,
      rows: 3,
      dependsOnValue: "accesories",
      attributeToChange: "disabled",
      habilitateAttributeValues: [false],
    },
  },
  {
    name: "specifyServices",
    component: FormCheckboxes,
    props: {
      label: "Specify services",
      options: SERVICES_TO_APPLY,
    },
  },
  {
    name: "serviceNoteField",
    component: FormTextareaOnDemand,
    props: {
      externalLabel: true,
      label: " ",
      placeholder: "Notes",
      initialValue: "",
      onClickButtonText: "Save note",
      notesField: "serviceNote",
    },
  },
  {
    name: "tested",
    component: FormSwitch,
    props: {
      label: "Tested",
      checked: false,
    },
  },
  {
    name: "testDescription",
    component: FormInput,
    props: {
      label: " ",
      placeholder: "Test note",
      multiline: true,
      rows: 3,
      externalLabel: true,
      dependsOnValue: "tested",
      attributeToChange: "disabled",
      habilitateAttributeValues: [false],
    },
  },
];

const parseServicesArray = (services) => {
  let object = {};

  for (let service in services) {
    object[services[service]] = true;
  }

  return object;
};

const getInitialValues = (selected) => {
  let initialValues;

  if (selected) {
    initialValues = {
      software: selected.software,
      softwareVersion: selected.softwareVersion,
      condition: selected.condition?._id,
      conditionNote: selected.conditionNote,
      conditionNoteField: "",
      accesories: selected?.accesories.value,
      accessoriesDescription: selected?.accesories.description,
      specifyServices: parseServicesArray(selected.specifyServices.value),
      serviceNoteField: "",
      serviceNote: selected.specifyServices.description,
      tested: selected.tested,
      testDescription: selected.testNote,
    };
  } else {
    initialValues = {
      software: "",
      softwareVersion: "",
      condition: "",
      conditionNote: [],
      conditionNoteField: "",
      accesories: false,
      accessoriesDescription: "",
      specifyServices: {},
      serviceNoteField: "",
      serviceNote: [],
      tested: false,
      testDescription: "",
    };
  }

  return initialValues;
};

const itemTechnicalSpecsForm = (itemConditionsList, statusList, selected) => {
  return {
    fields: itemTechnicalSpecsFields(itemConditionsList),
    validate: (values) => itemTechnicalSpecsValidate(values, statusList),
    displayLabel: "Tech specs",
    name: "techSpecs",
    initialValues: getInitialValues(selected),
  };
};

export default itemTechnicalSpecsForm;
