import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import FormRadios from "components/FormRadios";
import FormInput from "components/FormInput";
import FormInDialog from "components/FormInDialog";

import containerFormValidation from "screens/Containers/components/containerFormValidation";

import {
  fetchContainersCounters,
  saveContainer,
  unsetSelectedContainer,
  unsetDefaultCounters,
} from "store/container/actions";

const AddContainerModal = () => {
  const dispatch = useDispatch();

  const [selectedCounter, setSelectedCounter] = useState(null);
  const [selectedContainerType, setSelectedContainerType] = useState(null);

  const { defaultCounters, typesList } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  useEffect(() => {
    if (selectedContainerType) {
      dispatch(fetchContainersCounters());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainerType]);

  useEffect(() => {
    if (defaultCounters && selectedContainerType) {
      setSelectedCounter(
        defaultCounters.find((counter) => counter._id === selectedContainerType)
      );
    }
  }, [selectedCounter, defaultCounters, selectedContainerType]);

  const addContainerInitialValues = {
    type: "",
    name: "",
  };

  const addContainerFields = [
    {
      name: "type",
      component: FormRadios,
      props: {
        label: "Container type",
        options: typesList.map((type) => ({
          key: type._id,
          label: type.name,
        })),
        onChangeRadio: (radio) => {
          setSelectedContainerType(radio);
        },
      },
    },
    {
      name: "name",
      component: FormInput,
      props: {
        label: "Next in count",
        placeholder: "Next in count",
        variant: "outlined",
        initialValue: selectedContainerType ? selectedCounter?.seq : "",
        disabled: true,
      },
    },
  ];

  const resetModalState = () => {
    setSelectedCounter(null);
    setSelectedContainerType(null);

    dispatch(unsetSelectedContainer());
    dispatch(unsetDefaultCounters());
  };

  const addContainer = (data) => {
    dispatch(saveContainer(data));
  };

  return (
    <FormInDialog
      actionOnClose={resetModalState}
      validate={containerFormValidation}
      initialValues={addContainerInitialValues}
      formFields={addContainerFields}
      title={"Add new container"}
      submitActionText={"Save"}
      cancelActionText={"Cancel"}
      onSubmit={addContainer}
      OpenerComponent={(props) => (
        <Button
          {...props}
          fullWidth
          variant={"contained"}
          color={"secondary"}
          startIcon={<AddIcon />}
        >
          Add container
        </Button>
      )}
    />
  );
};

AddContainerModal.propTypes = {};

export default AddContainerModal;
