import React from "react";
import PropTypes from "prop-types";

import { Grid, Typography } from "@material-ui/core";

import CardContainer from "components/CardContainer";

import { formatDateForTable } from "lib/date/format";

import "./styles.scss";

const ChangelogCard = ({ date, byUser, description, statusText, action }) => {
  return (
    <CardContainer
      className={"changelog-card"}
      cardTitle={
        <Grid container alignItems={"flex-start"} justify={"space-between"}>
          <Grid item>
            <span className={"changelog-card__header"}>
              {statusText} on{" "}
              <span style={{ fontWeight: "bold" }}>
                {formatDateForTable(date, "-")}
              </span>{" "}
              {byUser && (
                <>
                  by <span style={{ fontWeight: "bold" }}>{byUser}</span>
                </>
              )}
            </span>
          </Grid>
          {action && <Grid item>{!!action && action}</Grid>}
        </Grid>
      }
    >
      {description ? (
        <Typography className={"changelog-card__description"}>
          {description}
        </Typography>
      ) : (
        <></>
      )}
    </CardContainer>
  );
};

ChangelogCard.propTypes = {
  date: PropTypes.any.isRequired,
  byUser: PropTypes.string.isRequired,
  statusText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  description: PropTypes.string,
};

export default ChangelogCard;
