import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import DateRangePickerWrapper from "components/DateRangePickerWrapper";

const DateRangeFilter = ({
  startDateFilterKey,
  endDateFilterKey,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);

  const [startDateFilterValue, setStartDateFilterValue] = useState(
    searchQuery?.[startDateFilterKey]
  );
  const [endDateFilterValue, setEndDateFilterValue] = useState(
    searchQuery?.[endDateFilterKey]
  );

  const onSelectFilterValue = (selectedValue) => {
    const searchParams = new URLSearchParams(searchQuery);

    searchParams.delete(startDateFilterKey);
    searchParams.delete(endDateFilterKey);

    if (selectedValue.startDate) {
      setStartDateFilterValue(selectedValue.startDate);
      searchParams.append(startDateFilterKey, selectedValue.startDate);
    }

    if (selectedValue.endDate) {
      setEndDateFilterValue(selectedValue.endDate);
      searchParams.append(endDateFilterKey, selectedValue.endDate);
    }

    history.push({
      path: location.pathname,
      search: searchParams.toString(),
    });
  };


  return (
    <DateRangePickerWrapper
      initialValue={[startDateFilterValue, endDateFilterValue]}
      onChange={onSelectFilterValue}
      size={"large"}
      placeholder={["YYYY-MM-DD", "YYYY-MM-DD"]}
      {...props}
    />
  );
};

DateRangeFilter.propTypes = {
  startDateFilterKey: PropTypes.string.isRequired,
  endDateFilterKey: PropTypes.string.isRequired,
};

export default DateRangeFilter;
