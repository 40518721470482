import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { SwipeableDrawer, IconButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Icon from "components/Icon";

const useStyles = makeStyles({
  "header-container": {
    paddingLeft: 20,
    paddingRight: 20,
  },
});

function DrawerContainer({
  openFrom = "left",
  OpenerComponent,
  uncontrolledAction,
  uncontrolledIsOpen,
  children,
  headerConfig,
  actionAfterClosed,
  ...props
}) {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  function toggleDrawer() {
    if (uncontrolledAction) {
      uncontrolledAction(isOpen);
    } else {
      setIsOpen(!isOpen);
    }
  }

  useEffect(() => {
    if (
      ((typeof uncontrolledIsOpen === "boolean" && !uncontrolledIsOpen) ||
        !isOpen) &&
      actionAfterClosed
    ) {
      actionAfterClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uncontrolledIsOpen, isOpen]);

  return (
    <>
      <OpenerComponent onClick={toggleDrawer} />
      <SwipeableDrawer
        anchor={openFrom}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        open={!!uncontrolledAction ? uncontrolledIsOpen : isOpen}
        {...props}
      >
        {headerConfig && (
          <Grid
            container
            alignItems={"center"}
            justify={"space-between"}
            className={styles["header-container"]}
          >
            <Grid item>{headerConfig.title}</Grid>
            <Grid item>
              <IconButton onClick={toggleDrawer}>
                <Icon name={"cancel"} fontSize={16} />
              </IconButton>
            </Grid>
          </Grid>
        )}
        {children}
      </SwipeableDrawer>
    </>
  );
}

DrawerContainer.propTypes = {
  OpenerComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  uncontrolledAction: PropTypes.func,
  uncontrolledIsOpen: PropTypes.bool,
  openFrom: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  headerConfig: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
      ]),
    }),
  ]),
};

export default DrawerContainer;
