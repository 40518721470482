import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";

import { Typography} from "@material-ui/core";

import ModalConfirm from "components/ModalConfirm";

import {deleteOpenSpace} from "store/open-space/actions";

const DeleteOpenSpaceButton = ({ item }) => {
  const dispatch = useDispatch();

  const deleteThisOpenSpace = () => {
    dispatch(deleteOpenSpace(item._id));
  }

  return (
    <ModalConfirm
      message={"Are you sure that you want to delete this open space?"}
      actionOnAccept={deleteThisOpenSpace}
      modalTitle={`Delete open space ${item.name}?`}
      actionOnAcceptText={"Delete open space"}
      Component={(props) => (
        <Typography {...props}>Delete</Typography>
      )}
    />
  );
};

DeleteOpenSpaceButton.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DeleteOpenSpaceButton;