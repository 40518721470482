import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";

import Icon from "components/Icon";
import LinkContainer from "components/LinkContainer";

import "./GoToItemHistoryButton.scss";

const GoToItemHistoryButton = ({ item }) => {
  return (
    <LinkContainer
      to={`/inventory/items/history/${item._id}`}
      Component={(props) => (
        <Button
          style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 16, paddingLeft: 16 }}
          variant={"outlined"}
          color={"secondary"}
          startIcon={<Icon name={"history"} />}
          {...props}
        >
          Item history
        </Button>
      )}
    />
  );
};

GoToItemHistoryButton.propTypes = {
  item: PropTypes.object.isRequired,
};

export default GoToItemHistoryButton;
