import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Paper, Typography } from "@material-ui/core";

import LocationLevel from "screens/WarehouseDetail/components/warehouse-locations/LocationLevel";
import DeleteRack from "screens/WarehouseDetail/components/warehouse-locations/DeleteRack";
import AddLevelButton from "screens/WarehouseDetail/components/warehouse-locations/AddLevelButton";

import "./styles.scss";

const LocationRow = ({ locationConfig, row }) => {
  const maxLevelsQtyIndex = () => {
    let indexOfMax = 0;

    for (let rack in locationConfig.racks) {
      if (
        locationConfig.racks[rack].shelves.length >
        locationConfig.racks[indexOfMax].shelves.length
      ) {
        indexOfMax = rack;
      }
    }

    return indexOfMax;
  };

  return (
    <div className={"location-row"}>
      {locationConfig.racks.length ? (
        <div className={"location-row-racks-header"}>
          {locationConfig.racks.map((rack, index) => (
            <div className={"location-row-rack"} key={index}>
              <Paper
                className={classnames({
                  "location-rack-header": true,
                  "location-rack-header--first": index === 0,
                })}
                key={rack._id}
                variant={"outlined"}
                square
              >
                <Typography className={"location-rack-header-title"}>
                  Rack {rack.name} <DeleteRack rack={rack} />
                </Typography>
                <AddLevelButton rack={rack} />
              </Paper>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography>
            There's no racks to display. This row can be safely deleted.
          </Typography>
        </div>
      )}
      <div className="location-levels locations-levels-header">
        {locationConfig?.racks[maxLevelsQtyIndex()]?.shelves.map(
          (level, index) => (
            <div key={index} className={"location-level"}>
              <Paper className={"location-level-header"}>
                <Typography component={"span"}>{level.name}</Typography>
              </Paper>
              <LocationLevel
                rowIndex={row}
                key={index}
                level={level}
                locationConfig={locationConfig}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

LocationRow.propTypes = {
  locationConfig: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    racks: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        shelves: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }),
};

export default LocationRow;
