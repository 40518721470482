import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { addRow } from "store/warehouse/actions";

const AddRowButton = () => {
  const dispatch = useDispatch();

  const { selected: selectedWarehouse } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );

  const addRowToWH = () => {
    dispatch(addRow({ warehouse: selectedWarehouse._id }));
  };

  return (
    <Button
      startIcon={<AddIcon />}
      color={"secondary"}
      variant={"contained"}
      onClick={addRowToWH}
    >
      Add row
    </Button>
  );
};

export default AddRowButton;
