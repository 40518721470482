import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  Button,
} from "@material-ui/core";

import Icon from "components/Icon";
import TableText from "components/TableText";
import ModalActions from "components/ModalActions";
import ModalWithOpener from "components/ModalWithOpener";
import SelectWrapper from "components/SelectWrapper";

import { saveItemCategory } from "store/item/actions";

const TableItemCategory = ({ item, listCategories }) => {
  const dispatch = useDispatch();

  // const { statusList } = useSelector(({ itemReducer }) => itemReducer);

  const [alert, setAlert] = useState(false);

  const [categoryId, setCategoryId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeAlert = () => {
    setAlert(false);
  };
  const onChangeSelect = (value) => {
    setCategoryId(value);
  };

  const saveItem = () => {
    if (item.category?._id === categoryId) {
      setAlert(true);
    } else if (categoryId === -1) {
      setAlert(true);
    } else if (categoryId === null) {
      setAlert(true);
    } else if (categoryId === undefined) {
      setAlert(true);
    } else {
      dispatch(saveItemCategory(item.id, categoryId));
    }
  };

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid
          xs={11}
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="containerCategoryItem"
        >
          <TableText
            accessor={"category"}
            item={{ category: item.category ? item.category.name : "" }}
          />
          <ModalWithOpener
            OpenerComponent={
              <IconButton onClick={openModal}>
                <Icon
                  name={"edit"}
                  color={"black"}
                  classnames={"item-table-action-icon"}
                  fontSize={18}
                />
              </IconButton>
            }
            open={isOpen}
            onClose={closeModal}
          >
            <>
              <DialogTitle>Change item category</DialogTitle>
              <DialogContent style={{ margin: 24 }}>
                <SelectWrapper
                  defaultValue={item.category ? item.category.id : ""}
                  label={item.category ? item.category.name : ""}
                  onChange={onChangeSelect}
                  margin="dense"
                  fullWidth
                  options={listCategories}
                  valueAccessor={"_id"}
                  labelAccessor={"name"}
                />
              </DialogContent>
              <ModalActions
                closeModalText={"cancel"}
                closeModal={closeModal}
                onSubmitActionText={"Save Item"}
                onClick={saveItem}
              />
            </>
          </ModalWithOpener>
          <Grid xs={1} item style={{ padding: 0, margin: 0 }}></Grid>
        </Grid>
      </Grid>
      {alert && (
        <Dialog open={alert} onClose={closeAlert}>
          <DialogTitle>Select a different category</DialogTitle>
          <DialogContent>
            The selected category already belongs to the item. choose a
            different one
          </DialogContent>
          <DialogActions>
            <Button color={"secondary"} onClick={closeAlert}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

TableItemCategory.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableItemCategory;
