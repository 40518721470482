import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import SimpleMenuWithOpener from "components/SimpleMenuWithOpener";
import EditCategoryButton from "screens/Categories/components/EditCategoryButton";
import DeleteCategoryButton from "screens/Categories/components/DeleteCategoryButton";
import AuthWrapper from "components/AuthWrapper";

const CategoryCardActions = ({ category }) => {
  const cardActions = [
    {
      key: "editCategory",
      component: (
        <AuthWrapper permission={"CATEGORIES.CATEGORIES_UPDATE"}>
          <EditCategoryButton item={category} />
        </AuthWrapper>
      ),
    },
    {
      key: "deleteCategory",
      component: (
        <AuthWrapper permission={"CATEGORIES.CATEGORIES_DELETE"}>
          <DeleteCategoryButton item={category} />
        </AuthWrapper>
      ),
    },
  ];

  return (
    <SimpleMenuWithOpener
      OpenerComponent={(props) => (
        <IconButton style={{ flex: 1, zIndex: 2 }} {...props}>
          <Icon name={"more-vertical"} color={"black"} />
        </IconButton>
      )}
      items={cardActions}
    />
  );
};

CategoryCardActions.propTypes = {
  category: PropTypes.object.isRequired,
};

export default CategoryCardActions;
