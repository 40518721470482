import React from 'react';
import PropTypes from 'prop-types';

import TableText from "components/TableText";

import * as dateFormat from "lib/date/format";

const TableDate = ({item, accessor, className, type = 'short', separator = '-', ...props}) => {
  const formattedDate = () => {
    if (item[accessor]) {
      switch (type) {
        case "timestamp":
          return dateFormat.formatForTable(
            item[accessor],
            separator
          )
        case "short":
        default:
          return dateFormat.formatDateForTable(
            item[accessor],
            separator
          )
      }
    }

    return '-'
  }

  return (
    <TableText item={{[accessor]: formattedDate()}} accessor={accessor} {...props} />
  );
};

TableDate.propTypes = {
  item: PropTypes.object.isRequired,
  accessor: PropTypes.string.isRequired,
};

export default TableDate;