import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

const HeaderLink = ({ route, thisRef, ...props }) => {
  const location = useLocation();

  const isActive = (path) => {
    const splitPathname = location.pathname.split("/");

    return splitPathname?.[1] && path.includes(splitPathname?.[1]);
  };

  return (
    <div
      className={`header-link-container ${
        isActive(route.path) ? "header-link-container__active" : ""
      }`}
      ref={thisRef}
      {...props}
    >
      {route.icon && route.icon}
      {route.type === "dropdown" ? (
        <span>{route.name}</span>
      ) : (
        <Link to={route.path}>{route.name}</Link>
      )}
    </div>
  );
};

HeaderLink.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    exact: PropTypes.bool,
  }),
};

export default HeaderLink;
