export const SERVICES_TO_APPLY = [
  {
    key: "needsAttention",
    label: "Needs attention",
  },
  {
    key: "visibleDamage",
    label: "Visible damage",
  },
  {
    key: "internalDamage",
    label: "Internal damage",
  },
  {
    key: "cosmeticIssues",
    label: "Cosmetic issues",
  },
  {
    key: "missingComponents",
    label: "Missing components",
  },
  {
    key: "needsCleaning",
    label: "Needs cleaning",
  },
  {
    key: "other",
    label: "Others",
  },
];