import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ScreenLayout from "components/ScreenLayout";
import HistoryTable from "screens/ItemHistory/components/HistoryTable";
import itemHistoryHeader from "screens/ItemHistory/components/itemHistoryHeader";
import ItemDetailHeaderSection from "screens/InventoryItemDetail/components/ItemDetailHeaderSection";

import { fetchSingleItem } from "store/item/actions";

const ItemHistory = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { selected: selectedItem } = useSelector(
    ({ itemReducer }) => itemReducer
  );

  useEffect(() => {
    if (!selectedItem) {
      dispatch(fetchSingleItem(params.id));
    }
  }, [dispatch, params.id, selectedItem]);

  return (
    <ScreenLayout
      headerComponents={!!selectedItem ? itemHistoryHeader(selectedItem) : []}
    >
      {selectedItem && (
        <>
          <ItemDetailHeaderSection selected={selectedItem} />
          <div style={{ paddingTop: 30 }}>
            <HistoryTable selectedItem={selectedItem} />
          </div>
        </>
      )}
    </ScreenLayout>
  );
};

export default ItemHistory;
