import Containers from "screens/Containers";

const containersRoutes = [
  {
    name: "Containers",
    path: "/containers",
    component: Containers,
    exact: true,
    featureKey: "CONTAINERS.CONTAINERS_READ",
  },
];

export default containersRoutes;
