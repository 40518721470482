import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

const LinkContainer = ({Component, to, linkProps = {}}) => {
  const history = useHistory();

  const navigateTo = () => {
    history.push({
      pathname: to,
      ...linkProps
    })
  }

  return (
    <Component onClick={navigateTo} />
  );
};

LinkContainer.propTypes = {
  Component: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  linkProps: PropTypes.object
};

export default LinkContainer;