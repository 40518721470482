import React from "react";
import { useDispatch } from "react-redux";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import { logout } from "store/auth/actions";
import { googleLogout } from "@react-oauth/google";

const GoogleLogoutButton = (props) => {
  const dispatch = useDispatch();

  const handleLogoutClick = async () => {
    try {
      googleLogout();
      dispatch(logout());
    } catch(err) {
      console.error(err);
    }
  };


  return (
    <IconButton onClick={handleLogoutClick}>
      <Icon name={"logout"} color={"#666"} />
    </IconButton>
  );
};

GoogleLogoutButton.propTypes = {};

export default GoogleLogoutButton;
