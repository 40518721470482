import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";

import ScreenLayout from "components/ScreenLayout";
import headerComponents from "screens/Warehouses/components/headerComponents";
import WarehouseCard from "screens/Warehouses/components/WarehouseCard";
import GridList from "components/GridList";

import {
  fetchWarehouses,
  unsetSelectedWarehouse,
} from "store/warehouse/actions";

const Warehouses = () => {
  const dispatch = useDispatch();

  const { list } = useSelector(({ warehouseReducer }) => warehouseReducer);
  const {loading} = useSelector(({helperReducer}) => helperReducer);

  useEffect(() => {
    dispatch(fetchWarehouses());
    dispatch(unsetSelectedWarehouse());
  }, [dispatch]);

  return (
    <ScreenLayout headerComponents={headerComponents}>
      <GridList
        loading={loading}
        spacing={2}
        list={list}
        ItemComponent={WarehouseCard}
        noItemsMessage={<Typography>There are no warehouses to list.</Typography>}
      />
    </ScreenLayout>
  );
};

export default Warehouses;
