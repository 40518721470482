import {
  SET_OPEN_SPACES_LIST,
  SET_SELECTED_OPEN_SPACE,
  UNSET_SELECTED_OPEN_SPACE,
  UNSET_OPEN_SPACES_LIST,
  SET_FETCHING_OPEN_SPACES_STATUS,
  UNSET_FETCHING_OPEN_SPACES_STATUS,
} from "store/open-space/types";
import {
  setFetchMessage,
  setLoadingStatus,
  unsetLoadingStatus,
} from "store/helper/actions";

import * as api from "lib/api";
import API_RESOURCES from "lib/api/resources";
import { SUCCESS_STATUS } from "components/MessageService/constants";

/**
 * Action to set selected open space
 *
 * @param selected
 * @returns {{payload: {selected: *}, type: string}}
 */
export const setSelectedOpenSpace = (selected) => ({
  type: SET_SELECTED_OPEN_SPACE,
  payload: {
    selected,
  },
});

/**
 * Action to nullify selected open space
 *
 * @returns {{type: string}}
 */
export const unsetSelectedOpenSpace = () => ({
  type: UNSET_SELECTED_OPEN_SPACE,
});

/**
 * Action for open space save (update & create)
 *
 * @param openSpacePayload
 * @param openSpaceId
 * @returns {function(*, *): Promise<void>}
 */
export const saveOpenSpace = (openSpacePayload, openSpaceId) => {
  return async (dispatch, getState) => {
    try {
      let response;

      if (openSpaceId) {
        response = await api.put(
          `${API_RESOURCES.OPEN_SPACES}/${openSpaceId}`,
          openSpacePayload
        );
      } else {
        response = await api.post(API_RESOURCES.OPEN_SPACES, openSpacePayload);
      }

      if (response) {
        dispatch(setFetchMessage(response));

        if (response.type === SUCCESS_STATUS) {
          const { warehouseReducer } = getState();

          dispatch(
            fetchOpenSpaces({ warehouse: warehouseReducer.selected._id })
          );
        }
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

const setOpenSpacesList = (list) => ({
  type: SET_OPEN_SPACES_LIST,
  payload: {
    list,
  },
});

/**
 * Fetch open spaces action
 *
 * @param queryParams
 * @param withoutLoading
 * @param sort
 * @returns {function(*): Promise<void>}
 */
export const fetchOpenSpaces = (
  queryParams,
  withoutLoading = false,
  sort = "name"
) => {
  return async (dispatch) => {
    if (!withoutLoading) {
      dispatch(setLoadingStatus());
    }

    try {
      const response = await api.get(API_RESOURCES.OPEN_SPACES, {
        ...queryParams,
        sort,
      });

      dispatch(setOpenSpacesList(response.data));
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      if (!withoutLoading) {
        dispatch(unsetLoadingStatus());
      }
    }
  };
};

export const unsetOpenSpacesList = () => ({
  type: UNSET_OPEN_SPACES_LIST,
});

/***
 * Action to delete open spaces
 *
 * @param openSpaceId
 * @returns {function(*): Promise<void>}
 */
export const deleteOpenSpace = (openSpaceId) => {
  return async (dispatch) => {
    try {
      const response = await api.deleteMethod(
        `${API_RESOURCES.OPEN_SPACES}/${openSpaceId}`
      );

      dispatch(setFetchMessage({ ...response, shouldUpdate: true }));
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

const setOSFetchingStatus = () => ({
  type: SET_FETCHING_OPEN_SPACES_STATUS,
});

const unsetOSFetchingStatus = () => ({
  type: UNSET_FETCHING_OPEN_SPACES_STATUS,
});

export const fetchSingleOpenSpace = (openSpaceId) => {
  return async (dispatch) => {
    dispatch(setOSFetchingStatus());

    try {
      const response = await api.get(`${API_RESOURCES.OPEN_SPACES}/${openSpaceId}`);

      if (response.data) {
        dispatch(setSelectedOpenSpace(response.data))
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetOSFetchingStatus());
    }
  };
};
