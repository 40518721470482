import { useSelector } from "react-redux";

function useHasPermission(permission) {
  const { authObject } = useSelector(({ authReducer }) => authReducer);

  const permissionAuthorized = (permission) => {
    let permissions = authObject?.actions;

    const keys = permission.split(".") || ["WILDCARD"];

    for (let i = 0; i < keys.length; i++) {
      let k = keys[i];

      if (k in permissions) {
        permissions = permissions[k];
      } else {
        return;
      }
    }

    return permissions;
  };

  const hasAccess = (permission) => {
    if (permission === "WILDCARD") {
      return true;
    }

    return (
      authObject && authObject.roles.length && permissionAuthorized(permission)
    );
  };

  if (permission instanceof Array) {
    let permitted = true;

    for (let i = 0; i < permission.length; i++) {
      permitted = hasAccess(permission[i]);
    }

    return permitted;
  } else {
    return hasAccess(permission);
  }
}

export { useHasPermission };
