import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Select } from "antd";

import Icon from "components/Icon";

// import { Typography } from "@material-ui/core";

// import FormFieldWrapper from "components/FormFieldWrapper";
// import AutocompleteWrapper from "components/AutocompleteWrapper";
const { Option } = Select;

const FormAutocompleteCategory = ({
  field,
  options,
  label,
  placeholder,
  onSearchExternal,
  onSelectExternal,
  requiredMessage,
  required,
  disabled,
  optionLabelSelector = "label",
  optionValueSelector,
  ...props
}) => {
  const form = useFormikContext();

  const [selectedValue, setSelectedValue] = useState(field.value);

  const onChange = (value) => {
    form.setFieldValue(field.name, value);
    setSelectedValue(value);

    if (onSearchExternal) onSearchExternal(value);
  };

  const onSelectValue = (value) => {
    form.setFieldValue(field.name, value);
    setSelectedValue(value);

    if (onSelectExternal) onSelectExternal(value);
  };

  useEffect(() => {
    if (!selectedValue || !selectedValue.length) {
      form.setFieldError(field.name, "");
      onChange("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <Select
      onChange={onSelectValue}
      // onSearch={onSearch}
      defaultValue={selectedValue}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      clearIcon={<Icon name={"cancel"} fontSize={20} color={"black"} />}
      suffixIcon={
        <Icon
          name={"dropdown-arrow"}
          type={"solid"}
          fontSize={16}
          color={"rgba(0, 0, 0, 0.54)"}
        />
      }
      className={"autocomplete-wrapper"}
      {...props}
    >
      {options.map((option, index) => (
        <Option
          key={optionValueSelector ? option[optionValueSelector] : index}
          value={
            optionValueSelector
              ? option[optionValueSelector]
              : JSON.stringify(option)
          }
        >
          {option[optionLabelSelector]}
        </Option>
      ))}
    </Select>
  );
};

FormAutocompleteCategory.propTypes = {
  optionLabelSelector: PropTypes.string,
  optionValueSelector: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default FormAutocompleteCategory;
