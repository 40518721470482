import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { IconButton } from "@material-ui/core";

import Icon from "components/Icon";
import UserModal from "screens/Users/components/UserModal";

import { saveUser } from "store/user/actions";

const EditUserButton = ({ item }) => {
  const dispatch = useDispatch();

  const initialValues = {
    name: item.name,
    email: item.email,
    roles: item.roles?.length ? item.roles[0] : "-1",
    active: item.active,
  };

  const handleUserEdit = (values) => {
    dispatch(saveUser({...values, roles: [values.roles]}, item._id));
  };

  return (
    <UserModal
      onSubmit={handleUserEdit}
      initialValues={initialValues}
      cancelActionText={"Discard"}
      submitActionText={"Save"}
      title={`Edit user ${item.name}`}
      OpenerComponent={(props) => (
        <IconButton {...props}>
          <Icon name={"edit"} color={"black"} />
        </IconButton>
      )}
    />
  );
};

EditUserButton.propTypes = {
  item: PropTypes.object.isRequired,
};

export default EditUserButton;
