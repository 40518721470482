import { alreadyOnSale } from "screens/ItemCreateEdit/components/validationShared";
import { validateNonNegativeNumber } from "lib/form/validation";

const itemPricingFormValidate = (values, statusList) => {
  let errors = {};

  if (values.cost && !validateNonNegativeNumber(values.cost)) {
    errors = {
      ...errors,
      cost: "Cost must be a positive number",
    };
  }

  if (values.price1 && !validateNonNegativeNumber(values.price1)) {
    errors = {
      ...errors,
      price1: "Price 1 must be a positive number",
    };
  }

  if (values.price2 && !validateNonNegativeNumber(values.price2)) {
    errors = {
      ...errors,
      price2: "Price 2 must be a positive number",
    };
  }

  if (values.price3 && !validateNonNegativeNumber(values.price3)) {
    errors = {
      ...errors,
      price3: "Price 3 must be a positive number",
    };
  }

  if (values.finalPrice && !validateNonNegativeNumber(values.finalPrice)) {
    errors = {
      ...errors,
      finalPrice: "Final price must be a positive number",
    };
  }

  if (values.thirtyDaysOfPosted && !validateNonNegativeNumber(values.thirtyDaysOfPosted)) {
    errors = {
      ...errors,
      thirtyDaysOfPosted: "Thirty days discount must be a positive number",
    };
  }

  if (values.ninetyDaysOfPosted && !validateNonNegativeNumber(values.ninetyDaysOfPosted)) {
    errors = {
      ...errors,
      ninetyDaysOfPosted: "Ninety days discount must be a positive number",
    };
  }

  if (values.oneHundredEightyDaysOfPosted && !validateNonNegativeNumber(values.oneHundredEightyDaysOfPosted)) {
    errors = {
      ...errors,
      oneHundredEightyDaysOfPosted:
        "180 days discount must be a positive number",
    };
  }

  if (alreadyOnSale(values.status, statusList)) {
    if (!values.cost) {
      errors = {
        ...errors,
        cost: "Cost is required given selected status",
      };
    } else if (!validateNonNegativeNumber(values.cost)) {
      errors = {
        ...errors,
        cost: "Cost must be a positive number",
      };
    }

    if (!values.finalPrice) {
      errors = {
        ...errors,
        finalPrice: "Final price is required given selected status",
      };
    } else if (!validateNonNegativeNumber(values.finalPrice)) {
      errors = {
        ...errors,
        finalPrice: "Final price must be a positive number",
      };
    }
  }
  
  return errors;
};

export default itemPricingFormValidate;
