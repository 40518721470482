import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import FormSelect from "components/FormSelect";

import {fetchWarehouseLevels, unsetLevelsList} from "store/warehouse/actions";

const LocationRackFormSelect = ({ field, form: { setFieldValue, values } }) => {
  const dispatch = useDispatch();

  const { racksList } = useSelector(({ warehouseReducer }) => warehouseReducer);
  const { selected: selectedContainer } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  const [selectedRackId, setSelectedRackId] = useState(field.value || "-1");

  const onChangeRack = (value) => {
    setSelectedRackId(value);
    setFieldValue(field.name, value);
  };

  useEffect(() => {
    if (selectedRackId !== "1") {
      dispatch(unsetLevelsList());
      dispatch(fetchWarehouseLevels(selectedRackId));
    }
  }, [dispatch, selectedRackId]);

  useEffect(() => {
    if (selectedContainer && values.type === "shelf" && selectedRackId === '-1' && values.row !== '-1') {
      const rackId = selectedContainer.shelf
        ? selectedContainer.shelf.rack._id
        : "-1";

      setSelectedRackId(rackId);
      setFieldValue("rack", rackId);
    }
  }, [values.type, selectedRackId, values.row, racksList, selectedContainer, setFieldValue]);

  if (values.type !== 'shelf') {
    return null
  }

  return (
    <FormSelect
      onChangeExternal={onChangeRack}
      label={"Rack"}
      placeholder={"Select rack"}
      options={racksList}
      field={field}
      valueAccessor={"_id"}
      labelAccessor={"name"}
      fullWidth
      disabled={!racksList.length}
    />
  );
};

LocationRackFormSelect.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default LocationRackFormSelect;
