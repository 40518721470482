import React from "react";

import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import {useDispatch} from "react-redux";

import UserModal from "screens/Users/components/UserModal";

import {saveUser} from "store/user/actions";

const AddUserModal = (props) => {
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    email: "",
    roles: "-1",
    active: false,
  };

  const handleSubmit = (values) => {
    dispatch(saveUser({...values, roles: [values.roles]}));
  };

  return (
    <UserModal
      onSubmit={handleSubmit}
      initialValues={initialValues}
      title={"Add user"}
      submitActionText={'Save'}
      cancelActionText={'Discard'}
      OpenerComponent={(props) => (
        <Button
          {...props}
          startIcon={<AddIcon />}
          variant={"contained"}
          color={"secondary"}
        >
          Add user
        </Button>
      )}
    />
  );
};

AddUserModal.propTypes = {};

export default AddUserModal;
