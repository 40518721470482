import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import FormSelect from "components/FormSelect";

import {
  fetchWarehouseRacks,
  unsetLevelsList,
  unsetRacksList,
} from "store/warehouse/actions";

const LocationRowFormSelect = ({ form: { setFieldValue, values }, field }) => {
  const dispatch = useDispatch();

  const { rowsList } = useSelector(({ warehouseReducer }) => warehouseReducer);
  const { list: openSpacesList } = useSelector(
    ({ openSpaceReducer }) => openSpaceReducer
  );
  const { selected: selectedContainer } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  const [selectedRowId, setSelectedRowId] = useState(field.value || "-1");
  const [selectedRowType, setSelectedRowType] = useState(values?.type || "");

  const onChangeRow = (value) => {
    setFieldValue(field.name, value.value);
    setFieldValue("type", value.group);

    setSelectedRowId(value.value);
    setSelectedRowType(value.group);
  };

  useEffect(() => {
    if (
      values.warehouse !== "-1" &&
      selectedContainer &&
      selectedRowId === "-1"
    ) {
      const rowId = selectedContainer.shelf
        ? selectedContainer.shelf.rack.row._id
        : selectedContainer.openSpace
        ? selectedContainer.openSpace._id
        : "-1";

      const rowType = selectedContainer.openSpace
        ? "openSpace"
        : selectedContainer.shelf
        ? "shelf"
        : "";

      setSelectedRowId(rowId);
      setFieldValue("row", rowId);

      setSelectedRowType(rowType);
      setFieldValue("type", rowType);

      if (rowType === "openSpace") {
        dispatch(unsetRacksList());
        dispatch(unsetLevelsList());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRowId,
    selectedContainer,
    values.warehouse,
    rowsList,
    dispatch,
  ]);

  useEffect(() => {
    if (values[field.name] !== "-1" && values['type'] === "shelf") {
      dispatch(fetchWarehouseRacks(values[field.name]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[field.name], values['type'], dispatch]);

  useEffect(() => {
    if (selectedRowType === "openSpace") {
      dispatch(unsetRacksList());
      dispatch(unsetLevelsList());
    }
  }, [selectedRowType, dispatch]);

  const options = [
    {
      type: "openSpace",
      categoryName: "Open spaces",
      options: openSpacesList,
    },
    {
      type: "shelf",
      categoryName: "Rows",
      options: rowsList,
    },
  ];

  return rowsList.length ? (
    <FormSelect
      onChangeExternal={onChangeRow}
      label={"Row"}
      placeholder={"Select row"}
      options={options}
      field={field}
      valueAccessor={"_id"}
      labelAccessor={"name"}
      hasGroups
      fullWidth
      disabled={!rowsList.length}
    />
  ) : null;
};

LocationRowFormSelect.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default LocationRowFormSelect;
