import React, { useEffect, useState } from "react";

import ScreenLayout from "components/ScreenLayout";
import HeaderComponents from "./components/HeaderComponents";
import DoughnutChart from "./components/DoughnutChart";

import "./Dashboard.scss";
//import { HorizontalChart } from "./components/HorizontalChart";
//import { AreaChat } from "./components/AreaChart";
// import { LineChart } from "./components/LineChart";
//import { MultipleChart } from "./components/MultipleChart";
import axios from "axios";
import { baseUrl, headers } from "../../lib/api/constants";
import PieChart from "./components/PieChart";
import ReadyForSaleChart from "./components/ReadyForSaleChart";
import VerticalChart from "./components/VerticalChart";
import { CircularProgress } from "@material-ui/core";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataStatuses, setDataStatuses] = useState([]);
  const [dataItemsReadyForSale, setDataItemsReadyForSale] = useState([]);
  const [dataItemsSold, setDataItemsSold] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getDataDashboard() {
    setLoading(true);
    const response = await axios
      .get(`${baseUrl}/dashboard`, {
        method: "GET",
        headers,
      })
      .catch((err) => console.log(err));

    if (response?.data) {
      setLoading(false);
      setData(response.data);
      // eslint-disable-next-line array-callback-return
      response.data.map((resp) => {
        if (resp.category) {
          setDataCategories(resp.category);
        }
        if (resp.status) {
          setDataStatuses(resp.status);
        }
        if (resp.itemReadyForSale) {
          setDataItemsReadyForSale(resp.itemReadyForSale);
        }
        if (resp.itemsSold) {
          setDataItemsSold(resp.itemsSold);
        }
      });
    }
  }

  useEffect(() => {
    if (data?.length === 0) {
      getDataDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alingItems: "center",
        }}
      >
        <CircularProgress color={"secondary"} />
      </div>
    );
  }

  return (
    <ScreenLayout headerComponents={HeaderComponents}>
      <div className="containerCharts">
        <div className="containerChart">
          <DoughnutChart itemForCategory={dataCategories} />
        </div>
        <div className="containerChart">
          <PieChart itemForStatus={dataStatuses} />
        </div>
        <div className="containerChart">
          <ReadyForSaleChart dataItemsReadyForSale={dataItemsReadyForSale} />
          <br />
          <VerticalChart dataItemsSold={dataItemsSold} />
        </div>
      </div>
    </ScreenLayout>
  );
};

export default Dashboard;
