import React from "react";

import OpenSpacesList from "screens/WarehouseDetail/components/open-spaces/OpenSpacesList";
import LocationsList from "screens/WarehouseDetail/components/warehouse-locations/LocationsList";
import AddRowButton from "screens/WarehouseDetail/components/warehouse-locations/AddRowButton";
import AuthWrapper from "components/AuthWrapper";
import AddOpenSpaceModal from "screens/WarehouseDetail/components/open-spaces/AddOpenSpaceModal";

const warehouseDetailSections = [
  {
    title: "Rows",
    action: (
      <AuthWrapper permission={"LOCATIONS.LOCATIONS_UPDATE"}>
        <AddRowButton />
      </AuthWrapper>
    ),
    content: (
      <AuthWrapper permission={"LOCATIONS.LOCATIONS_READ"}>
        <LocationsList />
      </AuthWrapper>
    ),
  },
  {
    title: "Open spaces",
    action: (
      <AuthWrapper permission={"OPEN_SPACES.OPEN_SPACES_CREATE"}>
        <AddOpenSpaceModal />
      </AuthWrapper>
    ),
    content: (
      <AuthWrapper permission={"OPEN_SPACES.OPEN_SPACES_READ"}>
        <OpenSpacesList />
      </AuthWrapper>
    ),
  },
];

export default warehouseDetailSections;
