import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import ScreenLayout from "components/ScreenLayout";
import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import FormWrapper from "components/FormWrapper";
import FormInput from "components/FormInput";
import FormSwitch from "components/FormSwitch";
import validateWarehouseForm from "screens/WarehouseCreateEdit/validate";
import InnerPageSection from "components/InnerPageSection";
import { fetchSingleWarehouse, saveWarehouse } from "store/warehouse/actions";
import {
  ERROR_STATUS_NOT_FOUND,
  SUCCESS_STATUS,
} from "components/MessageService/constants";
import FormActionButtons from "components/FormActionButtons";

const WarehouseCreateEdit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { selected: selectedWarehouse } = useSelector(
    ({ warehouseReducer }) => warehouseReducer
  );
  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  // Warehouse detail fetch
  useEffect(() => {
    if (params.id) {
      dispatch(fetchSingleWarehouse(params.id));
    }
  }, [dispatch, params]);

  // Basic handling for not existing routes.
  useEffect(() => {
    const handleNavigation = () => {
      if (messagePayload) {
        if (
          (params.id && messagePayload.type === ERROR_STATUS_NOT_FOUND) ||
          (messagePayload.type === SUCCESS_STATUS && !selectedWarehouse)
        ) {
          history.push("/warehouses");
        }

        if (messagePayload.type === SUCCESS_STATUS && selectedWarehouse) {
          history.goBack();
        }
      }
    };

    handleNavigation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload, params]);

  const createUpdateWarehouse = (data) => {
    if (!selectedWarehouse) {
      data = {
        ...data,
        rows: parseInt(data.rows),
        racks: parseInt(data.racks),
        shelfs: parseInt(data.shelfs),
        openSpaces: data.openSpaces ? parseInt(data.openSpaces) : 0,
      };
    }

    dispatch(saveWarehouse(data, selectedWarehouse && selectedWarehouse._id));
  };

  let formFields = [
    {
      name: "name",
      component: FormInput,
      props: {
        label: "Warehouse name",
        placeholder: "Insert warehouse name",
        fullWidth: true,
        variant: "outlined",
        externalLabel: true,
      },
    },
    {
      name: "isMain",
      component: FormSwitch,
      props: {
        label: "Main warehouse?",
        checked: false,
      },
    },
    {
      name: "address",
      component: FormInput,
      props: {
        label: "Address",
        placeholder: "Insert warehouse address",
        fullWidth: true,
        variant: "outlined",
        externalLabel: true,
      },
    },
  ];

  if (!selectedWarehouse) {
    formFields = [
      ...formFields,
      {
        name: "rows",
        component: FormInput,
        props: {
          label: "Quantity of rows",
          placeholder: "Insert quantity of rows in this warehouse",
          fullWidth: true,
          type: "number",
          variant: "outlined",
          externalLabel: true,
        },
      },
      {
        name: "racks",
        component: FormInput,
        props: {
          label: "Racks per row",
          placeholder: "Insert quantity of racks per row",
          fullWidth: true,
          type: "number",
          variant: "outlined",
          externalLabel: true,
        },
      },
      {
        name: "shelfs",
        component: FormInput,
        props: {
          label: "Levels per rack",
          placeholder: "Insert quantity of levels per rack",
          fullWidth: true,
          type: "number",
          variant: "outlined",
          externalLabel: true,
        },
      },
      {
        name: "openSpaces",
        component: FormInput,
        props: {
          label: "Open spaces",
          placeholder: "Insert quantity of open spaces in this warehouse",
          fullWidth: true,
          type: "number",
          variant: "outlined",
          externalLabel: true,
        },
      },
    ];
  }

  const formInitialValues = selectedWarehouse || {
    name: "",
    isMain: false,
    address: "",
    rows: "",
    racks: "",
    shelfs: "",
    openSpaces: "",
  };

  if ((params.id && selectedWarehouse) || !params.id) {
    return (
      <ScreenLayout
        headerComponents={[
          <LayoutHeaderTitle
            title={`${selectedWarehouse ? "Edit" : "Add"} warehouse`}
          />,
        ]}
      >
        <InnerPageSection title={"Warehouse details"}>
          <Grid item md={6} sm={6} xs={6}>
            <FormWrapper
              validate={validateWarehouseForm}
              onSubmit={createUpdateWarehouse}
              initialValues={formInitialValues}
              fields={formFields}
              OnSubmitActionComponent={(formProps) => (
                <Grid container>
                  <Grid item xs={4}>
                    <div />
                  </Grid>
                  <Grid item xs={8}>
                    <FormActionButtons
                      navigateTo={selectedWarehouse && "/warehouses"}
                      submitButtonProps={formProps}
                      submitActionText={"Save warehouse"}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </InnerPageSection>
      </ScreenLayout>
    );
  } else {
    return null;
  }
};

export default WarehouseCreateEdit;
