import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Typography } from "@material-ui/core";

import "./styles.scss";

const FormStepIcon = ({ icon, stepLabel, active, error }) => {
  return (
    <div className={"form-step-icon-container"}>
      <div
        className={classnames({
          "form-step-icon__icon-container": true,
          "form-step-icon__icon-container--is-active": active,
          "form-step-icon__icon-container--has-error": error,
        })}
      >
        <Typography className={"form-step-icon__icon"}>{icon}</Typography>
      </div>
      <Typography
        className={classnames({
          "form-step-icon__label": true,
          "form-step-icon__label--is-active": active,
          "form-step-icon__label--has-error": error,
        })}
      >
        {stepLabel}
      </Typography>
    </div>
  );
};

FormStepIcon.propTypes = {
  icon: PropTypes.number.isRequired,
  stepLabel: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
};

export default FormStepIcon;
