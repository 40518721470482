import React from "react";

import { Button, DialogActions } from "@material-ui/core";

import { useFormikContext } from "formik";

const ModalFormActions = ({
  closeModalText,
  closeModal,
  onSubmitActionText,
  ...props
}) => {
  const form = useFormikContext();

  return (
    <DialogActions>
      <Button color="secondary" onClick={closeModal}>
        {closeModalText}
      </Button>
      <Button
        color="secondary"
        disabled={form.isSubmitting || !form.isValid}
        onClick={props.onClick}
      >
        {onSubmitActionText}
      </Button>
    </DialogActions>
  );
};

export default ModalFormActions;
