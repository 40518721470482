import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";

import {Typography} from "@material-ui/core";

import OpenSpaceModal from "screens/WarehouseDetail/components/open-spaces/OpenSpaceModal";

import {saveOpenSpace} from "store/open-space/actions";

const EditOpenSpaceButton = ({ openSpace }) => {
  const dispatch = useDispatch();

  const handleEditOpenSpaceClick =  (values) => {
    dispatch(saveOpenSpace(values, openSpace._id));
  }

  return (
    <OpenSpaceModal
      initialValues={openSpace}
      cancelActionText={"Discard"}
      submitActionText={"Save"}
      onSubmit={handleEditOpenSpaceClick}
      title={`Edit open space ${openSpace.name}`}
      OpenerComponent={(props) => <Typography {...props}>Edit</Typography>}
    />
  );
};

EditOpenSpaceButton.propTypes = {
  openSpace: PropTypes.object.isRequired
};

export default EditOpenSpaceButton;