import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";

import { CircularProgress, Grid, Typography } from "@material-ui/core";

import ScreenLayout from "components/ScreenLayout";
import ItemDetailTextHeader from "screens/InventoryItemDetail/components/ItemDetailTextHeader";
import ImagePreviewList from "components/ImagePreviewList";
import ItemListingsList from "screens/InventoryItemDetail/components/ItemListingsList";
import PricingList from "screens/InventoryItemDetail/components/PricingList";
import ItemSectionDivider from "screens/InventoryItemDetail/components/ItemSectionDivider";
import ItemDetailHeaderSection from "screens/InventoryItemDetail/components/ItemDetailHeaderSection";
import DiscountListing from "screens/InventoryItemDetail/components/DiscountListing";
import ChangelogCard from "components/ChangelogCard";
import ChangelogList from "components/ChangelogList";
import SubcomponentsList from "screens/InventoryItemDetail/components/SubcomponentsList";
import inventoryItemDetailHeaderComponents from "screens/InventoryItemDetail/components/inventoryItemDetailHeaderComponents";
import ItemQrCode from "screens/InventoryItems/components/ItemQRCode";
import AuthWrapper from "components/AuthWrapper";

import { fetchSingleItem } from "store/item/actions";
import { ERROR_STATUS_NOT_FOUND } from "components/MessageService/constants";
import { UNDECISIVE_OPTIONS } from "lib/form/constants";
import { SERVICES_TO_APPLY } from "screens/ItemCreateEdit/constants";

import "./styles.scss";

const InventoryItemDetail = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);
  const { selected } = useSelector(({ itemReducer }) => itemReducer);

  useEffect(() => {
    dispatch(fetchSingleItem(params.id));
  }, [params.id, dispatch]);

  // Basic handling for not existing routes.
  useEffect(() => {
    const handleNavigation = () => {
      if (messagePayload) {
        if (params.id && messagePayload.type === ERROR_STATUS_NOT_FOUND) {
          history.goBack();
        }
      }
    };

    handleNavigation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload, params]);

  const getHasSoftwareValue = () => {
    return UNDECISIVE_OPTIONS.find(
      (option) => option.value === selected.software
    )?.label;
  };

  const parseServicesString = () => {
    if (!selected.specifyServices.value.length) {
      return "No services were specified.";
    }

    const selectedServices = SERVICES_TO_APPLY.filter((service) =>
      selected.specifyServices.value.find(
        (selectedService) => selectedService === service.key
      )
    );

    return selectedServices
      .map((selectedService) => selectedService.label)
      .join(", ");
  };

  return selected ? (
    <ScreenLayout
      headerComponents={inventoryItemDetailHeaderComponents(selected)}
    >
      <Grid container alignItems={"center"}>
        <ItemDetailHeaderSection selected={selected} />
        <Grid
          container
          alignItems={"flex-start"}
          className={"category-detail-section"}
        >
          <Grid xs={11} item>
            <ItemDetailTextHeader text={"Description: "} small />
            <ItemDetailTextHeader
              text={selected.description}
              bold={false}
              small
            />
          </Grid>
          <Grid xs={1} item>
            <ItemQrCode
              item={selected}
              OpenerComponent={(props) => (
                <div {...props} className={"category-detail-section__qr-code"}>
                  <QRCode value={selected.number} size={75} />
                </div>
              )}
            />
          </Grid>
        </Grid>
        <Grid container className={"category-detail-section"} spacing={2}>
          <Grid item xs={4}>
            <div className={"category-detail-section--main"}>
              <ImagePreviewList
                pictures={selected.pictures}
                title={`${selected.name} - ${selected.model}`}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"category-detail-section--main"}>
              <ItemDetailTextHeader
                text={`Item status: ${selected.status?.name}`}
                large
              />
            </div>
            <div className={"category-detail-section"}>
              <ItemDetailTextHeader text={"Status notes"} medium />
              <div className={"category-detail-section"}>
                <ChangelogList
                  list={selected.statusNote}
                  className={"category-changelog-list"}
                />
              </div>
            </div>
          </Grid>
          <AuthWrapper permission={"ITEMS.ITEMS_READ_DETAIL_PRICING"}>
            <Grid item xs={4}>
              <PricingList selected={selected} />
              <DiscountListing selected={selected} />
            </Grid>
          </AuthWrapper>
        </Grid>
        <ItemSectionDivider />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ItemDetailTextHeader text={"Software"} medium />
            <div className={"category-detail-section"}>
              <Typography className={"item-detail-description"}>
                Has software? {getHasSoftwareValue()}
              </Typography>
            </div>
            {selected.software === "yes" && (
              <div>
                <Typography
                  className={
                    "item-detail-description item-detail-description--secondary"
                  }
                >
                  Software version: {selected.softwareVersion}
                </Typography>
              </div>
            )}
            <div
              className={
                "category-detail-section category-detail-section--main"
              }
            >
              <ItemDetailTextHeader
                text={`Accessories: ${
                  selected?.accesories?.value ? "Yes" : "No"
                }`}
                medium
              />
              {selected?.accesories?.description && (
                <div className={"category-detail-section"}>
                  <ChangelogCard
                    statusText={
                      <span style={{ fontWeight: "bold" }}>
                        Accessories description
                      </span>
                    }
                    date={new Date().toDateString()}
                    byUser={""}
                    description={selected.accesories.description}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid xs={4} item>
            <div>
              <ItemDetailTextHeader
                text={`Condition: ${selected.condition?.name}`}
                medium
              />
              <div className={"category-detail-section"}>
                {selected.conditionNote.length ? (
                  <ChangelogList
                    list={selected.conditionNote}
                    className={"category-changelog-list"}
                  />
                ) : (
                  <Typography className={"item-detail-description"}>
                    No condition notes were specified.
                  </Typography>
                )}
              </div>
            </div>
            <div className={"category-detail-section"}>
              <ItemDetailTextHeader
                text={`Tested: ${selected.tested ? "Yes" : "No"}`}
                medium
              />
              <div className={"category-detail-section"}>
                {selected.tested ? (
                  <ChangelogCard
                    statusText={
                      <span>
                        <span style={{ fontWeight: "bold" }}>Test notes</span>{" "}
                        posted{" "}
                      </span>
                    }
                    date={new Date().toDateString()}
                    byUser={"Admin"}
                    description={selected.testNote}
                  />
                ) : (
                  <Typography className={"item-detail-description"}>
                    No test notes were specified.
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <ItemDetailTextHeader text={"Specify services"} medium />
            </div>
            <div className={"category-detail-section"}>
              <Typography className={"item-detail-description"}>
                {parseServicesString()}
              </Typography>
            </div>
            <div className={"category-detail-section"}>
              <ChangelogList
                list={selected.specifyServices.description}
                className={"category-changelog-list"}
              />
            </div>
          </Grid>
        </Grid>
        <ItemSectionDivider />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>
              <ItemDetailTextHeader text={"Subcomponents"} medium />
            </div>
            <div className={"category-detail-section"}>
              {selected?.subcomponents?.length ? (
                <SubcomponentsList list={selected.subcomponents} />
              ) : (
                <Typography className={"item-detail-description"}>
                  No subcomponents
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={9}>
            <ItemListingsList selected={selected} />
          </Grid>
        </Grid>
      </Grid>
    </ScreenLayout>
  ) : (
    <CircularProgress color={"secondary"} />
  );
};

export default InventoryItemDetail;
