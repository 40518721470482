import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  DialogContent,
  DialogTitle,
  Grid,
  Dialog,
  DialogActions,
  IconButton,
  Button,
} from "@material-ui/core";

import Icon from "components/Icon";
import TableText from "components/TableText";
import ModalActions from "components/ModalActions";
import ModalWithOpener from "components/ModalWithOpener";
import SelectWrapper from "components/SelectWrapper";

import { saveItemStatus } from "store/item/actions";

const TableItemStatus = ({ item }) => {
  const dispatch = useDispatch();

  const { statusList } = useSelector(({ itemReducer }) => itemReducer);

  const [statusId, setstatusId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeAlert = () => {
    setAlert(false);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const onChangeSelect = (value) => {
    setstatusId(value);
  };

  const saveItem = () => {
    if (item.status?._id === statusId) {
      setAlert(true);
    } else if (statusId === -1) {
      setAlert(true);
    } else if (statusId === null) {
      setAlert(true);
    } else if (statusId === undefined) {
      setAlert(true);
    } else {
      dispatch(saveItemStatus(item.id, statusId));
    }
  };

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid
          xs={11}
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="containerStatusItem"
        >
          <TableText
            accessor={"status"}
            item={{ status: item.status?.name || "" }}
          />
          <Grid xs={1} item>
            <ModalWithOpener
              OpenerComponent={
                <IconButton onClick={openModal}>
                  <Icon
                    name={"edit"}
                    color={"black"}
                    classnames={"item-table-action-icon"}
                    fontSize={18}
                  />
                </IconButton>
              }
              open={isOpen}
              onClose={closeModal}
            >
              <>
                <DialogTitle>Change item status</DialogTitle>
                <DialogContent style={{ margin: 24 }}>
                  <SelectWrapper
                    defaultValue={item.status?.id || ""}
                    label={item.status?.name || ""}
                    onChange={onChangeSelect}
                    margin="dense"
                    fullWidth
                    options={statusList}
                    valueAccessor={"_id"}
                    labelAccessor={"name"}
                  />
                </DialogContent>

                <ModalActions
                  closeModalText={"cancel"}
                  closeModal={closeModal}
                  onSubmitActionText={"Save Item"}
                  onClick={saveItem}
                />
              </>
            </ModalWithOpener>
          </Grid>
        </Grid>
      </Grid>
      {alert && (
        <Dialog open={alert} onClose={closeAlert}>
          <DialogTitle>Select a different status</DialogTitle>
          <DialogContent>
            The selected status already belongs to the item. choose a different
            one
          </DialogContent>
          <DialogActions>
            <Button color={"secondary"} onClick={closeAlert}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

TableItemStatus.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableItemStatus;
