import React from "react";
import {useDispatch} from "react-redux";

import { Button } from "@material-ui/core";

import Icon from "components/Icon";
import ModalConfirm from "components/ModalConfirm";

import { deleteRow } from "store/warehouse/actions";

const DeleteRowButton = ({ row }) => {
  const dispatch = useDispatch();

  const deleteThisRow = (event) => {
    event.stopPropagation();

    dispatch(deleteRow(row._id));
  };

  return (
    <ModalConfirm
      message={"Are you sure that you want to delete this row?"}
      actionOnAccept={deleteThisRow}
      modalTitle={`Delete row ${row.name}?`}
      actionOnAcceptText={"Delete row"}
      Component={(props) => (
        <Button
          {...props}
          className={"row-actions-button"}
          color={"secondary"}
          startIcon={<Icon name={"trash-can"} />}
        >
          Delete row
        </Button>
      )}
    />
  );
};

DeleteRowButton.propTypes = {};

export default DeleteRowButton;
