import React from "react";
import PropTypes from "prop-types";

import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";

/**
 * Card container component
 *
 * @param cardTitle
 * @param children
 * @param cardActions
 * @returns {JSX.Element}
 * @constructor
 */
const CardContainer = ({ cardTitle, children, cardActions, ...props }) => {
  return (
    <Card {...props}>
      {cardTitle && <CardHeader title={cardTitle} /> }
      <CardContent>{children}</CardContent>
      {cardActions && <CardActions>{cardActions}</CardActions>}
    </Card>
  );
};

CardContainer.propTypes = {
  cardTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  cardActions: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default CardContainer;
