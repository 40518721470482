import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";

const FormRadios = ({ options, field, label, form, onChangeRadio, horizontal = false }) => {
  const [selectedOption, setSelectedOption] = useState(field.value);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    form.setFieldValue(field.name, event.target.value);

    if (onChangeRadio) onChangeRadio(event.target.value);
  };

  return (
    <FormFieldWrapper name={field.name}>
      <>
        <FormLabel htmlFor={field.name}>{label}</FormLabel>
        <RadioGroup onChange={handleRadioChange} value={selectedOption} style={{flexDirection: horizontal ? 'row' : 'column'}}>
          {options.map((option) => (
            <FormControlLabel
              key={option.key}
              value={option.key}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </>
    </FormFieldWrapper>
  );
};

FormRadios.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default FormRadios;
