import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import { TextField } from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";

import "./styles.scss";

const FormInput = ({
  field,
  initialValue,
  externalLabel,
  dependsOnValue = "",
  attributeToChange = "",
  habilitateAttributeValues = [],
  required = false,
  ...props
}) => {
  const form = useFormikContext();
  const { errors, touched, setFieldValue, values } = form;

  // Allow inclusion of attributes as props depending on certain form value
  const conditions = dependsOnValue
    ? {
        [attributeToChange]:
          habilitateAttributeValues.find(
            (habilitatedValue) => habilitatedValue === values[dependsOnValue]
          ) !== undefined,
      }
    : {};

  useEffect(() => {
    if (initialValue) {
      setFieldValue(field.name, initialValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = (event) => {
    setFieldValue(field.name, event.target.value);
  };

  const CustomTextField = (
    <TextField
      {...props}
      {...field}
      {...conditions}
      onChange={onChange}
      label={externalLabel ? props.placeholder : props.label}
      value={field.value || ""}
      className={"form-input"}
      color={"secondary"}
      error={props.error || !!(touched[field.name] && errors[field.name])}
      variant={externalLabel ? "outlined" : props.variant}
      fullWidth={externalLabel || props?.fullWidth}
      helperText={
        props.helperText ||
        (!externalLabel &&
          touched[field.name] &&
          errors[field.name] &&
          errors[field.name])
      }
    />
  );

  return externalLabel ? (
    <FormFieldWrapper name={field.name} label={props.label} required={required}>
      {CustomTextField}
    </FormFieldWrapper>
  ) : (
    <div className={"form-input-container"}>{CustomTextField}</div>
  );
};

FormInput.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  label: PropTypes.string.isRequired,
  props: PropTypes.object,
};

export default FormInput;
