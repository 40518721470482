import {CURRENT_YEAR} from "lib/date/constants";

export const YEARS_LIST = [...[...Array(70).keys()].map((i) => ({
  value: (CURRENT_YEAR - i).toString(),
  label: (CURRENT_YEAR - i).toString(),
})), {value: "", label: ""}];

export const UNDECISIVE_OPTIONS = [
  {
    label: "Yes",
    value: "yes",
  },
  { label: "No", value: "no" },
  { label: "I don't know", value: "idk" },
];