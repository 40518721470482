import React from "react";
import PropTypes from "prop-types";

import { Alert } from "@material-ui/lab";

import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARNING,
} from "components/MessageService/constants";

const AlertContent = ({ children, ...props }) => {
  return <Alert {...props}>{children}</Alert>;
};

AlertContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  severity: PropTypes.oneOf([
    ALERT_SEVERITY_ERROR,
    ALERT_SEVERITY_SUCCESS,
    ALERT_SEVERITY_WARNING,
    ALERT_SEVERITY_INFO,
  ]).isRequired,
};

export default AlertContent;
