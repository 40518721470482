import React from "react";

import { AppBar, Button, Typography } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

import DrawerContainer from "components/DrawerContainer";
import HeaderActionsList from "components/HeaderToolbar/components/HeaderActionsList";
import LinkDropdownContainer from "components/LinkDropdownContainer";
import HeaderLink from "components/HeaderToolbar/components/HeaderLink";
import GoogleLogoutButton from "components/GoogleLogoutButton";

import toolbarRoutes from "app/routes";

import "./styles.scss";
import AuthWrapper from "components/AuthWrapper";

const HeaderToolbar = () => {
  return (
    <AppBar>
      <div className="header-toolbar">
        <div className="header-toolbar__menu-opener">
          <DrawerContainer
            openFrom={"left"}
            OpenerComponent={(props) => (
              <Button {...props}>
                <Menu />
              </Button>
            )}
          >
            <HeaderActionsList list={toolbarRoutes} />
          </DrawerContainer>
        </div>
        <div className="header-toolbar__title">
          <Typography variant="h2">AFAB</Typography>
        </div>
        <div className={"header-links header-links--left-side"}>
          {toolbarRoutes.map((route) =>
            route.type === "dropdown" ? (
              <LinkDropdownContainer
                key={route.name}
                links={route.links}
                OpenerComponent={({ thisRef, ...props }) => (
                  <HeaderLink route={route} thisRef={thisRef} {...props} />
                )}
              />
            ) : (
              <AuthWrapper permission={route.permission} key={route.name}>
                <HeaderLink route={route} />
              </AuthWrapper>
            )
          )}
        </div>
        <div className="header-links header-links--right-side">
          <GoogleLogoutButton />
        </div>
      </div>
    </AppBar>
  );
};

export default HeaderToolbar;
