import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";

import { ITEM_DETAIL_SECTIONS } from "lib/object/constants";

const HistoricMovements = ({ item }) => {
  const processedItems = (item.propertiesChanged || []).filter(
    (item) => item !== "__v" && item !== "modifiedBy" && item !== "modified"
  );

  const Movements = ({ itemKey, type }) => {
    const formattedType = ITEM_DETAIL_SECTIONS[itemKey];

    return (
      <Typography>
        {type} <span style={{ fontWeight: "bold" }}>{formattedType}</span>
      </Typography>
    );
  };

  return processedItems.map((procesedItem) => (
    <Movements
      key={procesedItem}
      type={item.modificationType}
      itemKey={procesedItem}
    />
  ));
};

HistoricMovements.propTypes = {
  item: PropTypes.object.isRequired,
};

export default HistoricMovements;
