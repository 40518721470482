import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import './ItemDetailTextHeader.scss'

const ItemDetailTextHeader = ({ text, small, medium, large, bold=true, ...props }) => {
  return (
    <span
      className={classnames({
        "item-detail-text-header": true,
        "item-detail-text-header--bold": bold,
        "item-detail-text-header--small": small,
        "item-detail-text-header--medium": medium,
        "item-detail-text-header--large": large,
      })}
      {...props}
    >
      {text}
    </span>
  );
};

ItemDetailTextHeader.propTypes = {
  text: PropTypes.string.isRequired,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
};

export default ItemDetailTextHeader;
