import ItemCreateEdit from "screens/ItemCreateEdit";
import InventoryItems from "screens/InventoryItems";
import InventoryItemDetail from "screens/InventoryItemDetail";
import ItemHistory from "screens/ItemHistory";

const itemsRoutes = [
  {
    name: "ItemCreate",
    path: "/inventory/items/create",
    component: ItemCreateEdit,
    exact: true,
    featureKey: "ITEMS.ITEMS_CREATE",
  },
  {
    name: "ItemCreateFromProduct",
    path: "/inventory/categories/:categoryId/items/create",
    component: ItemCreateEdit,
    exact: true,
    featureKey: "ITEMS.ITEMS_CREATE",
  },
  {
    name: "ProductItemsList",
    path: "/inventory/categories/:categoryId/items",
    component: InventoryItems,
    exact: true,
    featureKey: "ITEMS.ITEMS_READ",
  },
  {
    name: "InventoryItemsList",
    path: "/inventory/items",
    component: InventoryItems,
    exact: true,
    featureKey: "ITEMS.ITEMS_READ",
  },
  {
    name: "InventoryItemDetail",
    path: "/inventory/items/detail/:id",
    component: InventoryItemDetail,
    exact: true,
    featureKey: "ITEMS.ITEMS_READ_DETAIL",
  },
  {
    name: "InventoryItemUpdate",
    path: "/inventory/items/edit/:id",
    component: ItemCreateEdit,
    exact: true,
    featureKey: "ITEMS.ITEMS_UPDATE"
  },
  {
    name: "InventoryItemUpdate",
    path: "/inventory/items/clone/:id",
    component: ItemCreateEdit,
    exact: true,
    featureKey: "ITEMS.ITEMS_CREATE"
  },
  {
    name: "InventoryItemHistory",
    path: "/inventory/items/history/:id",
    component: ItemHistory,
    exact: true,
    featureKey: "ITEMS.ITEMS_READ_DETAIL_HISTORY"
  }
];

export default itemsRoutes;
