import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import ScreenLayout from "components/ScreenLayout";
import UsersTable from "screens/Users/components/UsersTable";

import usersHeaderComponents from "screens/Users/components/usersHeaderComponents";

import { fetchUserRoles } from "store/user/actions";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, [dispatch]);

  return (
    <ScreenLayout headerComponents={usersHeaderComponents}>
      <UsersTable />
    </ScreenLayout>
  );
};

export default Users;
