import React from "react";
import PropTypes from "prop-types";

import { Grid, IconButton } from "@material-ui/core";

import ItemDetailTextHeader from "screens/InventoryItemDetail/components/ItemDetailTextHeader";
import Icon from "components/Icon";
import TooltipWrapper from "components/TooltipWrapper";

import { formatCurrency } from "lib/number/format";

const PricingDetail = ({ pricingKey, item, priceName, withTooltip }) => {
  return (
    <Grid container alignItems={"center"}>
      <Grid xs={6} item>
        <ItemDetailTextHeader text={priceName} medium />
      </Grid>
      <Grid xs={6} style={{ textAlign: "end" }} item>
        <Grid container alignItems={'center'} justify={'flex-end'}>
          {withTooltip && item?.description && (
            <Grid item xs={3}>
              <TooltipWrapper
                children={
                  <IconButton>
                    <Icon name={"info"} fontSize={14} color={'#9E9E9E'} />
                  </IconButton>
                }
                messageToDisplay={item.description}
              />
            </Grid>
          )}
          <Grid item>
            <ItemDetailTextHeader
              text={`$ ${formatCurrency(item?.[pricingKey] || 0)}`}
              medium
              bold={pricingKey === "finalPrice"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PricingDetail.propTypes = {
  item: PropTypes.object,
  pricingKey: PropTypes.string.isRequired,
  priceName: PropTypes.string.isRequired,
};

export default PricingDetail;
