import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Percentage = ({percentage, description}) => {
  return (
    <div className={'percentage'}>
      <span className={'percentage__amount'}>{percentage || 0}%</span>
      <div className={'percentage__description'}>{description}</div>
    </div>
  );
};

Percentage.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Percentage;