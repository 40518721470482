import React  from "react";
import PropTypes from "prop-types";

import { Checkbox, TableCell } from "@material-ui/core";

const TableCheckbox = ({ action, itemId, isSelected = false }) => {
  const labelId = `enhanced-table-checkbox-${itemId}`;

  const handleCheckClick = () => {
    action(itemId);
  };

  return (
    <TableCell>
      <Checkbox
        checked={isSelected}
        onChange={handleCheckClick}
        inputProps={{ "aria-labelledby": labelId }}
      />
    </TableCell>
  );
};

TableCheckbox.propTypes = {
  action: PropTypes.func.isRequired,
  itemId: PropTypes.any.isRequired,
};

export default TableCheckbox;
