import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Typography } from "@material-ui/core";

import DrawerContainer from "components/DrawerContainer";
import ItemsList from "screens/WarehouseDetail/components/open-spaces/ItemsList";

import { unsetSelectedOpenSpace } from "store/open-space/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  "open-space-items-list-title": {
    fontWeight: "bold",
    fontSize: 16,
  },
  "open-space-items-list-subtitle": {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
  "open-space-list-section": {
    marginTop: 10,
    marginBottom: 10,
  },
  "open-space-items-list--empty": {
    width: "100%",
  },
  "open-space-items-list-drawer": {
    width: 300,
    padding: 20,
  },
  "list-loading-open-space": {
    textAlign: "center",
  },
});

const OpenSpaceItemsDrawer = ({ openSpace }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { selected: selectedOpenSpace, isFetching } = useSelector(
    ({ openSpaceReducer }) => openSpaceReducer
  );

  const openSpaceHeaderConfig = {
    title: (
      <span>
        Elements in Open Space{" "}
        <span className={styles["open-space-items-list-title"]}>
          {openSpace?.name}
        </span>
      </span>
    ),
  };

  const unsetOpenSpace = () => {
    dispatch(unsetSelectedOpenSpace());
  };

  return (
    <DrawerContainer
      headerConfig={openSpaceHeaderConfig}
      actionAfterClosed={unsetOpenSpace}
      OpenerComponent={(props) => <Typography {...props}>Items</Typography>}
      openFrom={"right"}
    >
      <div className={styles["open-space-items-list-drawer"]}>
        {isFetching && !selectedOpenSpace && (
          <div className={styles["list-loading-open-space"]}>
            <CircularProgress color={"secondary"} size={25} />
          </div>
        )}
        {!isFetching && (
          <>
            <div className={styles["open-space-list-section"]}>
              <span className={styles["open-space-items-list-subtitle"]}>
                Items
              </span>
              {openSpace?.items?.length ? (
                <ItemsList itemId={openSpace._id} type={"items"} />
              ) : (
                <Typography className={styles["open-space-items-list--empty"]}>
                  No items to display.
                </Typography>
              )}
            </div>
            <div className={styles["open-space-list-section"]}>
              <span className={styles["open-space-items-list-subtitle"]}>
                Containers
              </span>
              {openSpace?.containers?.length ? (
                <ItemsList itemId={openSpace._id} type={"containers"} />
              ) : (
                <Typography className={styles["open-space-items-list--empty"]}>
                  No containers to display.
                </Typography>
              )}
            </div>
          </>
        )}
      </div>
    </DrawerContainer>
  );
};

OpenSpaceItemsDrawer.propTypes = {
  openSpace: PropTypes.object.isRequired,
};

export default OpenSpaceItemsDrawer;
