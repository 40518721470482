import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";

import { Typography } from "@material-ui/core";

import TableLayout from "components/TableLayout";
// import TableText from "components/TableText";
import EditItemButton from "screens/InventoryItems/components/EditItemButton";
// import Icon from "components/Icon";
import ItemQRCode from "screens/InventoryItems/components/ItemQRCode";
import LinkToItemHistory from "screens/InventoryItems/components/LinkToItemHistory";
// import ItemTableLink from "screens/InventoryItems/components/ItemTableLink";
// import ItemGallery from "screens/InventoryItems/components/ItemGallery";
// import TableDate from "components/TableDate";
// import TableCurrency from "components/TableCurrency";
// import ItemCategory from "screens/InventoryItems/components/ItemCategory";
import CloneItemButton from "screens/InventoryItems/components/CloneItemButton";
// import TableItemListing from "screens/InventoryItems/components/TableItemListing";
// import TableItemStatus from "screens/InventoryItems/components/TableItemStatus";
import { baseUrl, headers } from "../../../lib/api/constants";
import { fetchItemsList, setCheckedItems } from "store/item/actions";
// import TableItemCategory from "./TableItemCategory";
import axios from "axios";
const ItemsTable = ({ columns }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const { list, count, page } = useSelector(({ itemReducer }) => itemReducer);
  // const { selected: selectedCategory } = useSelector(
  //   ({ categoryReducer }) => categoryReducer
  // );
  const { loading } = useSelector(({ helperReducer }) => helperReducer);

  const [selectedItems, setSelectedItems] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  // const [columnsActive, setColumnsActive] = useState([]);

  async function getCategories() {
    const r = await axios
      .get(`${baseUrl}/categorieslist`, {
        method: "GET",
        headers,
      })
      .catch((err) => console.log(err));

    if (r?.data) {
      setListCategories(r.data.category);
    }
  }

  useEffect(() => {
    let abortController = new AbortController();
    if (listCategories.length === 0) {
      getCategories();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const searchParams = queryString.parse(location.search);

  useEffect(() => {
    let queryObject = searchParams;

    if (params.categoryId) {
      queryObject = {
        ...queryObject,
        category: params.categoryId,
      };
    }

    dispatch(fetchItemsList(queryObject));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, dispatch]);

  // const columns = [
  //   {
  //     name: "imageUrl",
  //     displayName: <Icon name={"image"} classnames={"product-table-icon"} />,
  //     props: {
  //       width: 70,
  //       style: { textAlign: "center" },
  //     },
  //     Component: ItemGallery,
  //     status: true,
  //   },
  //   {
  //     name: "name",
  //     displayName: "Name",
  //     Component: ItemTableLink,
  //     props: {
  //       width: 120,
  //       style: {
  //         textAlign: "left",
  //         overflow: "auto",
  //         marginLeft: 20,
  //         marginRight: 20,
  //       },
  //     },
  //     componentProps: {
  //       accessor: "name",
  //     },
  //     shouldSort: true,
  //     status: true,
  //   },
  //   {
  //     name: "serialNumber",
  //     displayName: "Serial no.",
  //     props: {
  //       width: 140,
  //       style: { textAlign: "center" },
  //     },
  //     Component: ItemTableLink,
  //     componentProps: {
  //       accessor: "serialNumber",
  //       defaultValue: "N/A",
  //     },
  //     shouldSort: true,
  //     status: true,
  //   },
  //   {
  //     name: "number",
  //     displayName: "SKU",
  //     numeric: true,
  //     props: {
  //       width: 80,
  //       style: { textAlign: "center" },
  //     },
  //     componentProps: {
  //       accessor: "number",
  //     },
  //     Component: ItemTableLink,
  //     shouldSort: true,
  //     status: true,
  //   },
  //   {
  //     name: "manufacturer",
  //     displayName: "Manufacturer",
  //     props: {
  //       width: 150,
  //       style: {
  //         textAlign: "left",
  //         overflow: "auto",
  //       },
  //     },
  //     Component: ItemTableLink,
  //     componentProps: {
  //       accessor: "manufacturer",
  //     },
  //     shouldSort: true,
  //     status: true,
  //   },
  //   {
  //     name: "category",
  //     displayName: "Category",
  //     props: {
  //       width: 150,
  //       style: { textAlign: "right" },
  //     },
  //     // Component: ItemCategory,
  //     Component: ({ item }) => (
  //       <TableItemCategory item={item} listCategories={listCategories} />
  //     ),
  //     shouldSort: true,
  //     shouldBeHidden: !!selectedCategory,
  //     status: true,
  //   },

  //   {
  //     name: "location",
  //     displayName: "Location",
  //     props: {
  //       width: 170,
  //       style: {
  //         textAlign: "center",
  //         overflow: "auto",
  //         marginLeft: 20,
  //         marginRight: 20,
  //       },
  //     },
  //     Component: TableText,
  //     shouldSort: true,
  //     status: true,
  //   },
  //   {
  //     name: "dateOfAdquisition",
  //     displayName: "Acquisition date",
  //     Component: TableDate,
  //     componentProps: {
  //       accessor: "dateOfAdquisition",
  //     },
  //     shouldSort: true,
  //     numeric: true,
  //     props: {
  //       width: 180,
  //     },
  //     status: true,
  //   },
  //   {
  //     name: "source",
  //     displayName: "Source",
  //     Component: ItemTableLink,
  //     shouldSort: true,
  //     componentProps: {
  //       accessor: "source",
  //     },
  //     props: {
  //       width: 140,
  //       style: { textAlign: "center" },
  //     },
  //     status: true,
  //   },
  //   {
  //     name: "status",
  //     displayName: "Status",
  //     Component: ({ item }) => <TableItemStatus item={item} />,
  //     props: {
  //       width: 230,
  //       style: { textAlign: "right" },
  //     },
  //     shouldSort: true,
  //     status: true,
  //   },

  //   {
  //     name: "listings",
  //     displayName: "Listings",
  //     Component: TableItemListing,
  //     props: {
  //       width: 140,
  //     },
  //   },
  //   {
  //     name: "condition",
  //     displayName: "Condition",
  //     Component: ({ item }) => (
  //       <TableText
  //         accessor={"condition"}
  //         item={{
  //           condition: item.condition ? item.condition.name : "N/A",
  //         }}
  //       />
  //     ),
  //     props: {
  //       width: 170,
  //     },
  //     shouldSort: true,
  //     status: true,
  //   },
  //   {
  //     name: "cost",
  //     displayName: "Cost",
  //     Component: TableCurrency,
  //     numeric: true,
  //     props: {
  //       width: 140,
  //       style: { textAlign: "center" },
  //     },
  //     shouldSort: true,
  //     permission: "ITEMS.ITEMS_READ_DETAIL_PRICING",
  //     status: true,
  //   },
  //   {
  //     name: "finalPrice",
  //     displayName: "Final price",
  //     Component: TableCurrency,
  //     numeric: true,
  //     props: {
  //       width: 140,
  //       style: { textAlign: "center" },
  //     },
  //     shouldSort: true,
  //     permission: "ITEMS.ITEMS_READ_DETAIL_PRICING",
  //     status: true,
  //   },
  // ];

  const handleItemSelect = (itemId) => {
    if (isSelected(itemId)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemId));
      dispatch(
        setCheckedItems(selectedItems.filter((item) => item !== itemId))
      );
    } else {
      setSelectedItems([...selectedItems, itemId]);
      dispatch(setCheckedItems([...selectedItems, itemId]));
    }
  };

  const isSelected = (itemId) =>
    !!selectedItems.find((item) => item === itemId);

  return (
    <TableLayout
      stickyHeader
      onSelectRowAction={handleItemSelect}
      isSelected={isSelected}
      loading={loading}
      actionCellProps={{ width: 200 }}
      className={"product-items-table"}
      containerClassName={"product-items-table-container"}
      actions={[
        { Component: EditItemButton, permission: "ITEMS.ITEMS_CREATE" },
        {
          Component: LinkToItemHistory,
          permission: "ITEMS.ITEMS_READ_DETAIL_HISTORY",
        },
        { Component: ItemQRCode, permission: "WILDCARD" },
        { Component: CloneItemButton, permission: "ITEMS.ITEMS_CREATE" },
      ]}
      data={list}
      columns={columns}
      pagination={{ count, page }}
      noItemsMessage={<Typography>No items to display</Typography>}
    />
  );
};

export default ItemsTable;
