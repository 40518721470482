import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./index.scss";

const Icon = ({
  name,
  type = "regular",
  brand,
  fontSize,
  classnames,
  color,
  style = {},
  ...props
}) => {
  return (
    <span
      style={{
        ...style,
        fontSize,
        color,
      }}
      className={classNames({
        [`icon ${name}`]: !brand,
        [`icon--brand ${name}`]: !!brand,
        [`icon--solid ${name}`]: type === "solid",
        [classnames]: !!classnames,
      })}
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  classnames: PropTypes.string,
};

export default Icon;
