import React from "react";
import PropTypes from "prop-types";
import ModalConfirm from "components/ModalConfirm";
import { Button } from "@material-ui/core";

const ConfirmLocationSavingModal = ({ onClick, ...props }) => {
  return (
    <ModalConfirm
      Component={(props) => (
        <Button color="secondary" variant={"contained"} {...props}>
          Save location
        </Button>
      )}
      actionOnAccept={onClick}
      modalTitle={"Container is full"}
      message={
        "The container you have selected is marked as full. Do you want to use it anyway?"
      }
      actionOnAcceptText={"Yes, save location"}
      acceptButtonProps={props}
    />
  );
};

ConfirmLocationSavingModal.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ConfirmLocationSavingModal;
