import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Switch } from "@material-ui/core";

import { SUCCESS_STATUS } from "components/MessageService/constants";

import { saveUser } from "store/user/actions";

const UserActiveSwitch = ({ item }) => {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isActive, setIsActive] = useState(item.active);
  const [isLoading, setIsLoading] = useState(false);

  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  useEffect(() => {
    if (messagePayload) {
      toggleSwitch(messagePayload.type === SUCCESS_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload]);

  const handleActiveStatus = () => {
    setSelectedItem(item._id);
    setIsLoading(true);

    dispatch(saveUser({ ...item, active: !isActive }, item._id, false));
  };

  const toggleSwitch = (success) => {
    if (selectedItem === item._id) {
      setIsLoading(false);
      setSelectedItem(null);

      if (success) {
        setIsActive((isActive) => !isActive);
      }
    }
  };

  return (
    <Switch
      checked={isActive}
      onChange={handleActiveStatus}
      disabled={isLoading}
    />
  );
};

UserActiveSwitch.propTypes = {
  item: PropTypes.object.isRequired,
};

export default UserActiveSwitch;
