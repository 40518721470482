import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, ListSubheader } from "@material-ui/core";

/**
 * Component to load selects
 * An options array must be provided (could have any format), and key accessor for value and label.
 *
 * e.g.: array with format [{name: "", _id: "", ...}] could be provided with
 * valueAccessor = "_id" and labelAccessor = "name"
 *
 * There's no default values for valueAccessor and labelAccessor, therefore, within options array, are the mandatory params.
 *
 * @param options
 * @param label
 * @param valueAccessor
 * @param customValue
 * @param labelAccessor
 * @param onChange
 * @param customCondition
 * @param hasGroups
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectWrapper = ({
  options,
  label,
  onChange,
  customValue,
  valueAccessor = "value",
  labelAccessor = "label",
  customCondition = true,
  hasGroups = false,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(
    props?.defaultValue || "-1"
  );

  const onChangeSelect = (event) => {
    // Handle group option selection
    if (hasGroups) {
      onChange({
        value: event.target.value,
        group: event.nativeEvent.target.dataset.group,
      });
    } else {
      onChange(event.target.value, event.nativeEvent.target.dataset.itemdata);
    }

    setSelectedValue(event.target.value);
  };

  const parsedOptions = () => {
    if (hasGroups) {
      return options
        .map((item) => [
          { type: item.type, categoryName: item.categoryName },
          ...item.options.map((option) => ({ ...option, type: item.type })),
        ])
        .flat();
    }

    return options;
  };

  return options && options.length && customCondition ? (
    <Select
      onChange={onChangeSelect}
      value={customValue || selectedValue}
      variant={"outlined"}
      label={label}
      {...props}
    >
      <MenuItem value="-1">{label}</MenuItem>
      {parsedOptions().map((item) =>
        item.categoryName ? (
          <ListSubheader key={item.type}>{item.categoryName}</ListSubheader>
        ) : (
          <MenuItem
            data-itemdata={JSON.stringify(item)}
            data-group={item.type}
            value={item[valueAccessor]}
            key={item[valueAccessor]}
          >
            {item[labelAccessor]}
          </MenuItem>
        )
      )}
    </Select>
  ) : null;
};

SelectWrapper.propTypes = {
  options: PropTypes.array,
  valueAccessor: PropTypes.string,
  labelAccessor: PropTypes.string,
  customCondition: PropTypes.any,
};

export default SelectWrapper;
