import React from 'react';
import PropTypes from 'prop-types';

import {Grid} from "@material-ui/core";

import ItemDetailTextHeader from "screens/InventoryItemDetail/components/ItemDetailTextHeader";
import Percentage from "components/Percentage";

const DiscountListing = ({selected}) => {
  return (
    <div className={"category-detail-section"}>
      <ItemDetailTextHeader text={"Discounts allowed"} bold={true} />
      <div className={"category-detail-section"}>
        <Grid container>
          <Grid item xs={4}>
            <Percentage
              description={"30 days"}
              percentage={selected?.discountAllowed?.thirtyDaysOfPosted}
            />
          </Grid>
          <Grid item xs={4}>
            <Percentage
              description={"90 days"}
              percentage={selected?.discountAllowed?.ninetyDaysOfPosted}
            />
          </Grid>
          <Grid item xs={4}>
            <Percentage
              description={"180 days"}
              percentage={
                selected?.discountAllowed?.oneHundredEightyDaysOfPosted
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

DiscountListing.propTypes = {
  selected: PropTypes.object.isRequired
};

export default DiscountListing;