import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Typography } from "@material-ui/core";

import ScreenLayout from "components/ScreenLayout";
import LayoutHeaderTitle from "components/LayoutHeaderTitle";
import InnerPageSection from "components/InnerPageSection";
import FormStepperWrapper from "components/FormStepperWrapper";

import itemInfoForm from "screens/ItemCreateEdit/components/itemInfoForm";
import itemTechnicalSpecsForm from "screens/ItemCreateEdit/components/itemTechnicalSpecsForm";
import itemPricingForm from "screens/ItemCreateEdit/components/itemPricingForm";
import itemListingsForm from "screens/ItemCreateEdit/components/itemListingsForm";
import itemSubcomponentsForm from "screens/ItemCreateEdit/components/itemSubcomponentsForm";

import {
  fetchItemConditions,
  fetchItemStatuses,
  fetchItemToClone,
  fetchSingleItem,
  saveItem,
  unsetSelectedItem,
} from "store/item/actions";
import {
  fetchSingleCategory,
  setSelectedCategory,
} from "store/category/actions";
import {
  ERROR_STATUS_NOT_FOUND,
  SUCCESS_STATUS,
} from "components/MessageService/constants";

import { useHasPermission } from "lib/hooks/permissions";

import "./styles.scss";

const ItemCreateEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [viewAction, setViewAction] = useState("");

  const { statusList, conditionsList, selected } = useSelector(
    ({ itemReducer }) => itemReducer
  );
  const { selected: selectedCategory } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );
  const { messagePayload } = useSelector(({ helperReducer }) => helperReducer);

  const submitItem = (values) => {
    dispatch(saveItem(values, viewAction === "clone"));
  };

  useEffect(() => {
    if (params.categoryId) {
      dispatch(fetchSingleCategory(params.categoryId));
    }

    if (!viewAction) {
      if (location.pathname.includes("clone")) {
        setViewAction("clone");
        dispatch(unsetSelectedItem());
      } else {
        setViewAction("edit");
      }
    }

    if (selected) {
      dispatch(setSelectedCategory(selected.category));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.categoryId, params.id, dispatch, selected]);

  useEffect(() => {
    if (viewAction.length) {
      if (viewAction === "clone") {
        if (!selected) {
          dispatch(fetchItemToClone(params.id));
        }
      } else {
        if (!selected) {
          dispatch(fetchSingleItem(params.id));
        }
      }
    }
  }, [dispatch, viewAction, selected, params.id]);

  // Basic handling for not existing routes.
  useEffect(() => {
    const handleNavigation = () => {
      if (messagePayload) {
        if (
          messagePayload.type === ERROR_STATUS_NOT_FOUND ||
          messagePayload.type === SUCCESS_STATUS
        ) {
          if (params.categoryId) {
            history.push(`/inventory/categories/${params.categoryId}/items`);
          } else {
            if (viewAction === "clone") {
              history.push(`/inventory/items/detail/${selected._id}`);
            } else {
              history.push(`/inventory/items`);
            }
          }
        }
      }
    };

    handleNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagePayload]);

  useEffect(() => {
    if (!statusList || statusList.length === 0) {
      dispatch(fetchItemStatuses());
    }

    if (!conditionsList || conditionsList.length === 0) {
      dispatch(fetchItemConditions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasPricingPermission = useHasPermission("ITEMS.ITEMS_UPDATE_PRICING");

  const ItemEditSteps = (selected) =>
    [
      itemInfoForm(statusList, selectedCategory, selected),
      itemSubcomponentsForm(selected),
      itemTechnicalSpecsForm(conditionsList, statusList, selected),
      hasPricingPermission ? itemPricingForm(statusList, selected) : null,
      itemListingsForm(selected),
    ].filter((step) => !!step);

  const ItemCreateSteps = () =>
    [
      itemInfoForm(statusList, selectedCategory),
      itemSubcomponentsForm(selected),
      itemTechnicalSpecsForm(conditionsList, statusList),
      hasPricingPermission ? itemPricingForm(statusList) : null,
      itemListingsForm(),
    ].filter((step) => !!step);

  return (
    <ScreenLayout
      headerComponents={[
        <LayoutHeaderTitle
          titleComponent={
            <Typography variant={"h1"}>
              <span>
                {selected ? (viewAction === "clone" ? "Clone" : "Edit") : "Add"}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {selected ? selected.name : ""}
                </span>{" "}
                item
              </span>{" "}
              {selectedCategory && (
                <>
                  {selected ? "in" : "to"}{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {selectedCategory.name}
                  </span>{" "}
                  category
                </>
              )}{" "}
            </Typography>
          }
        />,
      ]}
    >
      <InnerPageSection
        sectionTitleComponent={
          <div className={"item-create-edit-header"}>
            <h3>Item details</h3>
          </div>
        }
      >
        {selected && params.id && (
          <FormStepperWrapper
            onSubmit={submitItem}
            cancelActionText={"Cancel"}
            submitActionText={"Save item"}
            steps={ItemEditSteps(selected)}
            shouldDisplaySaveButton
          />
        )}
        {!params.id && (
          <FormStepperWrapper
            onSubmit={submitItem}
            cancelActionText={"Cancel"}
            submitActionText={"Save item"}
            steps={ItemCreateSteps()}
          />
        )}
      </InnerPageSection>
    </ScreenLayout>
  );
};

export default ItemCreateEdit;
