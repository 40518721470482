export const ITEM_DETAIL_SECTIONS = {
  yearManufactured: "Year manufactured",
  dateOfAdquisition: "Acquisition date",
  source: "Source",
  serialNumber: "S/N",
  location: "Location",
  listedOnOutsideRepSite: "Listing at outside rep. site",
  listedOnWebsite: "Listing at website",
  listedOnEbay: "Listing at eBay",
  container: "Container",
  shelf: "Shelf",
  statusNote: "Status note",
  specifyServices: "Item services",
  openSpace: "Open Space",
  number: "SKU"
};
