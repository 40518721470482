import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";

import QrCodeDialog from "components/QRCodeDialog";
import Icon from "components/Icon";
import ItemQrCodeDescription from "screens/InventoryItems/components/ItemQRCodeDescription";

const ItemQrCode = ({ item, OpenerComponent }) => {
  return (
    <QrCodeDialog
      value={item.number}
      OpenerComponent={(props) =>
        OpenerComponent ? (
          <OpenerComponent {...props} />
        ) : (
          <IconButton {...props}>
            <Icon name={"qr-code"} color={"black"} fontSize={18} />
          </IconButton>
        )
      }
      qrDescription={<ItemQrCodeDescription item={item} />}
    />
  );
};

ItemQrCode.propTypes = {
  item: PropTypes.object.isRequired,
  OpenerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default ItemQrCode;
