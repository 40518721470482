import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";

import { Typography} from "@material-ui/core";

import ModalConfirm from "components/ModalConfirm";

import {deleteCategory} from "store/category/actions";

const DeleteCategoryButton = ({ item }) => {
  const dispatch = useDispatch();

  const deleteThisCategory = () => {
    dispatch(deleteCategory(item._id));
  }

  return (
    <ModalConfirm
      message={"Are you sure that you want to delete this category?"}
      actionOnAccept={deleteThisCategory}
      modalTitle={`Delete category ${item.name}?`}
      actionOnAcceptText={"Delete category"}
      Component={(props) => (
        <Typography {...props}>Delete</Typography>
      )}
    />
  );
};

DeleteCategoryButton.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DeleteCategoryButton;