import {
  setFetchMessage,
  setLoadingStatus,
  unsetLoadingStatus,
} from "store/helper/actions";

import * as api from "lib/api";

import {
  SET_WAREHOUSES_LIST,
  SET_SELECTED_WAREHOUSE,
  UNSET_SELECTED_WAREHOUSE,
  SET_ROWS_LIST,
  SET_LEVELS_LIST,
  SET_RACKS_LIST,
  UNSET_ROWS_LIST,
  UNSET_RACKS_LIST,
  UNSET_LEVELS_LIST,
  SET_WAREHOUSE_STRUCTURE,
  SET_LOADING_WAREHOUSE_STRUCTURE,
  UNSET_LOADING_WAREHOUSE_STRUCTURE,
  SET_WAREHOUSE_MESSAGE,
  UNSET_WAREHOUSE_MESSAGE,
} from "store/warehouse/types";

import API_RESOURCES from "lib/api/resources";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "components/MessageService/constants";

const setWarehousesList = (list) => ({
  type: SET_WAREHOUSES_LIST,
  payload: {
    list,
  },
});

export const unsetSelectedWarehouse = () => ({
  type: UNSET_SELECTED_WAREHOUSE,
});

/**
 * Fetch warehouses list redux saver.
 * @returns {function(*): Promise<void>}
 */
export const fetchWarehouses = (withoutLoading = false) => {
  return async (dispatch) => {
    if (!withoutLoading) {
      dispatch(setLoadingStatus());
    }

    try {
      const response = await api.get(API_RESOURCES.WAREHOUSES);

      if (response.data) {
        dispatch(setWarehousesList(response.data));
      } else if (response.message) {
        dispatch(setFetchMessage(response));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      if (!withoutLoading) {
        dispatch(unsetLoadingStatus());
      }
    }
  };
};

/**
 * Action to set selected warehouse payload in store
 *
 * @param selected
 * @returns {{payload: {selected: *}, type: string}}
 */
const setSelectedWarehouse = (selected) => ({
  type: SET_SELECTED_WAREHOUSE,
  payload: {
    selected,
  },
});

/**
 * Fetch a single warehouse by its id
 *
 * @param warehouseId
 * @returns {function(*): Promise<void>}
 */
export const fetchSingleWarehouse = (warehouseId) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      const response = await api.get(
        `${API_RESOURCES.WAREHOUSES}/${warehouseId}`
      );

      if (!response.data) {
        dispatch(setFetchMessage(response));
      }

      dispatch(setSelectedWarehouse(response.data));
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

/**
 * Add / edit warehouses action
 * If there's no warehouseId variable, we assume that method post is the one to be used
 * On the other hand, it is assumed that method PUT must be the selected one
 *
 * @param rawWarehousePayload
 * @param warehouseId
 * @returns {function(*): Promise<void>}
 */
export const saveWarehouse = (rawWarehousePayload, warehouseId) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus());

    try {
      let response;
      let warehousePayload = rawWarehousePayload;

      if (warehouseId) {
        response = await api.put(
          `${API_RESOURCES.WAREHOUSES}/${warehouseId}`,
          warehousePayload
        );
      } else {
        warehousePayload = {
          ...warehousePayload,
          rows: parseInt(warehousePayload.rows),
          racks: parseInt(warehousePayload.racks),
          shelfs: parseInt(warehousePayload.shelfs),
          openSpaces: parseInt(warehousePayload.openSpaces),
        };

        response = await api.post(API_RESOURCES.WAREHOUSES, warehousePayload);
      }

      if (response) {
        console.log(response);
        dispatch(setFetchMessage(response));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetLoadingStatus());
    }
  };
};

const setRowsList = (rowsList) => ({
  type: SET_ROWS_LIST,
  payload: {
    rowsList,
  },
});

/**
 * Fetch rows that belong to a warehouse
 *
 * @param warehouseId
 * @param sort
 * @returns {function(*): Promise<void>}
 */
export const fetchWarehouseRows = (warehouseId, sort = "name") => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.ROWS, {
        warehouse: warehouseId,
        sort,
      });

      if (response) {
        dispatch(setRowsList(response.data.rows));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

const setRacksList = (racksList) => ({
  type: SET_RACKS_LIST,
  payload: {
    racksList,
  },
});

/**
 * Fetch racks that belongs to a certain row
 *
 * @param rowId
 * @param sort
 * @returns {function(*): Promise<void>}
 */
export const fetchWarehouseRacks = (rowId, sort) => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.RACKS, {
        row: rowId,
        sort,
      });

      if (response) {
        dispatch(setRacksList(response.data.rows));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

const setLevelsList = (levelsList) => ({
  type: SET_LEVELS_LIST,
  payload: {
    levelsList,
  },
});

/**
 * Fetch levels (shelves) related to a given rack
 *
 * @param rackId
 * @param sort
 * @returns {function(*): Promise<void>}
 */
export const fetchWarehouseLevels = (rackId, sort = "name") => {
  return async (dispatch) => {
    try {
      const response = await api.get(API_RESOURCES.LEVELS, {
        rack: rackId,
        sort,
      });

      if (response) {
        dispatch(setLevelsList(response.data.rows));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };
};

/**
 * Action to set warehouse structure in store
 *
 * @param warehouseStructure
 * @returns {{payload: {warehouseStructure: *}, type: string}}
 */
const setWarehouseStructure = (warehouseStructure) => ({
  type: SET_WAREHOUSE_STRUCTURE,
  payload: {
    warehouseStructure,
  },
});

/**
 * Action to fetch warehouse structure
 *
 * @param warehouseId
 * @returns {function(*): Promise<void>}
 */
export const fetchWarehouseStructure = (warehouseId) => {
  return async (dispatch) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.get(
        `${API_RESOURCES.WAREHOUSES}/${warehouseId}/${API_RESOURCES.WAREHOUSE_LOCATION}`
      );

      if (response.data) {
        dispatch(setWarehouseStructure(response.data.rows));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};

export const unsetRowsList = () => ({
  type: UNSET_ROWS_LIST,
});

export const unsetRacksList = () => ({
  type: UNSET_RACKS_LIST,
});

export const unsetLevelsList = () => ({
  type: UNSET_LEVELS_LIST,
});

const setWarehouseMessage = (message) => ({
  type: SET_WAREHOUSE_MESSAGE,
  payload: {
    message,
  },
});

export const unsetWarehouseMessage = () => ({
  type: UNSET_WAREHOUSE_MESSAGE,
});

const setWarehouseLoadingStatus = () => ({
  type: SET_LOADING_WAREHOUSE_STRUCTURE,
});

const unsetWarehouseLoadingStatus = () => ({
  type: UNSET_LOADING_WAREHOUSE_STRUCTURE,
});

/**
 * Action to add rows
 *
 * @param rowPayload
 * @returns {function(*, *): Promise<void>}
 */
export const addRow = (rowPayload) => {
  return async (dispatch, getState) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.post(API_RESOURCES.ROWS, rowPayload);

      if (response.type === ERROR_STATUS) {
        dispatch(
          setWarehouseMessage({
            message: response.message,
            title: "Row couldn't be created",
          })
        );
      }

      if (response.type === SUCCESS_STATUS) {
        dispatch(setFetchMessage(response));
        const { warehouseReducer } = getState();

        dispatch(fetchWarehouseStructure(warehouseReducer.selected._id));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};

/**
 * Action to delete rows
 *
 * @param rowId
 * @returns {function(*, *): Promise<void>}
 */
export const deleteRow = (rowId) => {
  return async (dispatch, getState) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.deleteMethod(`${API_RESOURCES.ROWS}/${rowId}`);

      if (response.type === ERROR_STATUS) {
        dispatch(
          setWarehouseMessage({
            message: response.message,
            title: "Row couldn't be deleted",
          })
        );
      }

      if (response.type === SUCCESS_STATUS) {
        dispatch(setFetchMessage(response));
        const { warehouseReducer } = getState();

        dispatch(fetchWarehouseStructure(warehouseReducer.selected._id));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};

/**
 * Action to add racks
 *
 * @param rackPayload
 * @returns {function(*, *): Promise<void>}
 */
export const addRack = (rackPayload) => {
  return async (dispatch, getState) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.post(API_RESOURCES.RACKS, {
        ...rackPayload,
        // force selected name to be saved as an uppercase string
        name: rackPayload.name.toUpperCase(),
      });

      if (response.type === ERROR_STATUS) {
        dispatch(
          setWarehouseMessage({
            message: response.message,
            title: "Rack couldn't be created",
          })
        );
      }

      if (response.type === SUCCESS_STATUS) {
        dispatch(setFetchMessage(response));
        const { warehouseReducer } = getState();

        dispatch(fetchWarehouseStructure(warehouseReducer.selected._id));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};

/**
 * Action to delete racks
 *
 * @param rackId
 * @returns {function(*, *): Promise<void>}
 */
export const deleteRack = (rackId) => {
  return async (dispatch, getState) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.deleteMethod(
        `${API_RESOURCES.RACKS}/${rackId}`
      );
      if (response.type === ERROR_STATUS) {
        dispatch(
          setWarehouseMessage({
            message: response.message,
            title: "Rack couldn't be deleted",
          })
        );
      }

      if (response.type === SUCCESS_STATUS) {
        dispatch(setFetchMessage(response));
        const { warehouseReducer } = getState();

        dispatch(fetchWarehouseStructure(warehouseReducer.selected._id));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};

/**
 * Action to add shelves
 *
 * @param levelPayload
 * @returns {function(*, *): Promise<void>}
 */
export const addShelf = (levelPayload) => {
  return async (dispatch, getState) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.post(API_RESOURCES.LEVELS, levelPayload);

      if (response.type === ERROR_STATUS) {
        dispatch(
          setWarehouseMessage({
            message: response.message,
            title: "Shelf couldn't be created",
          })
        );
      }

      if (response.type === SUCCESS_STATUS) {
        dispatch(setFetchMessage(response));
        const { warehouseReducer } = getState();

        dispatch(fetchWarehouseStructure(warehouseReducer.selected._id));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};

/**
 * Action to delete shelves
 *
 * @param shelfId
 * @returns {function(*, *): Promise<void>}
 */
export const deleteShelf = (shelfId) => {
  return async (dispatch, getState) => {
    dispatch(setWarehouseLoadingStatus());

    try {
      const response = await api.deleteMethod(
        `${API_RESOURCES.LEVELS}/${shelfId}`
      );

      if (response.type === ERROR_STATUS) {
        dispatch(
          setWarehouseMessage({
            message: response.message,
            title: "Shelf couldn't be deleted",
          })
        );
      }

      if (response.type === SUCCESS_STATUS) {
        dispatch(setFetchMessage(response));
        const { warehouseReducer } = getState();

        dispatch(fetchWarehouseStructure(warehouseReducer.selected._id));
      }
    } catch (e) {
      dispatch(setFetchMessage(e));
    } finally {
      dispatch(unsetWarehouseLoadingStatus());
    }
  };
};
