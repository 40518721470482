import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import TableText from "components/TableText";

const ItemTableLink = ({item, accessor, ...props}) => {
  const history = useHistory();

  const goToItemDetail = () => {
    history.push(`/inventory/items/detail/${item._id}`);
  }

  return (
    <TableText
      item={item}
      accessor={accessor}
      style={{ cursor: "pointer" }}
      onClick={goToItemDetail}
      {...props}
    />
  );
};

ItemTableLink.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemTableLink;
