import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";

import { DialogTitle, DialogContent } from "@material-ui/core";

import ModalWithOpener from "components/ModalWithOpener";
import ModalActions from "components/ModalActions";

import { setFetchMessage } from "store/helper/actions";
import { useWindowSize } from "lib/hooks/dimensions";

import './styles.scss';

const QrCodeDialog = ({ OpenerComponent, qrDescription, value }) => {
  const dimensions = useWindowSize();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  let browser = window.self;
  let qrCodeWindow = null;

  const qrCodeRef = useRef();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handlePrintQRCode = () => {
    if (qrCodeWindow && !qrCodeWindow.closed) {
      qrCodeWindow.focus();

      return ;
    }

    try {
      qrCodeWindow = browser.open(
        "",
        "QR",
        `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=${
          dimensions.height - 400
        },top=${dimensions.height - 400},left="${dimensions.width - 840}`
      );

      qrCodeWindow.document.body.append(qrCodeRef.current.cloneNode(true));

      qrCodeWindow.onafterprint = function () {
        qrCodeWindow.close();
      };

      qrCodeWindow.print();

    } catch (e) {
      dispatch(setFetchMessage(e));
    }
  };

  return (
    <ModalWithOpener
      OpenerComponent={<OpenerComponent onClick={openModal} />}
      open={isOpen}
      onClose={closeModal}
    >
      <>
        <DialogTitle>Print QR Code</DialogTitle>
        <div ref={qrCodeRef}>
          <DialogContent style={{ margin: 24 }}>
            <QRCode value={value} />
            {qrDescription}
          </DialogContent>
        </div>
        <ModalActions
          closeModalText={"cancel"}
          closeModal={closeModal}
          onSubmitActionText={"Print QR Code"}
          onClick={handlePrintQRCode}
        />
      </>
    </ModalWithOpener>
  );
};

QrCodeDialog.propTypes = {
  OpenerComponent: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  qrDescription: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default QrCodeDialog;
