import Categories from "screens/Categories";

const inventoryRoutes = [
  {
    name: "categories",
    path: "/inventory/categories",
    component: Categories,
    featureKey: "CATEGORIES.CATEGORIES_READ",
    exact: true
  },
  // {
  //   name: "category-form",
  //   path: "/inventory/categories/create",
  //   component: ProductCreateEdit,
  //   featureKey: "CATEGORIES.CATEGORIES_CREATE",
  //   exact: true
  // },
  // {
  //   name: "category-form-edit",
  //   path: "/inventory/categories/edit/:id",
  //   component: ProductCreateEdit,
  //   featureKey: "CATEGORIES.CATEGORIES_UPDATE",
  //   exact: true
  // },
];

export default inventoryRoutes;
