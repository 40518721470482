import React from "react";
import PropTypes from "prop-types";

import TableText from "components/TableText";

/**
 * Formats number to use local currency
 *
 * @param item
 * @param accessor
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TableCurrency = ({ item, accessor, ...props }) => {
  const formattedNumber =
    !!item[accessor] &&
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(parseFloat(item[accessor]));

  return (
    <TableText
      item={{
        [accessor]: formattedNumber,
      }}
      accessor={accessor}
      {...props}
    />
  );
};

TableCurrency.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableCurrency;
