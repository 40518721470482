import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import SelectWrapper from "components/SelectWrapper";
import FormFieldWrapper from "components/FormFieldWrapper";

import "./styles.scss";

const FormSelect = ({
  field,
  label,
  placeholder,
  initialValue,
  required,
  externalLabel,
  onChangeExternal,
  ...props
}) => {
  const form = useFormikContext();

  const { setFieldValue, setFieldTouched, touched, errors } = form;

  useEffect(() => {
    if (!field.value) {
      setFieldValue(field.name, initialValue || "-1");
      setFieldTouched(field.name, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, fullData) => {
    if (props.hasGroups) {
      setFieldTouched(field.name, true);
      setFieldValue(field.name, value.value);
    } else {
      setFieldTouched(field.name, true);
      setFieldValue(field.name, value);
    }

    if (onChangeExternal) onChangeExternal(value, setFieldValue, fullData);
  };

  useEffect(() => {
    if (props.disabled) {
      setFieldValue(field.name, "-1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  const CustomField = () => (
    <SelectWrapper
      onChange={onChange}
      defaultValue={field.value}
      label={placeholder}
      error={!!(touched[field.name] && errors[field.name])}
      {...props}
    />
  );

  return externalLabel ? (
    <FormFieldWrapper name={field.name} label={label} required={required}>
      <CustomField />
    </FormFieldWrapper>
  ) : (
    <div className={"form-input-container"}>
      <CustomField />
    </div>
  );
};

FormSelect.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormSelect;
