import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import { Button, FormHelperText, Grid, IconButton } from "@material-ui/core";

import FormFieldWrapper from "components/FormFieldWrapper";
// import ImagePreview from "components/ImagePreview";
import Icon from "components/Icon";

import * as arrayOperations from "lib/array/operations";
import { fileResizing, fileSizeByBase64 } from "lib/assets/format";
import { getFiles } from "lib/files/operations";

import "./styles.scss";

const FormFileInput = ({
  field,
  initialValue,
  externalLabel,
  required,
  ...props
}) => {
  const form = useFormikContext();

  const { setFieldValue, setErrors, setFieldTouched, errors } = form;
  const [error, setError] = useState(errors[field.name]);

  const thisFieldErrors = errors[field.name];

  const inputFileRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    async function setInitialFiles() {
      const parsedFilesList = await parseFilesObject(
        field.value.filter((file) => !!file.size)
      );
      const parsedValuesList = await getFiles(
        field.value.filter((file) => !!file.file)
      );

      setSelectedFiles([
        ...selectedFiles,
        ...parsedValuesList.map((item) =>
          item.type === "LOCAL" ? URL.createObjectURL(item.file) : item.file
        ),
        ...parsedFilesList.map((item) => item.base64Img),
      ]);
    }

    if (field.value) {
      setInitialFiles();
    }

    setError(errors[field.name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError(thisFieldErrors);
  }, [thisFieldErrors]);

  const openFileSelector = () => {
    inputFileRef.current.click();
  };

  const parseFilesObject = (filesObject) => {
    return Promise.all(
      Object.keys(filesObject).map(async (fileIndex) => {
        try {
          if (
            filesObject[fileIndex]?.type === "image/png" ||
            filesObject[fileIndex]?.type === "image/jpeg"
          ) {
            const resizedImage = await fileResizing(filesObject[fileIndex]);

            if (fileSizeByBase64(resizedImage) <= 2048) {
              return { file: filesObject[fileIndex], base64Img: resizedImage };
            } else {
              setError(
                "Image size is bigger than the minimum accepted (2MB). Please, select another one."
              );
              setErrors({
                [field.name]:
                  "Image size is bigger than the minimum accepted (2MB). Please, select another one.",
              });

              return null;
            }
          } else {
            setErrors({
              [field.name]:
                "Image extension must be JPEG or PNG. Please, select another one.",
            });

            setError(
              "Image extension must be JPEG or PNG. Please, select another one."
            );

            return null;
          }
        } catch (err) {
          setErrors({
            [field.name]: err,
          });

          setError(err);
        }
      })
    );
  };

  const onChangeFileInput = async (event) => {
    setError("");
    setFieldTouched(field.name);

    const parsedList = await parseFilesObject(event.target.files);

    setFieldValue(field.name, [
      ...(field.value || []),
      ...parsedList.filter((file) => !!file).map((item) => item.file),
    ]);

    // setSelectedFiles([
    //   ...selectedFiles,
    //   ...parsedList.filter((file) => !!file).map((item) => item.base64Img),
    // ]);

    let arrayUrls = [];
    if (parsedList) {
      // eslint-disable-next-line array-callback-return
      parsedList.map((file) => {
        let url = URL.createObjectURL(file.file);
        arrayUrls.push(url);
      });
    }
    setSelectedFiles([...selectedFiles, ...arrayUrls]);
  };

  const removeSelectedFile = async (fileIndex, image) => {
    if (field.value[fileIndex]._id) {
      setFieldValue("imagesToDelete", [
        ...form.values.imagesToDelete,
        field.value[fileIndex],
      ]);
    }

    const base64ArrayWithoutRemovedFile = selectedFiles.filter(
      (file) => file !== image
    );

    const filesArrayWithoutRemovedFiles = arrayOperations.removeByValue(
      field.value,
      field.value[fileIndex]
    );

    setSelectedFiles(base64ArrayWithoutRemovedFile);
    setFieldValue(field.name, filesArrayWithoutRemovedFiles);
  };

  const CustomField = (
    <Grid container className={"file-input-container"} spacing={2}>
      <Grid item xs={4}>
        <Button className={"file-input-button"} onClick={openFileSelector}>
          {`Select file${props.multiple ? "s" : ""}`}
        </Button>
        <input
          type="file"
          ref={inputFileRef}
          className={"file-input-field"}
          name={field.name}
          onChange={onChangeFileInput}
          {...props}
        />
      </Grid>
      <Grid
        item
        xs={props.multiple ? 12 : 8}
        className={"file-input-images-list"}
      >
        <Grid container spacing={2}>
          {selectedFiles.map((file, index) => (
            <Grid
              item
              xs={2}
              key={index}
              className={"file-image-preview-container"}
            >
              <div key={file}>
                <img
                  src={file}
                  alt="prev"
                  style={{
                    maxWidth: "90px",
                    minHeight: "90px",
                    maxHeight: "90px",
                    minWidth: "90px",
                    paddingRight: "15px",
                  }}
                />
                <IconButton
                  size={"small"}
                  onClick={() => removeSelectedFile(index, file)}
                  className={"file-input-image-button"}
                >
                  <Icon name={"cancel"} />
                </IconButton>
              </div>
              {/* <ImagePreview
                actionComponent={
                  <IconButton
                    size={"small"}
                    onClick={() => removeSelectedFile(index, file)}
                    className={"file-input-image-button"}
                  >
                    <Icon name={"cancel"} />
                  </IconButton>
                }
                item={{
                  imageUrl: file,
                  name: index,
                }}
                className={"file-input-image-preview"}
              /> */}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {externalLabel ? (
        <FormFieldWrapper
          name={field.name}
          label={props.label}
          required={required}
          customError={!!error}
          errorMessage={error || errors[field.name]}
        >
          <>
            {CustomField}
            {!!error && (
              <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
            )}
          </>
        </FormFieldWrapper>
      ) : (
        <div className={"form-input-container"}>
          <>
            {CustomField}
            {!!error && (
              <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
            )}
          </>
        </div>
      )}
    </>
  );
};

FormFileInput.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  label: PropTypes.string.isRequired,
  props: PropTypes.object,
};

export default FormFileInput;
