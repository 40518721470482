import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AutocompleteFilter from "components/AutocompleteFilter";

import { fetchCategories } from "store/category/actions";

const CategoryFilter = () => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  const { list: categoryFilterList } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );

  useEffect(() => {
    if (searchValue && searchValue.length >= 3) {
      dispatch(fetchCategories({ search: searchValue }));
    }
  }, [dispatch, searchValue]);

  return (
    <AutocompleteFilter
      options={categoryFilterList}
      onSearchExternal={setSearchValue}
      filterKey={"category"}
      label={'Category'}
    />
  );
};

export default CategoryFilter;
