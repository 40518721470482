import * as api from "lib/api";
import API_RESOURCES from "lib/api/resources";

async function addComment(comment) {
  return api.post(API_RESOURCES.COMMENTS, comment);
}

async function addComments(commentsList) {
  let requests = [];

  for (let comment in commentsList) {
    requests = [
      ...requests,
      addComment(commentsList[comment])
    ]
  }

  return Promise.all(requests);
}

async function getCommentsByEntity(entity, entityId) {
  return api.get(API_RESOURCES.COMMENTS, {entity, entityId})
}

export { addComment, getCommentsByEntity, addComments };
