import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { Grid, Stepper, Step, StepLabel } from "@material-ui/core";

import FormWrapper from "components/FormWrapper";
import FormStepIcon from "components/FormStepIcon";
import FormStepperActionButtons from "components/FormStepperWrapper/components/FormStepperActionButtons";

import { getFlatValues } from "lib/array/operations";

import "./styles.scss";

const FormStepperWrapper = ({
  steps,
  onSubmit,
  cancelActionText,
  submitActionText,
  shouldDisplaySaveButton,
}) => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [stepIsValid, setStepIsValid] = useState(true);
  const [accumulatedValues, setAccumulatedValues] = useState(
    getFlatValues(steps.map((step) => ({ ...step.initialValues })))
  );
  const [changingStep, setChangingStep] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const initialValuesObject = getFlatValues(
      steps.map((step) => ({ ...step.initialValues }))
    );

    setAccumulatedValues(initialValuesObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitAction = (values) => {
    onSubmit(values);
  }

  const moveToNextStep = (values) => {
      setChangingStep(true);
      setAccumulatedValues(values);

      if (selectedStep + 1 === steps.length) {
        onSubmit(values);
      } else {
        setSelectedStep(selectedStep + 1);
      }

      setChangingStep(false);
  };

  const moveToPreviousStep = () => {
    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }

    if (selectedStep === 0) {
      history.goBack();
    }
  };

  const WatchValueChanges = ({ form, onChangeValue, eventWatcher }) => {
    useEffect(() => {
      onChangeValue(form.values, form.isValid);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventWatcher]);

    return null;
  };

  const onStateChange = (values, formIsValid) => {
    setAccumulatedValues(values);
    setStepIsValid(formIsValid);
  };

  const onStepChange = (step) => {
    if (stepIsValid) {
      setSelectedStep(step);
    }
  };

  useEffect(() => {}, [selectedStep]);

  const onChangeValueField = {
    name: "changing",
    props: { eventWatcher: selectedStep, onChangeValue: onStateChange },
    component: WatchValueChanges,
  };

  return (
    <>
      <Stepper
        activeStep={selectedStep}
        className={"form-stepper"}
        alternativeLabel
      >
        {steps.map((step, index) => (
          <Step key={step.name}>
            <StepLabel
              error={!stepIsValid && index === selectedStep}
              onClick={() => onStepChange(index)}
              className={"form-stepper__step"}
              StepIconComponent={(props) => (
                <FormStepIcon {...props} stepLabel={step.displayLabel} />
              )}
            />
          </Step>
        ))}
      </Stepper>
      {!changingStep && (
        <Grid item md={6} sm={6} xs={6}>
          <FormWrapper
            noDirtyValidation
            fields={[...steps[selectedStep]?.fields, onChangeValueField]}
            initialValues={{
              ...steps[selectedStep].initialValues,
              ...accumulatedValues,
            }}
            validate={steps[selectedStep]?.validate}
            onSubmit={submitAction}
            OnSubmitActionComponent={(formProps) => (
              <Grid container>
                <Grid item xs={4}>
                  <div />
                </Grid>
                <Grid item xs={8}>
                  <FormStepperActionButtons
                    selectedStep={selectedStep}
                    submitButtonProps={{ ...formProps, disabled: false }}
                    previousStepAction={moveToPreviousStep}
                    cancelButtonText={cancelActionText}
                    stepsLength={steps.length}
                    submitActionText={submitActionText}
                    submitAction={onSubmit}
                    displaySaveButton={shouldDisplaySaveButton}
                    nextStepAction={moveToNextStep}
                  />
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      )}
    </>
  );
};

FormStepperWrapper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
      displayLabel: PropTypes.string.isRequired,
      initialValues: PropTypes.object.isRequired,
      validate: PropTypes.func,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
};

export default FormStepperWrapper;
