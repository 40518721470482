import {exportDateObject} from "lib/date/shared";

function formatForTable(date) {
  const dateObject = exportDateObject(date);

  return `${dateObject.month}/${dateObject.day}/${dateObject.year} @ ${dateObject.hours}:${dateObject.minutes}`;
}

function formatDateForTable(date, separator = "/") {
  const dateObject = exportDateObject(date);

  return `${dateObject.month}${separator}${dateObject.day}${separator}${dateObject.year}`;
}

function formatForLog(date) {
  const dateObject = exportDateObject(date);

  return `${dateObject.month}/${dateObject.day}/${dateObject.year} at ${dateObject.hours}:${dateObject.minutes}`;
}

function formatDateForDatePicker(date) {
  const dateObject = exportDateObject(date);

  return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
}

export { formatForTable, formatForLog, formatDateForTable, formatDateForDatePicker};
