import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";

import "./Subcomponents.scss";

const SubcomponentsList = ({ list }) => {
  return (
    <div>
      {list.map((subcomponent) => (
        <div key={subcomponent._id}>
          <Typography className={"subcomponents-list-text subcomponent-name"}>
            {subcomponent.name}
          </Typography>
          <Typography className={"subcomponents-list-text"}>
            S/N: {subcomponent.serialNumber}
          </Typography>
          <Typography className={"subcomponents-list-text"}>
            Condition: {subcomponent.condition}
          </Typography>
        </div>
      ))}
    </div>
  );
};

SubcomponentsList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default SubcomponentsList;
