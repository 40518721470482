const rackFormValidate = (values) => {
  let errors = {};

  if (!values.name) {
    errors = {
      ...errors,
      name: "Rack name is required",
    };
  } else if (values.name.length > 4) {
    errors = {
      ...errors,
      name: "Rack name length should be less than 4 letters",
    };
  }

  return errors;
};

export default rackFormValidate;
