import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
} from "@material-ui/core";

import "./styles.scss";
import AuthWrapper from "components/AuthWrapper";

const LinkDropdownContainer = ({ OpenerComponent, links }) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const navigateTo = (event, path) => {
    history.push(path);

    handleClose(event);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <OpenerComponent
        thisRef={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  className={"link-dropdown-menu-list"}
                  onKeyDown={handleListKeyDown}
                >
                  {links.map((link) => (
                    <AuthWrapper permission={link.permission} key={link.name}>
                      <MenuItem
                        onClick={(event) => navigateTo(event, link.path)}
                      >
                        <span className={"link-dropdown-menu-list__item"}>
                          {link.name}
                        </span>
                      </MenuItem>
                    </AuthWrapper>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

LinkDropdownContainer.propTypes = {};

export default LinkDropdownContainer;
