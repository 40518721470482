import React from "react";

import Warehouses from "screens/Warehouses";
import Containers from "screens/Containers";
import Icon from "components/Icon";
import Categories from "screens/Categories";
import InventoryItems from "screens/InventoryItems";
import Users from "screens/Users";
import Dashboard from "screens/Dashboard";

const toolbarRoutes = [
  {
    name: "Dashboard",
    path: "/",
    component: Dashboard,
    exact: true,
    icon: <Icon name={"home"} classnames={"header-icon"} />,
    permission: "WILDCARD",
    // permission: "WAREHOUSE.WAREHOUSE_READ",
  },
  {
    name: "Inventory",
    path: "/inventory",
    exact: true,
    icon: <Icon name={"box"} classnames={"header-icon"} />,
    type: "dropdown",
    permission: "WILDCARD",
    links: [
      {
        name: "Categories",
        path: "/inventory/categories",
        exact: true,
        component: Categories,
        permission: "CATEGORIES.CATEGORIES_READ",
      },
      {
        name: "Items",
        path: "/inventory/items",
        exact: true,
        component: InventoryItems,
        permission: "ITEMS.ITEMS_READ",
      },
    ],
  },
  {
    name: "Warehouses",
    path: "/warehouses",
    component: Warehouses,
    exact: true,
    icon: <Icon name={"warehouse"} classnames={"header-icon"} />,
    permission: "WAREHOUSE.WAREHOUSE_READ",
  },
  {
    name: "Containers",
    path: "/containers",
    component: Containers,
    exact: true,
    icon: <Icon name={"container"} classnames={"header-icon"} />,
    permission: "CONTAINERS.CONTAINERS_READ",
  },
  {
    name: "Users",
    path: "/users",
    component: Users,
    exact: true,
    icon: <Icon name={"users"} classnames={"header-icon"} />,
    permission: "USERS.USERS_READ",
  },
];

export default toolbarRoutes;
