import React from 'react';

import {Grid} from "@material-ui/core";

const ItemSectionDivider = props => {
  return (
    <Grid container className={"category-section-divider"}>
      <Grid xs={12} item>
        <hr />
      </Grid>
    </Grid>
  );
};

export default ItemSectionDivider;