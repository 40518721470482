import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import { Button, CircularProgress } from "@material-ui/core";

const FormStepperActionButtons = ({
  selectedStep,
  submitButtonProps,
  previousStepAction,
  cancelButtonText,
  stepsLength,
  submitActionText,
  displaySaveButton,
  nextStepAction,
}) => {
  const history = useHistory();
  const formik = useFormikContext();

  const { loading } = useSelector(({ helperReducer }) => helperReducer);

  const onPreviousStepClick = () => {
    if (selectedStep === 0) {
      history.goBack();
    } else {
      previousStepAction();
    }
  };

  const submitText =
    selectedStep < stepsLength - 1 ? "Next step" : submitActionText;

  const submit = (forceSubmit) => {
    if (forceSubmit) {
      formik.handleSubmit();
    } else {
      nextStepAction(formik.values);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <Button color={"secondary"} onClick={onPreviousStepClick}>
          {selectedStep === 0 ? cancelButtonText || "Cancel" : "Previous step"}
        </Button>
        <Button
          onClick={() => submit()}
          disabled={loading || submitButtonProps.disabled}
          variant={"contained"}
          color={"secondary"}
        >
          {loading ? "Saving..." : submitText}
        </Button>
      </div>
      {displaySaveButton && selectedStep < stepsLength - 1 && (
        <div>
          <Button
            onClick={() => submit(true)}
            disabled={loading || submitButtonProps.disabled}
            color={"secondary"}
            variant={"contained"}
            startIcon={loading ? <CircularProgress size={25} /> : null}
          >
            {loading ? "Saving..." : submitActionText}
          </Button>
        </div>
      )}
    </div>
  );
};

FormStepperActionButtons.propTypes = {};

export default FormStepperActionButtons;
