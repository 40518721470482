const userFormValidation = (values) => {
  let errors = {};

  if (!values.name) {
    errors = {
      ...errors,
      name: "User's name is required",
    };
  }

  if (!values.email) {
    errors = {
      ...errors,
      email: "User's email address is required",
    };
  }

  if (values.roles && values.roles === "-1") {
    errors = {
      ...errors,
      roles: "A role must be selected",
    };
  }

  return errors;
};

export default userFormValidation;
