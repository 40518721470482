const API_RESOURCES = {
  WAREHOUSES: "warehouses",
  OPEN_SPACES: "open-spaces",
  PALLETS: "pallets",
  ROLLING_CARTS: "rollingcarts",
  GET_COUNTERS: "getCounters",
  CONTAINERS: "containers",
  CONTAINER_TYPES: "containerTypes",
  CATEGORIES: "categories",
  ITEM_CONDITIONS: "itemconditions",
  ITEM_STATUSES: "itemstatuses",
  ITEMS: "items",
  FILES: "files",
  S3_PUT: 'files/put-signed-url',
  S3_GET: 'files/get-signed-url',
  COMMENTS: "comments",
  ROWS: "rows",
  RACKS: "racks",
  LEVELS: "shelves",
  CONTAINER_LOCATION_SHELF: "location/shelf",
  CONTAINER_LOCATION_OS: "location/openSpace",
  CONTAINER_LOCATION: "location/container",
  WAREHOUSE_LOCATION: "locations",
  LOGIN: 'login',
  USERS: 'users',
  ROLES: 'roles',
  ITEM_HISTORY: "historic-movements",
  GET_ROLLING_CART_COUNTER: "containers/rolling-cart/next-letter",
  GET_USER_DATA_BY_GOOGLE: "https://www.googleapis.com/oauth2/v3/userinfo",
};

export default API_RESOURCES;
