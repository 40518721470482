import { isValidNumber } from "lib/form/validation";

const validateWarehouseForm = (values) => {
  let errors = {};

  if (!values.name) {
    errors = {
      ...errors,
      name: "Warehouse name is required",
    };
  }

  if (!values.address) {
    errors = {
      ...errors,
      address: "Warehouse address is required",
    };
  }

  // If validation is handling warehouse creation mode
  if (!values._id) {
    if (!values.rows) {
      errors = {
        ...errors,
        rows: "Warehouse row's quantity is required",
      };
    } else if (!isValidNumber(values.rows)) {
      errors = {
        ...errors,
        rows: "Rows quantity must be a valid number",
      };
    }

    if (!values.racks) {
      errors = {
        ...errors,
        racks: "Racks per row quantity is required",
      };
    } else if (!isValidNumber(values.racks)) {
      errors = {
        ...errors,
        racks: "Racks per row quantity must be a valid number",
      };
    }

    if (!values.shelfs) {
      errors = {
        ...errors,
        shelfs: "Levels per rack quantity is required",
      };
    } else if (!isValidNumber(values.shelfs)) {
      errors = {
        ...errors,
        shelfs: "Levels per rack quantity must be a valid number",
      };
    }

    if (values.openSpaces && !isValidNumber(values.openSpaces)) {
      errors = {
        ...errors,
        openSpaces: "Open spaces warehouse's quantity must be a valid number",
      };
    }
  }

  return errors;
};

export default validateWarehouseForm;
