import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { Typography } from "@material-ui/core";

import TableLayout from "components/TableLayout";
import EditContainerButton from "screens/Containers/components/EditContainerButton";
import TableText from "components/TableText";
import ItemsQtyForTable from "components/ItemsQtyForTable";
import ContainerItemsListDrawer from "screens/Containers/components/ContainerItemsListDrawer";
import ContainerIsFullSwitch from "screens/Containers/components/ContainerIsFullSwitch";

import { fetchContainers } from "store/container/actions";

import * as dateFormat from "lib/date/format";

const ContainersTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = queryString.parse(location.search);

  const { list, count, page, isFetching } = useSelector(
    ({ containerReducer }) => containerReducer
  );

  useEffect(() => {
    dispatch(fetchContainers(searchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const containersTableColumns = [
    {
      name: "name",
      displayName: "#",
      Component: TableText,
      numeric: true,
      props: {
        width: 45,
      },
    },
    {
      name: "isFull",
      displayName: "Full",
      Component: ContainerIsFullSwitch,
      props: {
        width: 60,
      },
    },
    {
      name: "type",
      displayName: "Type",
      Component: ({ item }) => (
        <TableText item={{ type: item.type.name }} accessor={"type"} />
      ),
      shouldSort: true,
      disablePadding: true,
      props: {
        width: 180,
      },
    },
    {
      name: "items",
      displayName: "Items qty",
      Component: ItemsQtyForTable,
      shouldSort: true,
      numeric: true,
      props: {
        width: 130,
      },
    },
    {
      name: "location",
      displayName: "Location",
      shouldSort: true,
      Component: ({ item }) => (
        <TableText
          item={{
            warehouseLocation: item.location || "Container is not located",
          }}
          accessor={"warehouseLocation"}
        />
      ),
      props: {
        width: 235,
      },
    },
    {
      name: "created",
      displayName: "Created on",
      Component: ({ item }) => (
        <TableText
          accessor={"created"}
          item={{ created: dateFormat.formatForTable(item.created) }}
        />
      ),
      shouldSort: true,
      numeric: true,
      props: {
        width: 225,
      },
    },
    {
      name: "updated",
      displayName: "Last update",
      Component: ({ item }) => (
        <TableText
          accessor={"updated"}
          item={{
            updated: item.modified
              ? dateFormat.formatForTable(item.modified)
              : "-",
          }}
        />
      ),
      shouldSort: true,
      numeric: true,
      props: {
        width: 225,
      },
    },
  ];

  return (
    <TableLayout
      data={list}
      actionCellProps={{
        width: 150,
      }}
      actions={[
        {
          Component: EditContainerButton,
          permission: "CONTAINERS.CONTAINERS_UPDATE",
        },
        {
          Component: ContainerItemsListDrawer,
          permission: "CONTAINERS.CONTAINERS_UPDATE",
        },
      ]}
      columns={containersTableColumns}
      pagination={{ count, page }}
      loading={isFetching}
      noItemsMessage={<Typography>There are no containers to list.</Typography>}
    />
  );
};

ContainersTable.propTypes = {};

export default ContainersTable;
