import React from "react";
import PropTypes from "prop-types";

import { Divider, List, ListItem, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  "item-name": {
    fontWeight: "bold",
  },
  "item-attribute": {
    display: "block !important",
    fontSize: 14,
  },
  "list-item": {
    display: "flex",
    flexDirection: "column",
  },
});

const ItemDetailList = ({ list, itemProps }) => {
  const styles = useStyles();

  return (
    <List dense disablePadding>
      {list.map((item, index) => (
        <>
          {index > 0 && <Divider />}
          <ListItem key={item._id} className={"list-item"} {...itemProps}>
            <List dense disablePadding>
              <ListItem>
                <Typography
                  className={[styles["item-name"], styles["item-attribute"]]}
                >
                  {item.name}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles["item-attribute"]}>
                  Model: {item.model}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles["item-attribute"]}>
                  S/N: {item.serialNumber}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles["item-attribute"]}>
                  SKU: {item.number}
                </Typography>
              </ListItem>
            </List>
          </ListItem>
        </>
      ))}
    </List>
  );
};

ItemDetailList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default ItemDetailList;
