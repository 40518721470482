import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { RiseOutlined } from "@ant-design/icons";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function VerticalChart({ dataItemsSold }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = dataItemsSold?.map((item) => item.date);

  const data = {
    labels,
    datasets: [
      {
        label: "Quantity",
        data: dataItemsSold?.map((item) => item.qty),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Total",
        data: dataItemsSold?.map((item) => item.price),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        border: "1px solid #C3C3C3",
      }}
    >
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #C3C3C3",
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
          padding: 10,
        }}
      >
        <RiseOutlined style={{ color: "green", fontSize: 30 }} />
        <span style={{ fontWeight: "bold", fontSize: 20, paddingLeft: 10 }}>
          ITEMS SOLD
        </span>
      </div>
      <div style={{ padding: 20, paddingTop: 35, paddingBottom: 40 }}>
        <Bar options={options} data={data} />
      </div>
      <div
        style={{
          width: "100%",
          // borderTop: "1px solid #C3C3C3",
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
      ></div>
    </div>
  );
}
