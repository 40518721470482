import {
  SET_CATEGORIES_FETCHING,
  SET_CATEGORIES_LIST,
  SET_SELECTED_CATEGORY,
  UNSET_CATEGORIES_FETCHING,
  UNSET_SELECTED_CATEGORY,
  SET_CATEGORIES_LISTING_TABLE,
  SET_CATEGORIES_LISTING_GRID,
} from "store/category/types";

const categoryReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selected: payload.selected,
      };
    case UNSET_SELECTED_CATEGORY:
      return {
        ...state,
        selected: null,
      };
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        list: payload.data.rows,
        count: payload.data.total,
        page: payload.data.currentPage,
      };
    case SET_CATEGORIES_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    case UNSET_CATEGORIES_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case SET_CATEGORIES_LISTING_TABLE:
      return {
        ...state,
        selectedDisplayList: "table",
      };
    case SET_CATEGORIES_LISTING_GRID:
      return {
        ...state,
        selectedDisplayList: "grid",
      };
    default:
      return state;
  }
};

const initialState = {
  selected: null,
  list: [],
  count: 0,
  page: 1,
  isFetching: false,
  selectedDisplayList: "table",
};

export default categoryReducer;
