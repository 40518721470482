import API_RESOURCES from "lib/api/resources";
import * as api from "lib/api";
// import { fileResizing } from "lib/assets/format";
import { baseUrl } from "lib/api/constants";

/**
 * Function that allows uploading multiple images to EP
 *
 * @param folder
 * @param files
 * @returns {Promise<unknown[]>}
 */
async function fileCreation(folder, files) {
  let requests = [];

  for (let file in files) {
    // const base64Img = await fileResizing(files[file]);

    if (files[file]) {
      requests = [
        ...requests,
        uploadFileS3(files[file])
        // api.post(API_RESOURCES.FILES, { folder, data: base64Img }),
      ];
    }
  }

  return Promise.all(requests);
}

async function getFiles(files) {
  let requests = [];

  for (let file in files) {
    requests = [
      ...requests,
      getFile(files[file].file)
    ];
  }

  return Promise.all(requests);
}

async function getFile(fileName) {
  const searched = fileName.search('amazons3');
  if (searched === -1) {
    return { file: await api.get(API_RESOURCES.FILES, { fileName }, "blob"), type: 'LOCAL'};
  } else {
    return{ file: await getFileS3(fileName), type: 'AMAZON'}
  }
}

async function deleteFiles(files) {
  let requests = [];

  for (let file in files) {
    requests = [
      ...requests,
      api.deleteMethod(API_RESOURCES.FILES, { fileName: files[file].file }),
    ];
  }

  return Promise.all(requests);
}

async function uploadFileS3(file, fileId = '0') {
  const responsePromise = await fetch(`${baseUrl}/${API_RESOURCES.S3_PUT}/${fileId}`);
  const response = await responsePromise.json();
  const { signedUrl, fileName } = response.data;
  await fetch(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': 'images/png'
    }
  });
  return { data: { fileName } }
}

async function getFileS3(fileName) {
  const fileId = fileName.replace('uploads/amazons3/','').replace('.png','');
  const responsePromise = await fetch(`${baseUrl}/${API_RESOURCES.S3_GET}/${fileId}`);
  const response = await responsePromise.json();
  // console.log('response', response);
  const { signedUrl } = response.data;
  return signedUrl
}

export { fileCreation, getFiles, deleteFiles, getFile, getFileS3, uploadFileS3 };
